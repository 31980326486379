import adminService from '../../api/services/admin';
import {
  ADD_ADMIN_ATTEMPT,
  ADD_ADMIN_SUCCESS,
  ADD_ADMIN_ERROR,
  ADD_ADMIN_RESET,
  GET_ADMIN_BY_ID_ATTEMPT,
  GET_ADMIN_BY_ID_SUCCESS,
  GET_ADMIN_BY_ID_ERROR,
  GET_ADMIN_BY_ID_RESET,
  DELETE_ADMIN_ATTEMPT,
  DELETE_ADMIN_SUCCESS,
  DELETE_ADMIN_ERROR,
  DELETE_ADMIN_RESET,
  UUPDATE_ADMIN_ATTEMPT,
  UPDATE_ADMIN_SUCCESS,
  UPDATE_ADMIN_ERROR,
  UPDATE_ADMIN_RESET,
} from '../constants';
export const addNewAdmin = data => {
  return async (dispatch, getState) => {
    try {
      const { admin } = getState();
      if (!admin.adminInfo.attempt) {
        dispatch({ type: ADD_ADMIN_ATTEMPT });

        const response = await adminService.addNewAdmin(data);
        dispatch({
          type: ADD_ADMIN_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({ type: ADD_ADMIN_ERROR, payload: error });
      console.log(error);
    }
  };
};

export const getAdminById = id => {
  return async (dispatch, getState) => {
    try {
      const { admin } = getState();
      if (!admin.adminInfo.getAdminByIdStatus.attempt) {
        dispatch({ type: GET_ADMIN_BY_ID_ATTEMPT });

        const response = await adminService.getAdminById(id);
        dispatch({
          type: GET_ADMIN_BY_ID_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({ type: GET_ADMIN_BY_ID_ERROR, payload: error });
      console.log(error);
    }
  };
};

export const deleteAdmin = id => {
  return async (dispatch, getState) => {
    try {
      const { admin } = getState();
      if (!admin.adminInfo.deleteAdminStatus.attempt) {
        dispatch({ type: DELETE_ADMIN_ATTEMPT });

        const response = await adminService.deleteAdmin(id);
        dispatch({
          type: DELETE_ADMIN_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({ type: DELETE_ADMIN_ERROR, payload: error });
      console.log(error);
    }
  };
};

export const updateAdmin = (data, id) => {
  return async (dispatch, getState) => {
    try {
      const { admin } = getState();
      if (!admin.adminInfo.updateAdminStatus.attempt) {
        dispatch({ type: UUPDATE_ADMIN_ATTEMPT });
        const response = await adminService.updateAdmin(data, id);
        dispatch({
          type: UPDATE_ADMIN_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({ type: UPDATE_ADMIN_ERROR, payload: error });
      console.log(error);
    }
  };
};

export const addNewAdminReset = () => ({ type: ADD_ADMIN_RESET });

export const getAdminByIdReset = () => ({ type: GET_ADMIN_BY_ID_RESET });

export const deleteAdminReset = () => ({ type: DELETE_ADMIN_RESET });

export const updateAdminReset = () => ({ type: UPDATE_ADMIN_RESET });
