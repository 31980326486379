import axiosApiClient from '..';
import { handleError } from '../../utils/errors';

const BASE_URL = '/properties';

class PropertiesService {
  getProperties = async (page, sortBy, order, searchTerm) => {
    try {
      if (!sortBy) {
        sortBy = '';
      }
      if (!order) {
        order = 'ASC';
      }
      const response = await axiosApiClient.get(
        `${BASE_URL}?page=${page}&sortBy=${sortBy}&order=${order}&searchTerm=${searchTerm}`,
      );
      if (response.status !== 200) {
        throw new Error();
      }
      const { data, total, perPage } = response.data;
      return { data, total, perPage };
    } catch (err) {
      throw handleError(err, 'Error occured while fetching properties.');
    }
  };

  getPropertiesForBusiness = async () => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/admin/business-properties`);
      if (response.status !== 200) {
        throw new Error();
      }
      const { data } = response.data;
      return data;
    } catch (err) {
      throw handleError(err, 'Error occured while fetching properties for businesses.');
    }
  };

  getPropertiesForNotifications = async () => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/admin/notifications`);
      if (response.status !== 200) {
        throw new Error();
      }
      const { data } = response.data;
      return data;
    } catch (err) {
      throw handleError(err, 'Error occured while fetching properties for notifications.');
    }
  };

  getPropertyUnits = async (id, page, sortBy, order) => {
    try {
      if (!sortBy) {
        sortBy = '';
      }
      if (!order) {
        order = 'ASC';
      }
      const response = await axiosApiClient.get(
        `${BASE_URL}/${id}/property-units?page=${page}&sortBy=${sortBy}&order=${order}`,
      );
      if (response.status !== 200) {
        throw new Error();
      }
      const { data, total, perPage } = response.data;
      return { data, total, perPage };
    } catch (err) {
      throw handleError(err, 'Error occured while fetching property units.');
    }
  };

  getPropertyAmenities = async (id, page, sortBy, order) => {
    try {
      if (!sortBy) {
        sortBy = '';
      }
      if (!order) {
        order = 'ASC';
      }
      const response = await axiosApiClient.get(
        `${BASE_URL}/${id}/amenities?page=${page}&sortBy=${sortBy}&order=${order}`,
      );
      if (response.status !== 200) {
        throw new Error();
      }
      const { data, total, perPage } = response.data;
      return { data, total, perPage };
    } catch (err) {
      throw handleError(err, 'Error occured while fetching property amenities.');
    }
  };

  getPropertyById = async id => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/${id}`);
      if (response.status !== 200) {
        throw new Error();
      }
      const { data } = response.data;
      return { data };
    } catch (err) {
      throw handleError(err, 'Error occured while fetching property.');
    }
  };

  uploadPropertyImage = async data => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}/upload/image`, data);
      if (response.status !== 201) {
        throw new Error();
      }
    } catch (err) {
      throw handleError(err, 'Error occured while uploading property image.');
    }
  };

  uploadAmenityImage = async data => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}/amenity/upload/image`, data);
      if (response.status !== 201) {
        throw new Error();
      }
    } catch (err) {
      throw handleError(err, 'Error occured while uploading amenity image.');
    }
  };

  toggleAutoSync = async propertyExternalId => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}/autosync`, { propertyExternalId });
      if (response.status !== 200) {
        throw new Error();
      }
    } catch (err) {
      throw handleError(
        err,
        `Error occured while toggling auto sync for property with externalId: ${propertyExternalId}.`,
      );
    }
  };

  setEmergencyPhoneNumber = async (emergencyPhoneNumber, propertyExternalId) => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}/` + propertyExternalId + `/emergency-phone-number`, {
        emergencyPhoneNumber,
      });
      if (response.status !== 200) {
        throw new Error();
      }
    } catch (err) {
      throw handleError(
        err,
        `Error occured while setting emergency phone number for property with externalId: ${propertyExternalId}.`,
      );
    }
  };

  setPaymentUrl = async (paymentUrl, propertyExternalId) => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}/` + propertyExternalId + `/paymentUrl`, {
        paymentUrl,
      });
      if (response.status !== 200) {
        throw new Error();
      }
    } catch (err) {
      throw handleError(
        err,
        `Error occured while setting payment Url for property with externalId: ${propertyExternalId}.`,
      );
    }
  };

  setReservationLink = async (reservationLink, propertyExternalId) => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}/` + propertyExternalId + `/reservationLink`, {
        reservationLink,
      });
      if (response.status !== 200) {
        throw new Error();
      }
    } catch (err) {
      throw handleError(
        err,
        `Error occured while setting reservation link for property with externalId: ${propertyExternalId}.`,
      );
    }
  };
  uploadReferralPhoto = async data => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}/admin/referralPhoto`, data);
      if (response.status !== 201) {
        throw new Error();
      }
    } catch (err) {
      throw handleError(err, 'Error occured while uploading referral photo.');
    }
  };
}

export default new PropertiesService();
