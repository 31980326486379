import React, { useRef } from 'react';

const MultilineInput = ({ value, placeholder, validators, onChange, className, disabled, onFocus }) => {
  const textarea = useRef();

  const handleChange = event => {
    const { value } = event.target;
    onChange(value);
  };

  return (
    <div className={`custom-field ${className}`}>
      <textarea
        rows={4}
        ref={textarea}
        value={value}
        placeholder="&nbsp;"
        onChange={handleChange}
        disabled={disabled}
        onFocus={onFocus}
      />
      <span onClick={() => textarea.current.focus()} className="placeholder">
        {placeholder}
      </span>
    </div>
  );
};

export default MultilineInput;
