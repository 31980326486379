import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Input, Table, TableItem } from '../../components';
import { Icons } from '../../themes';
import { ORDER } from '../../components/Table/Table';
import NotificationService from '../../api/services/notification';
import _ from 'lodash';
import { formatDate } from '../../utils/util';
import { useDispatch } from 'react-redux';

const NotificationScreen = () => {
  const [searchTerm, setsearchTerm] = useState('');
  const [notifications, setNotifications] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [perPage, setPerPage] = useState(1);
  const [sortOptions, setSortOptions] = useState({
    sortBy: '',
    order: ORDER.ASC,
  });

  const dispatch = useDispatch();

  let page = +useLocation().search.split('=')[1];
  let navigate = useNavigate();

  const setPage = async page => {
    setCurrentPage(page);
    navigate({
      pathname: '/notifications',
      search: `?page=${page}`,
    });
  };

  const tableHeader = [
    {
      key: 'title',
      label: 'Title',
      sortable: true,
    },
    {
      key: 'description',
      label: 'Description',
      sortable: true,
    },
    {
      key: 'seen',
      label: 'Seen',
      sortable: true,
    },
    {
      key: 'createdAt',
      label: 'Created',
      sortable: true,
    },
  ];

  const debouncedSearch = useCallback(
    _.debounce(async (sortOptions, searchTerm, page) => {
      try {
        const response1 = await NotificationService.getAllNotifications(
          Number(page),
          sortOptions.sortBy,
          sortOptions.order,
          searchTerm,
        );
        setPerPage(response1.perPage);
        setTotalCount(response1.total);
        setNotifications(response1.data);
      } catch (error) {
        console.log(error.message);
      }
    }, 400),
    [],
  );

  useEffect(() => {
    setCurrentPage(page);
    if (page > 0) debouncedSearch(sortOptions, searchTerm, page);
  }, [page, sortOptions, searchTerm, debouncedSearch]);

  const debouncedSetPageAndCurrentPage = useCallback(
    _.debounce(() => {
      setPage(1);
      setCurrentPage(1);
    }, 300),
    [],
  );

  useEffect(() => {
    debouncedSetPageAndCurrentPage();
  }, [searchTerm]);

  const handleSort = async (sortBy, order) => {
    setSortOptions({ sortBy, order });
  };

  return (
    <div className="notifications__screen">
      <div className="notifications__screen__content">
        <div className="notifications__screen__header">
          <p className="notifications__screen__header-title">Notifications</p>
          <Input
            className="notifications__screen__header-search"
            type="text"
            onChange={setsearchTerm}
            placeholder="Search"
          />
        </div>
        <Table
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={perPage}
          hasExtraActions
          setPage={setPage}
          noDataContent="No Data"
          handleSort={handleSort}
          headerInformation={tableHeader}>
          {notifications.map((notification, index) => {
            return (
              <Fragment key={index}>
                <TableItem data={notification.title} />
                <TableItem data={notification.description} />
                <TableItem data={notification.seen ? 'true' : 'false'} />
                <TableItem data={notification.createdAt ? formatDate(notification.createdAt) : ''} />
                <TableItem
                  className={'notifications__screen__content__search'}
                  data={
                    <Button
                      title={<img src={Icons.search} alt="search" />}
                      onClick={() =>
                        navigate({
                          pathname: `/notification/${notification.id}`,
                        })
                      }
                      className="notifications__screen__content__btn"
                    />
                  }
                />
              </Fragment>
            );
          })}
        </Table>
      </div>
    </div>
  );
};

export default NotificationScreen;
