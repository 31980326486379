import {
  GET_PROPERTY_BY_ID_ERROR,
  GET_PROPERTY_BY_ID_SUCCESS,
  GET_PROPERTY_BY_ID_ATEMPT,
  GET_PROPERTY_BY_ID_RESET,
  UPDATE_OR_CREATE_SOCIAL_ACCOUNTS_ATTEMPT,
  UPDATE_OR_CREATE_SOCIAL_ACCOUNTS_SUCCESS,
  UPDATE_OR_CREATE_SOCIAL_ACCOUNTS_ERROR,
  UPDATE_OR_CREATE_SOCIAL_ACCOUNTS_RESET,
} from '../constants';

const INIT_STATE = {
  property: {},
  getPropertyByIdStatus: {
    attempt: false,
    success: false,
    error: null,
  },
  updateOrCreatePropertySocialAccountsStatus: {
    attempt: false,
    success: false,
    error: null,
  },
};

const propertyReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROPERTY_BY_ID_ATEMPT:
      return {
        ...state,
        getPropertyByIdStatus: { attempt: true, success: false, error: null },
      };
    case GET_PROPERTY_BY_ID_SUCCESS:
      return {
        ...state,
        getPropertyByIdStatus: { attempt: false, success: true, error: null },
        property: action.payload,
      };
    case GET_PROPERTY_BY_ID_ERROR:
      return {
        ...state,
        getPropertyByIdStatus: { attempt: false, success: false, error: action.payload },
      };
    case GET_PROPERTY_BY_ID_RESET:
      return {
        ...state,
        getPropertyByIdStatus: { attempt: false, success: false, error: null },
      };
    case UPDATE_OR_CREATE_SOCIAL_ACCOUNTS_ATTEMPT:
      return {
        ...state,
        updateOrCreatePropertySocialAccountsStatus: { attempt: true, success: false, error: null },
      };
    case UPDATE_OR_CREATE_SOCIAL_ACCOUNTS_SUCCESS:
      return {
        ...state,
        updateOrCreatePropertySocialAccountsStatus: { attempt: false, success: true, error: null },
        property: { ...state.property, socialAccounts: [...action.payload] },
      };
    case UPDATE_OR_CREATE_SOCIAL_ACCOUNTS_ERROR:
      return {
        ...state,
        updateOrCreatePropertySocialAccountsStatus: { attempt: false, success: false, error: action.payload },
      };
    case UPDATE_OR_CREATE_SOCIAL_ACCOUNTS_RESET:
      return {
        ...state,
        updateOrCreatePropertySocialAccountsStatus: { attempt: false, success: false, error: null },
      };
    default:
      return state;
  }
};

export default propertyReducer;
