import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { formatDate } from '../../utils/util';
import { ImageWrapper, MediaSlider, Modal, Button, Input } from '../../components';
import { Images, Icons } from '../../themes';
import { ReactComponent as Loader } from '../../assets/icons/loader.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  approveItemById,
  approveItemReset,
  deleteItemById,
  deleteItemByIdReset,
  getItemById,
  getItemByIdReset,
} from '../../store/actions/marketplace';
import { toast } from 'react-toastify';

const MarketplaceItemScreen = () => {
  const { id } = useParams();
  const [rejectionReason, setRejectionReason] = useState('');
  const [deleteReason, setDeleteReason] = useState('');
  const approveItemStatus = useSelector(state => state.marketplace.approveItemStatus);
  const deleteItemStatus = useSelector(state => state.marketplace.deleteItemStatus);
  const getSelectedItemStatus = useSelector(state => state.marketplace.getSelectedItemInfo);
  const item = useSelector(state => state.marketplace.getSelectedItemInfo.selectedItem);
  const dispatch = useDispatch();
  const modalRef = useRef();
  const rejectModalRef = useRef();
  const approvalModalRef = useRef();
  const deleteModalRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getItemById(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (approveItemStatus.success) {
      dispatch(approveItemReset());
      approvalModalRef.current.hide();
      rejectModalRef.current.hide();
      toast.success("Succesfully changed Item's status");
    }
    if (approveItemStatus.error) {
      dispatch(approveItemReset());
      approvalModalRef.current.hide();
      rejectModalRef.current.hide();
      toast.error('Error occured during Item status update');
    }
  }, [dispatch, approveItemStatus.success, approveItemStatus.error]);

  useEffect(() => {
    if (getSelectedItemStatus.success || getSelectedItemStatus.error) {
      dispatch(getItemByIdReset());
    }
  }, [dispatch, getSelectedItemStatus.success, getSelectedItemStatus.error]);

  useEffect(() => {
    if (deleteItemStatus.success) {
      dispatch(deleteItemByIdReset());
      deleteModalRef.current.hide();
      toast.success('Succesfully deleted item');
      navigate({ pathname: '/marketplace', search: `?page=${1}` });
    }
    if (deleteItemStatus.error) {
      dispatch(deleteItemByIdReset());
      deleteModalRef.current.hide();
      toast.error('Error occured while deleting item');
    }
  }, [dispatch, deleteItemStatus.success, deleteItemStatus.error, navigate]);

  return (
    <div className="marketplace__item__screen">
      {item && (
        <div className="marketplace__item__screen__content">
          <div className="marketplace__item__screen__content__container">
            <div className="marketplace__item__screen__content__container__box left">
              <div>
                <h2>
                  <b>Item</b>
                </h2>
                <p>
                  <b>Title:</b> {item.title}
                </p>
                <p>
                  <b>Price:</b> ${item.price}
                </p>
                <p>
                  <b>Status: </b>
                  {item.status}
                </p>
                <p>
                  <b>Category: </b>
                  {item.category?.name}
                </p>
                <p>
                  <b>Tags: </b>
                  {item.tags?.map(tag => `#${tag.name}`).join(', ')}
                </p>
                <p>
                  <b>Created: </b>
                  {item.createdAt ? formatDate(item.createdAt) : ''}
                </p>
                {item.rejectionReason && (
                  <p>
                    <b>Rejection reason: </b>
                    {item.rejectionReason}
                  </p>
                )}
                {item.approvedAt && (
                  <p>
                    <b>Approved: </b>
                    {item.approvedAt ? formatDate(item.approvedAt) : ''}
                  </p>
                )}
                <p>
                  <b>Description: </b>
                  {item.description}
                </p>
                <p>
                  <b>Brand: </b>
                  {item.brand}
                </p>
                <p>
                  <b>Material: </b>
                  {item.material}
                </p>
                <p>
                  <b>Color: </b>
                  {item.color}
                </p>
              </div>
              <div className="marketplace__item__screen__content__container__box__row">
                <ImageWrapper
                  alt={item.name}
                  source={item.photos?.length ? item.photos[0].url : Images.logo}
                  onClick={() => {
                    modalRef.current.show();
                  }}
                  style={{ cursor: 'pointer', height: '200px', borderRadius: '0.5rem' }}
                />
                {item.status === 'pending' && (
                  <div className="marketplace__item__screen__content__container__box__row__buttons">
                    <Button
                      title="Approve"
                      onClick={() => {
                        approvalModalRef.current.show();
                      }}
                    />
                    <Button
                      title="Reject"
                      onClick={() => {
                        rejectModalRef.current.show();
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            <div
              className="marketplace__item__screen__content__container__box__delete-container"
              onClick={() => {
                deleteModalRef.current.show();
              }}>
              <img src={Icons.deleteIcon} alt="delete-icon" />
            </div>
          </div>
        </div>
      )}
      <Modal
        ref={modalRef}
        onClose={() => {
          modalRef.current.hide();
        }}
        title={item?.title}
        maxWidth="700px">
        <div className="modalContent">
          <MediaSlider pictures={item?.photos} />
        </div>
      </Modal>
      <Modal
        ref={deleteModalRef}
        onClose={() => deleteModalRef.current.hide()}
        title={`${item?.title}`}
        maxWidth="500px">
        {!deleteItemStatus.attempt ? (
          <>
            <p className="modal__content-text">Are you sure you want to delete this item?</p>
            <Input type="text" onChange={setDeleteReason} placeholder="Delete reason" />
            <div className="modal__content__button__container">
              <Button
                title="No"
                onClick={() => deleteModalRef.current.hide()}
                style={{ marginRight: '2rem' }}
                className="modal__content__button__container-btn"
              />
              <Button
                title="Yes"
                disabled={deleteReason === ''}
                onClick={() => {
                  if (deleteReason !== '') {
                    dispatch(deleteItemById(id, deleteReason));
                  }
                }}
                className="modal__content__button__container-btn"
              />
            </div>
          </>
        ) : (
          <div>
            <div className="modal__content__loader__container">
              <p className="modal__content__loader__container-text">Deleting in progress...</p>
              <Loader className="modal__content__loader__container-loader" />
            </div>
          </div>
        )}
      </Modal>
      <Modal
        ref={rejectModalRef}
        onClose={() => rejectModalRef.current.hide()}
        title={`${item?.title}`}
        maxWidth="500px">
        {!approveItemStatus.attempt ? (
          <>
            <p className="modal__content-text">Are you sure you want to reject this item?</p>
            <Input type="text" onChange={setRejectionReason} placeholder="Rejection reason" />
            <div className="modal__content__button__container">
              <Button
                title="No"
                onClick={() => rejectModalRef.current.hide()}
                style={{ marginRight: '2rem' }}
                className="modal__content__button__container-btn"
              />
              <Button
                disabled={rejectionReason === ''}
                title="Yes"
                onClick={() => {
                  if (rejectionReason !== '') {
                    dispatch(approveItemById(item.id, false, rejectionReason));
                  }
                }}
                className="modal__content__button__container-btn"
              />
            </div>
          </>
        ) : (
          <div>
            <div className="modal__content__loader__container">
              <p className="modal__content__loader__container-text">Rejection in progress...</p>
              <Loader className="modal__content__loader__container-loader" />
            </div>
          </div>
        )}
      </Modal>
      <Modal
        ref={approvalModalRef}
        onClose={() => approvalModalRef.current.hide()}
        title={`${item?.title}`}
        maxWidth="500px">
        {!approveItemStatus.attempt ? (
          <>
            <p className="modal__content-text">Are you sure you want to approve this item?</p>
            <div className="modal__content__button__container">
              <Button
                title="No"
                onClick={() => approvalModalRef.current.hide()}
                style={{ marginRight: '2rem' }}
                className="modal__content__button__container-btn"
              />
              <Button
                title="Yes"
                onClick={() => {
                  dispatch(approveItemById(item.id, true, rejectionReason));
                }}
                className="modal__content__button__container-btn"
              />
            </div>
          </>
        ) : (
          <div>
            <div className="modal__content__loader__container">
              <p className="modal__content__loader__container-text">Approval in progress...</p>
              <Loader className="modal__content__loader__container-loader" />
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default MarketplaceItemScreen;
