import {
  PROPERTIES_UPLOAD_PHOTO_ATTEMPT,
  PROPERTIES_UPLOAD_PHOTO_ERROR,
  PROPERTIES_UPLOAD_PHOTO_RESET,
  PROPERTIES_UPLOAD_PHOTO_SUCCESS,
  TOGGLE_AUTO_SYNC_ATTEMPT,
  TOGGLE_AUTO_SYNC_ERROR,
  TOGGLE_AUTO_SYNC_RESET,
  TOGGLE_AUTO_SYNC_SUCCESS,
  SET_EMERGENCY_PHONE_NUMBER_ATTEMPT,
  SET_EMERGENCY_PHONE_NUMBER_SUCCESS,
  SET_EMERGENCY_PHONE_NUMBER_ERROR,
  SET_EMERGENCY_PHONE_NUMBER_RESET,
  SET_PAYMENT_URL_ATTEMPT,
  SET_PAYMENT_URL_SUCCESS,
  SET_PAYMENT_URL_ERROR,
  SET_PAYMENT_URL_RESET,
  SET_RESERVATON_LINK_ATTEMPT,
  SET_RESERVATON_LINK_ERROR,
  SET_RESERVATON_LINK_RESET,
  SET_RESERVATON_LINK_SUCCESS,
  PROPERTIES_UPLOAD_REFERRAL_PHOTO_ATTEMPT,
  PROPERTIES_UPLOAD_REFERRAL_PHOTO_ERROR,
  PROPERTIES_UPLOAD_REFERRAL_PHOTO_SUCCESS,
  PROPERTIES_UPLOAD_REFERRAL_PHOTO_RESET,
} from '../constants';

const INIT_STATE = {
  uploadPhoto: {
    attempt: false,
    success: false,
    error: null,
  },
  uploadReferralPhoto: {
    attempt: false,
    success: false,
    error: null,
  },
  toggledPropertyExternalId: null,
  toggleAutoSyncStatus: {
    attempt: false,
    success: false,
    error: null,
  },
  setEmergencyPhoneNumberStatus: {
    attempt: false,
    success: false,
    error: null,
  },
  setPaymentUrlStatus: {
    attempt: false,
    success: false,
    error: null,
  },
  setReservationLinkStatus: {
    attempt: false,
    success: false,
    error: null,
  },
};

const propertiesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case PROPERTIES_UPLOAD_PHOTO_ATTEMPT:
      return {
        ...state,
        uploadPhoto: { attempt: true, success: false, error: null },
      };
    case PROPERTIES_UPLOAD_PHOTO_SUCCESS:
      return {
        ...state,
        uploadPhoto: { attempt: false, success: true, error: null },
      };
    case PROPERTIES_UPLOAD_PHOTO_ERROR:
      return {
        ...state,
        uploadPhoto: { attempt: false, success: false, error: action.payload },
      };
    case PROPERTIES_UPLOAD_PHOTO_RESET:
      return {
        ...state,
        uploadPhoto: { attempt: false, success: false, error: null },
      };
    case PROPERTIES_UPLOAD_REFERRAL_PHOTO_ATTEMPT:
      return {
        ...state,
        uploadReferralPhoto: { attempt: true, success: false, error: null },
      };
    case PROPERTIES_UPLOAD_REFERRAL_PHOTO_SUCCESS:
      return {
        ...state,
        uploadReferralPhoto: { attempt: false, success: true, error: null },
      };
    case PROPERTIES_UPLOAD_REFERRAL_PHOTO_ERROR:
      return {
        ...state,
        uploadReferralPhoto: { attempt: false, success: false, error: action.payload },
      };
    case PROPERTIES_UPLOAD_REFERRAL_PHOTO_RESET:
      return {
        ...state,
        uploadReferralPhoto: { attempt: false, success: false, error: null },
      };
    case TOGGLE_AUTO_SYNC_ATTEMPT:
      return {
        ...state,
        toggleAutoSyncStatus: { attempt: true, success: false, error: null },
      };
    case TOGGLE_AUTO_SYNC_SUCCESS:
      return {
        ...state,
        toggledPropertyExternalId: action.payload,
        toggleAutoSyncStatus: { attempt: false, success: true, error: null },
      };
    case TOGGLE_AUTO_SYNC_ERROR:
      return {
        ...state,
        toggleAutoSyncStatus: { attempt: false, success: false, error: action.payload },
      };
    case TOGGLE_AUTO_SYNC_RESET:
      return {
        ...state,
        toggledPropertyExternalId: null,
        toggleAutoSyncStatus: { attempt: false, success: false, error: null },
      };
    case SET_EMERGENCY_PHONE_NUMBER_ATTEMPT:
      return {
        ...state,
        setEmergencyPhoneNumberStatus: { attempt: true, success: false, error: null },
      };
    case SET_EMERGENCY_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        setEmergencyPhoneNumberStatus: { attempt: false, success: true, error: null },
      };
    case SET_EMERGENCY_PHONE_NUMBER_ERROR:
      return {
        ...state,
        setEmergencyPhoneNumberStatus: { attempt: false, success: false, error: action.payload },
      };
    case SET_EMERGENCY_PHONE_NUMBER_RESET:
      return {
        ...state,
        setPaymentUrlStatus: { attempt: false, success: false, error: null },
      };
    case SET_PAYMENT_URL_ATTEMPT:
      return {
        ...state,
        setPaymentUrlStatus: { attempt: true, success: false, error: null },
      };
    case SET_PAYMENT_URL_SUCCESS:
      return {
        ...state,
        setPaymentUrlStatus: { attempt: false, success: true, error: null },
      };
    case SET_PAYMENT_URL_ERROR:
      return {
        ...state,
        setPaymentUrlStatus: { attempt: false, success: false, error: action.payload },
      };
    case SET_PAYMENT_URL_RESET:
      return {
        ...state,
        setPaymentUrlStatus: { attempt: false, success: false, error: null },
      };
    case SET_RESERVATON_LINK_ATTEMPT:
      return {
        ...state,
        setReservationLinkStatus: { attempt: true, success: false, error: null },
      };
    case SET_RESERVATON_LINK_SUCCESS:
      return {
        ...state,
        setReservationLinkStatus: { attempt: false, success: true, error: null },
      };
    case SET_RESERVATON_LINK_RESET:
      return {
        ...state,
        setReservationLinkStatus: { attempt: false, success: false, error: null },
      };
    case SET_RESERVATON_LINK_ERROR:
      return {
        ...state,
        setReservationLinkStatus: { attempt: false, success: false, error: action.payload },
      };
    default:
      return state;
  }
};

export default propertiesReducer;
