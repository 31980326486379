import React from 'react';
import { Images } from '../../../themes';

const SplashScreen = () => {
  return (
    <div className="pulse__frame">
      <img className="pulse" src={Images.logo} alt="pulse-logo" />
    </div>
  );
};

export default SplashScreen;
