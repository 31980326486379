import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../components/Button/Button';
import Modal from '../../components/Modal/Modal';
import {
  setEmergencyPhoneNumber as setEmergencyPhoneNumberAction,
  setEmergencyPhoneNumberReset,
  setPaymentUrl as setPaymentUrlAction,
  setReservationLink as setReservationLinkAction,
  setPaymentUrlReset,
  uploadPropertyPhoto,
  uploadPropertyPhotoReset,
  setReservationLinkReset,
  uploadReferralPhoto,
  uploadReferralPhotoReset,
} from '../../store/actions/properties';
import { TOGGLE_PARTIAL_SYNC_MODAL } from '../../store/constants';
import { Icons, Images } from '../../themes';
import { ReactComponent as Loader } from '../../assets/icons/loader.svg';
import { GoogleMap, Input } from '../../components';
import isURL from 'validator/lib/isURL';
import _ from 'lodash';
import { getSocialNetworksReset, getSocialNetworks } from '../../store/actions/common';
import {
  getPropertyById,
  getPropertyByIdReset,
  createOrUpdateSocialAccounts,
  updateOrCreateSocialAccountsReset,
} from '../../store/actions/property';
import { addNewBusiness, addNewBusinessReset } from '../../store/actions/business';
import { ReactComponent as CancelIcon } from '../../assets/icons/cancelButton.svg';
import { adminRoles } from '../../utils/constants';
// import Resizer from 'react-image-file-resizer';

const PropertyScreen = () => {
  const { id } = useParams();
  const property = useSelector(state => state.propertyInfo.property);
  const getPropertyByIdStatus = useSelector(state => state.propertyInfo.getPropertyByIdStatus);
  const [emergencyPhoneNumber, setEmergencyPhoneNumber] = useState(null);
  const [paymentUrl, setPaymentUrl] = useState(null);
  const [reservationLink, setReservationLink] = useState(null);
  const imageInput = useRef();
  const [imagePreview, setImagePreview] = useState(null);
  const uploadingPhoto = useSelector(state => state.properties.uploadPhoto);
  const setEmergencyPhoneNumberStatus = useSelector(state => state.properties.setEmergencyPhoneNumberStatus);
  const setPaymentUrlStatus = useSelector(state => state.properties.setPaymentUrlStatus);
  const setReservationLinkStatus = useSelector(state => state.properties.setReservationLinkStatus);
  const getSocialNetworksStatus = useSelector(state => state.common.getSocialNetworksStatus);
  const updateOrCreatePropertySocialAccountsStatus = useSelector(
    state => state.propertyInfo.updateOrCreatePropertySocialAccountsStatus,
  );
  const phoneModalRef = useRef();
  const paymentModalRef = useRef();
  const reservationLinkModalRef = useRef();
  const socialNetworksModalRef = useRef();
  const localBusinessModalRef = useRef();
  const [address, setAddress] = useState(null);
  const [businessName, setBusinessName] = useState(null);
  const [businessType, setBusinessType] = useState(null);
  const [websiteLink, setWebsiteLink] = useState(null);
  const [discountCode, setDiscountCode] = useState(null);
  const [image, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [lat, setLat] = useState(0);
  const [long, setLong] = useState(0);
  const imageInputForBusiness = useRef();
  const businessInfo = useSelector(state => state.business.businessInfo);
  const referralImageInput = useRef();
  const referralImageModalRef = useRef();
  const [referralPhotoPreview, setReferralPhotoPreview] = useState(property.referralPhoto?.url);
  const uploadReferralPhotoStatus = useSelector(state => state.properties.uploadReferralPhoto);
  const [referralPhoto, setReferralPhoto] = useState(null);
  const [facebookLink, setFacebookLink] = useState('');
  const [instagramLink, setInstagramLink] = useState('');
  const [schedule, setSchedule] = useState({});
  const user = useSelector(state => state.userInfo);

  const dispatch = useDispatch();

  let navigate = useNavigate();

  useEffect(() => {
    if (setEmergencyPhoneNumberStatus.error) {
      toast.error(setEmergencyPhoneNumberStatus.error.message);
      dispatch(setEmergencyPhoneNumberReset());
    }
  }, [setEmergencyPhoneNumberStatus.error, dispatch]);

  useEffect(() => {
    if (setEmergencyPhoneNumberStatus.success) {
      toast.success('Successfuly updated emergency phone number');
      property.emergencyPhoneNumber = emergencyPhoneNumber;
      dispatch(setEmergencyPhoneNumberReset());
      phoneModalRef.current.hide();
    }
  }, [setEmergencyPhoneNumberStatus.success, dispatch]);

  useEffect(() => {
    if (setPaymentUrlStatus.error) {
      toast.error(setPaymentUrlStatus.error.message);
      dispatch(setPaymentUrlReset());
    }
  }, [setPaymentUrlStatus.error, dispatch]);

  useEffect(() => {
    if (setPaymentUrlStatus.success) {
      toast.success('Successfuly updated payment URL');
      property.paymentUrl = paymentUrl;
      dispatch(setPaymentUrlReset());
      paymentModalRef.current.hide();
    }
  }, [setPaymentUrlStatus.success, dispatch]);

  useEffect(() => {
    if (setReservationLinkStatus.error) {
      toast.error(setReservationLinkStatus.error.message);
      dispatch(setReservationLinkReset());
    }
  }, [setReservationLinkStatus.error, dispatch]);

  useEffect(() => {
    if (setReservationLinkStatus.success) {
      toast.success('Successfuly updated reservation link');
      property.reservationLink = reservationLink;
      dispatch(setReservationLinkReset());
      reservationLinkModalRef.current.hide();
    }
  }, [setReservationLinkStatus.success, dispatch]);

  useEffect(() => {
    dispatch(getPropertyById(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getSocialNetworks());
  }, [dispatch]);

  useEffect(() => {
    if (getSocialNetworksStatus.success || getSocialNetworksStatus.error) {
      dispatch(getSocialNetworksReset());
    }
  }, [dispatch, getSocialNetworksStatus.success, getSocialNetworksStatus.error]);

  useEffect(() => {
    if (getPropertyByIdStatus.success || getPropertyByIdStatus.error) {
      dispatch(getPropertyByIdReset());
    }
  }, [dispatch, getPropertyByIdStatus.success, getPropertyByIdStatus.error]);

  useEffect(() => {
    if (updateOrCreatePropertySocialAccountsStatus.success) {
      dispatch(updateOrCreateSocialAccountsReset());
      socialNetworksModalRef.current.hide();
      toast.success('Succesfully saved social accounts for property');
    }
    if (updateOrCreatePropertySocialAccountsStatus.error) {
      dispatch(updateOrCreateSocialAccountsReset());
      toast.error('Something went wrong while adding social accounts to property');
    }
  }, [dispatch, updateOrCreatePropertySocialAccountsStatus.success, updateOrCreatePropertySocialAccountsStatus.error]);

  useEffect(() => {
    if (uploadReferralPhotoStatus.success) {
      referralImageModalRef.current.hide();
      toast.success('Successfully added referral photo to property');
      dispatch(uploadReferralPhotoReset());
    }
    if (uploadReferralPhotoStatus.error) {
      referralImageModalRef.current.hide();
      toast.error('Error while adding referral photo to property');
      dispatch(uploadReferralPhotoReset());
    }
  }, [dispatch, uploadReferralPhotoStatus.success, uploadReferralPhotoStatus.error]);

  const onEditProfilePhotoClick = () => {
    imageInput.current.click();
  };

  // const resizeFile = file =>
  //   new Promise(resolve => {
  //     Resizer.imageFileResizer(
  //       file,
  //       500,
  //       1500,
  //       'JPEG',
  //       100,
  //       0,
  //       uri => {
  //         resolve(uri);
  //       },
  //       'file',
  //     );
  //   });

  useEffect(() => {
    if (uploadingPhoto.success) {
      toast.success('Photo successfully uploaded');
      dispatch(uploadPropertyPhotoReset());
    }
  }, [uploadingPhoto.success, dispatch]);

  useEffect(() => {
    if (uploadingPhoto.error) {
      toast.error(uploadingPhoto.error.message);
      dispatch(uploadPropertyPhotoReset());
    }
  }, [uploadingPhoto.error, dispatch]);

  useEffect(() => {
    if (businessInfo.success) {
      dispatch(addNewBusinessReset());
      setImage(null);
      setSchedule({});
      setPreviewImage(null);
      setLong(0);
      setLat(0);
      localBusinessModalRef.current.hide();
      toast.success('Succesfully added new Business');
    }
    if (businessInfo.error) {
      dispatch(addNewBusinessReset());
      toast.error('Error while adding new Business');
    }
  }, [dispatch, businessInfo.success, businessInfo.error]);

  const handleImagePreview = async e => {
    if (e.target.files.length !== 0) {
      let image_as_base64 = URL.createObjectURL(e.target.files[0]);
      let image_as_files = e.target.files[0];
      // let image = await resizeFile(image_as_files);
      setImagePreview(image_as_base64);

      let formData = new FormData();

      if (image_as_files !== null) {
        formData.append('file', image_as_files);
        formData.append('id', id);

        dispatch(uploadPropertyPhoto(formData));
      }
    }
  };

  const handleReferralPhotoPreview = async e => {
    if (e.target.files.length !== 0) {
      let image_as_base64 = URL.createObjectURL(e.target.files[0]);
      let image_as_files = e.target.files[0];
      // let image = await resizeFile(image_as_files);
      setReferralPhotoPreview(image_as_base64);
      setReferralPhoto(image_as_files);
    }
  };

  const handleReferralPhotoSubmit = async () => {
    if (referralPhoto && referralPhotoPreview) {
      let formData = new FormData();
      formData.append('file', referralPhoto);
      formData.append('id', id);
      property.referralPhoto = referralPhotoPreview;

      dispatch(uploadReferralPhoto(formData));
    }
  };

  const handleSetEmergencyPhoneNumber = () => {
    if (emergencyPhoneNumber.length <= 20) {
      dispatch(setEmergencyPhoneNumberAction(emergencyPhoneNumber, property.externalId));
    } else {
      toast.warning('Please enter a valid phone number');
    }
  };

  const handleSetPaymentUrl = () => {
    if (isURL(paymentUrl)) {
      dispatch(setPaymentUrlAction(paymentUrl, property.externalId));
    } else {
      toast.warning('Please enter a valid payment url');
    }
  };

  const hanldeSetReservationLink = () => {
    if (isURL(reservationLink)) {
      dispatch(setReservationLinkAction(reservationLink, property.externalId));
    } else {
      toast.warning('Please enter a valid reservation link');
    }
  };

  const isSocialAccountsFormValid = () => {
    if (instagramLink) {
      if (!isURL(instagramLink)) {
        toast.warning('Instagram link is not valid!');
        return false;
      }
    }
    if (facebookLink) {
      if (!isURL(facebookLink)) {
        toast.warning('Facebook link is not valid!');
        return false;
      }
    }
    return true;
  };

  const handleSubmit = () => {
    if (isSocialAccountsFormValid()) {
      let socialAccounts = [];
      if (facebookLink !== '') {
        socialAccounts.push({ facebook: facebookLink });
      }
      if (instagramLink !== '') {
        socialAccounts.push({ instagram: instagramLink });
      }
      if (socialAccounts.length > 0) {
        dispatch(createOrUpdateSocialAccounts(socialAccounts, property.id));
      }
    }
  };

  const handleStartTimeChange = (day, time) => {
    setSchedule(prevSchedule => ({
      ...prevSchedule,
      [day]: {
        ...prevSchedule[day],
        start: time,
      },
    }));
  };

  const handleEndTimeChange = (day, time) => {
    setSchedule(prevSchedule => ({
      ...prevSchedule,
      [day]: {
        ...prevSchedule[day],
        end: time,
      },
    }));
  };

  const onAddPhotoClick = () => {
    imageInputForBusiness.current.click();
  };

  const handleAddReferralPhoto = () => {
    referralImageInput.current.click();
  };

  const handleImagePreviewForBusiness = async e => {
    if (e.target.files.length !== 0) {
      let image_as_files = e.target.files[0];

      if (image_as_files.type.startsWith('image/')) {
        let image_as_base64 = URL.createObjectURL(e.target.files[0]);

        setImage(image_as_files);
        setPreviewImage(image_as_base64);
      } else {
        toast.warning('Please select a image file.');
      }
    }
  };

  const renderTimeOptions = () => {
    const hours = Array.from({ length: 12 }, (_, i) => i + 1);
    const timeOptions = [];

    for (const period of ['am', 'pm']) {
      for (const hour of hours) {
        timeOptions.push(`${hour}:00${period}`);
      }
    }

    return timeOptions.map(time => (
      <option key={time} value={time}>
        {time}
      </option>
    ));
  };

  const formValid = () => {
    if (!businessName) {
      toast.warning('Please add business name');
      return false;
    }
    if (!businessType) {
      toast.warning('Please add business type');
      return false;
    }
    if (!(websiteLink && isURL(websiteLink))) {
      toast.warning('Please enter a valid website link');
      return false;
    }
    if (!address) {
      toast.warning('Please select addres on the map');
      return false;
    }
    if (Object.keys(schedule).length !== 0) {
      toast.warning('Please select working hours');
      return false;
    }
    if (image === null) {
      toast.warning('Please add a image');
      return false;
    }
    return true;
  };

  return (
    <div className="property__screen">
      {property && (
        <div className="property__screen__content">
          <div className="property__screen__content__photo__container">
            <img
              className="property__screen__content__photo__container__image"
              alt="coverPhoto"
              src={imagePreview ? imagePreview : property.imageUrl ? property.imageUrl : Images.welcomeScreen}
            />
            <input
              ref={imageInput}
              type="file"
              className="property__screen__content__photo__container__image__input"
              onChange={handleImagePreview}
            />
            <p className="property__screen__content__photo__container__edit" onClick={onEditProfilePhotoClick}>
              Edit Photo
            </p>
          </div>
          <div className="property__screen__content__container">
            <div className="property__screen__content__container__box left">
              <p>
                <b>Name:</b> {property.name}
              </p>
              <p>
                <b>Type:</b> {property.type}
              </p>
              <p>
                <b>Year Built: </b>
                {property.yearBuilt}
              </p>
              <br />
              <p>
                <b>Short Description:</b> <br />
                <i>{property.shortDescription === 'undefined...' ? '' : property.shortDescription}</i>
              </p>
              <br />
              <p>
                <b>Long Description:</b> <br />
                <i>{property.longDescription === 'undefined...' ? '' : property.longDescription}</i>
              </p>
              <br />

              <p>
                <b> Website: </b>
                <a href={property.website}>{property.website}</a>
              </p>
            </div>
            <div className="right">
              <div className="property__screen__content__container__box">
                <p>
                  <b>Country: </b>
                  {property.address?.country}
                </p>
                <p>
                  <b>State:</b> {property.address?.state}
                </p>
                <p>
                  <b>City:</b> {property.address?.city}
                </p>
                <p>
                  <b>Address:</b> {property.address?.address}
                </p>
                <p>
                  <b>Postal Code:</b> {property.address?.postalCode}
                </p>
              </div>
              {user.role === adminRoles.MARKETING ? null : (
                <div
                  className="property__screen__content__container__button"
                  onClick={() =>
                    navigate({
                      pathname: `/properties/${property.id}/amenities?page=1`,
                    })
                  }>
                  <div className="property__screen__content__container__button__content">
                    <img className="property__screen__content__container__icon" src={Icons.amenities} alt="amenities" />
                    <p className="property__screen__content__container__button__text">Amenities</p>
                  </div>
                  <div className="property__screen__content__container__button__content">
                    <p className="property__screen__content__container__button__text">{property.numOfAmenities}</p>
                    <img
                      className="property__screen__content__container__icon_arrow"
                      src={Icons.arrowBlue}
                      alt="right-arrow"
                    />
                  </div>
                </div>
              )}
              <div
                className="property__screen__content__container__button__sync"
                onClick={() => reservationLinkModalRef.current.show()}>
                <div className="property__screen__content__container__button__content__justify__between">
                  <div className="property__screen__content__container__row__wrapper">
                    <img
                      className="property__screen__content__container__icon"
                      src={Icons.link}
                      alt="reservation link"
                    />
                    <p className="property__screen__content__container__button__text">Amenities reservation link</p>
                  </div>
                  <div className="property__screen__content__container__row__wrapper">
                    <p className="property__screen__content__container__button__text">
                      {property.reservationLink ? property.reservationLink : 'Unknown'}
                    </p>
                    <img
                      className="property__screen__content__container__icon_arrow"
                      src={Icons.arrowBlue}
                      alt="arrow"
                    />
                  </div>
                </div>
              </div>

              {user.role === adminRoles.MARKETING ? null : (
                <div
                  className="property__screen__content__container__button"
                  onClick={() =>
                    navigate({
                      pathname: `/properties/${property.id}/property-units?page=1`,
                    })
                  }>
                  <div className="property__screen__content__container__button__content">
                    <img className="property__screen__content__container__icon" src={Icons.room} alt="room" />
                    <p className="property__screen__content__container__button__text">Property Units</p>
                  </div>
                  <div className="property__screen__content__container__button__content">
                    <p className="property__screen__content__container__button__text">{property.numOfPropertyUnits}</p>

                    <img
                      className="property__screen__content__container__icon_arrow"
                      src={Icons.arrowBlue}
                      alt="arrow"
                    />
                  </div>
                </div>
              )}

              {user.role === adminRoles.MARKETING ? null : (
                <div
                  className="property__screen__content__container__button__sync"
                  onClick={() => dispatch({ type: TOGGLE_PARTIAL_SYNC_MODAL, payload: property.externalId })}>
                  <div className="property__screen__content__container__button__content">
                    <img className="property__screen__content__container__icon" src={Icons.sync} alt="sync" />
                    <p className="property__screen__content__container__button__text">Sync Now</p>
                  </div>
                </div>
              )}

              {user.role === adminRoles.MARKETING ? null : (
                <div
                  className="property__screen__content__container__button__sync"
                  onClick={() => phoneModalRef.current.show()}>
                  <div className="property__screen__content__container__button__content__justify__between">
                    <div className="property__screen__content__container__row__wrapper">
                      <img className="property__screen__content__container__icon" src={Icons.phone} alt="phone" />
                      <p className="property__screen__content__container__button__text">Emergency phone</p>
                    </div>
                    <div className="property__screen__content__container__row__wrapper">
                      <p className="property__screen__content__container__button__text">
                        {property.emergencyPhoneNumber ? property.emergencyPhoneNumber : 'Unknown'}
                      </p>
                      <img
                        className="property__screen__content__container__icon_arrow"
                        src={Icons.arrowBlue}
                        alt="arrow"
                      />
                    </div>
                  </div>
                </div>
              )}
              {user.role === adminRoles.MARKETING ? null : (
                <div
                  className="property__screen__content__container__button__sync"
                  onClick={() => paymentModalRef.current.show()}>
                  <div className="property__screen__content__container__button__content__justify__between">
                    <div className="property__screen__content__container__row__wrapper">
                      <img className="property__screen__content__container__icon" src={Icons.payment} alt="payment" />
                      <p className="property__screen__content__container__button__text">Payment URL</p>
                    </div>
                    <div className="property__screen__content__container__row__wrapper">
                      <p className="property__screen__content__container__button__text">
                        {property.paymentUrl ? property.paymentUrl : 'Unknown'}
                      </p>
                      <img
                        className="property__screen__content__container__icon_arrow"
                        src={Icons.arrowBlue}
                        alt="arrow"
                      />
                    </div>
                  </div>
                </div>
              )}

              {user.role === adminRoles.MARKETING ? null : (
                <div
                  className="property__screen__content__container__button__sync"
                  onClick={() => localBusinessModalRef.current.show()}>
                  <div className="property__screen__content__container__button__content__justify__between">
                    <div className="property__screen__content__container__row__wrapper">
                      <img
                        className="property__screen__content__container__icon"
                        src={Icons.business}
                        alt="local business"
                      />
                      <p className="property__screen__content__container__button__text">
                        Add Local Business To Property
                      </p>
                    </div>
                    <div className="property__screen__content__container__row__wrapper">
                      <img
                        className="property__screen__content__container__icon_arrow"
                        src={Icons.arrowBlue}
                        alt="arrow"
                      />
                    </div>
                  </div>
                </div>
              )}
              {user.role === adminRoles.MARKETING ? null : (
                <div
                  className="property__screen__content__container__button__sync"
                  onClick={() => {
                    setReferralPhotoPreview(property?.referralPhoto);
                    referralImageModalRef.current.show();
                  }}>
                  <div className="property__screen__content__container__button__content__justify__between">
                    <div className="property__screen__content__container__row__wrapper">
                      <img className="property__screen__content__container__icon" src={Icons.referral} alt="referral" />
                      <p className="property__screen__content__container__button__text">Add Referral Banner</p>
                    </div>
                    <div className="property__screen__content__container__row__wrapper">
                      <img
                        className="property__screen__content__container__icon_arrow"
                        src={Icons.arrowBlue}
                        alt="arrow"
                      />
                    </div>
                  </div>
                </div>
              )}

              <div
                className="property__screen__content__container__button__sync"
                onClick={() => {
                  if (property?.socialAccounts.length) {
                    for (const socialAccount of property?.socialAccounts) {
                      if (socialAccount.accountLink.includes('https://www.instagram.com/')) {
                        setInstagramLink(socialAccount.accountLink);
                      }
                      if (socialAccount.accountLink.includes('https://www.facebook.com/')) {
                        setFacebookLink(socialAccount.accountLink);
                      }
                    }
                  }
                  socialNetworksModalRef.current.show();
                }}>
                <div className="property__screen__content__container__button__content__justify__between">
                  <div className="property__screen__content__container__row__wrapper">
                    <img className="property__screen__content__container__icon" src={Icons.group} alt="socials" />
                    <p className="property__screen__content__container__button__text">Social Accounts</p>
                  </div>
                  <div className="property__screen__content__container__row__wrapper">
                    {/* <p className="property__screen__content__container__button__text">
                      {property.reservationLink ? property.reservationLink : 'Unknown'}
                    </p> */}
                    <img
                      className="property__screen__content__container__icon_arrow"
                      src={Icons.arrowBlue}
                      alt="arrow"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            ref={phoneModalRef}
            onClose={() => phoneModalRef.current.hide()}
            title={
              setEmergencyPhoneNumberStatus.attempt
                ? 'Updating emergency phone number'
                : 'Set your property emergency phone number'
            }
            maxWidth="500px">
            {!setEmergencyPhoneNumberStatus.attempt ? (
              <>
                <Input type="text" onChange={setEmergencyPhoneNumber} placeholder="Emergency phone number" />
                <div className="modal__content__button__container">
                  <Button
                    title="Cancel"
                    onClick={() => phoneModalRef.current.hide()}
                    style={{ marginRight: '2rem' }}
                    className="modal__content__button__container-btn"
                  />
                  <Button
                    title="Confirm"
                    onClick={handleSetEmergencyPhoneNumber}
                    className="modal__content__button__container-btn"
                  />
                </div>
              </>
            ) : (
              <div>
                <div className="modal__content__loader__container">
                  <Loader className="modal__content__loader__container-loader" />
                </div>
              </div>
            )}
          </Modal>
          <Modal
            ref={paymentModalRef}
            onClose={() => paymentModalRef.current.hide()}
            title={setPaymentUrlStatus.attempt ? 'Updating payment URL' : 'Set your property payment URL'}
            maxWidth="500px">
            {!setPaymentUrlStatus.attempt ? (
              <>
                <Input type="text" onChange={setPaymentUrl} placeholder="Payment URL" />
                <div className="modal__content__button__container">
                  <Button
                    title="Cancel"
                    onClick={() => paymentModalRef.current.hide()}
                    style={{ marginRight: '2rem' }}
                    className="modal__content__button__container-btn"
                  />
                  <Button
                    title="Confirm"
                    onClick={handleSetPaymentUrl}
                    className="modal__content__button__container-btn"
                  />
                </div>
              </>
            ) : (
              <div>
                <div className="modal__content__loader__container">
                  <Loader className="modal__content__loader__container-loader" />
                </div>
              </div>
            )}
          </Modal>
          <Modal
            ref={reservationLinkModalRef}
            onClose={() => reservationLinkModalRef.current.hide()}
            title={
              setReservationLinkStatus.attempt ? 'Updating reservation link' : 'Set your property reservation link'
            }
            maxWidth="500px">
            {!setReservationLinkStatus.attempt ? (
              <>
                <Input type="text" onChange={setReservationLink} placeholder="Reservation Link" />
                <div className="modal__content__button__container">
                  <Button
                    title="Cancel"
                    onClick={() => reservationLinkModalRef.current.hide()}
                    style={{ marginRight: '2rem' }}
                    className="modal__content__button__container-btn"
                  />
                  <Button
                    title="Confirm"
                    onClick={hanldeSetReservationLink}
                    className="modal__content__button__container-btn"
                  />
                </div>
              </>
            ) : (
              <div>
                <div className="modal__content__loader__container">
                  <Loader className="modal__content__loader__container-loader" />
                </div>
              </div>
            )}
          </Modal>
          <Modal
            ref={socialNetworksModalRef}
            onClose={() => {
              socialNetworksModalRef.current.hide();
              if (property?.socialAccounts.length) {
                let hasInstagram = false;
                let hasFacebook = false;

                for (const socialAccount of property?.socialAccounts) {
                  if (socialAccount.accountLink.includes('https://www.instagram.com/')) {
                    setInstagramLink(socialAccount.accountLink);
                    hasInstagram = true;
                  }
                  if (socialAccount.accountLink.includes('https://www.facebook.com/')) {
                    setFacebookLink(socialAccount.accountLink);
                    hasFacebook = true;
                  }
                }

                if (!hasInstagram) {
                  setInstagramLink('');
                }
                if (!hasFacebook) {
                  setFacebookLink('');
                }
              } else {
                setInstagramLink('');
                setFacebookLink('');
              }
            }}
            title={'Set your property social accounts'}
            maxWidth="500px">
            {!updateOrCreatePropertySocialAccountsStatus.attempt ? (
              <>
                <Input type="text" value={facebookLink} onChange={setFacebookLink} placeholder="Facebook" />
                <Input type="text" value={instagramLink} onChange={setInstagramLink} placeholder="Instagram" />

                <div className="modal__content__button__container">
                  <Button
                    title="Cancel"
                    onClick={() => {
                      socialNetworksModalRef.current.hide();
                      if (property?.socialAccounts.length) {
                        let hasInstagram = false;
                        let hasFacebook = false;

                        for (const socialAccount of property?.socialAccounts) {
                          if (socialAccount.accountLink.includes('https://www.instagram.com/')) {
                            setInstagramLink(socialAccount.accountLink);
                            hasInstagram = true;
                          }
                          if (socialAccount.accountLink.includes('https://www.facebook.com/')) {
                            setFacebookLink(socialAccount.accountLink);
                            hasFacebook = true;
                          }
                        }

                        if (!hasInstagram) {
                          setInstagramLink('');
                        }
                        if (!hasFacebook) {
                          setFacebookLink('');
                        }
                      } else {
                        setInstagramLink('');
                        setFacebookLink('');
                      }
                    }}
                    style={{ marginRight: '2rem' }}
                    className="modal__content__button__container-btn"
                  />
                  <Button
                    disabled={instagramLink === '' && facebookLink === ''}
                    title="Confirm"
                    onClick={handleSubmit}
                    className="modal__content__button__container-btn"
                  />
                </div>
              </>
            ) : (
              <div>
                <div className="modal__content__loader__container">
                  <Loader className="modal__content__loader__container-loader" />
                </div>
              </div>
            )}
          </Modal>
          <Modal
            ref={localBusinessModalRef}
            onClose={() => {
              localBusinessModalRef.current.hide();
              setImage(null);
              setSchedule({});
              setPreviewImage(null);
              setLong(0);
              setLat(0);
            }}
            title={'Add new Local Business Partnership'}
            maxWidth="500px">
            {!businessInfo.attempt ? (
              <>
                <Input type="text" onChange={setBusinessName} placeholder="Name" />
                <Input type="text" onChange={setBusinessType} placeholder="Type" />
                <Input type="text" onChange={setWebsiteLink} placeholder="Website Link" />
                <Input type="text" onChange={setDiscountCode} placeholder="Discount Code" />

                <div className="time-picker-container">
                  {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(day => (
                    <div key={day}>
                      <label className="time-picker-label" htmlFor={`${day}-start`}>
                        {day}:
                      </label>

                      <div className="first">
                        <select
                          id={`${day}-start`}
                          className="time-picker-select"
                          onChange={e => handleStartTimeChange(day, e.target.value)}
                          value={(schedule[day] && schedule[day].start) || ''}>
                          <option value="">Select start time</option>
                          {renderTimeOptions()}
                        </select>
                      </div>
                      <div>
                        <select
                          id={`${day}-end`}
                          className="time-picker-select"
                          onChange={e => handleEndTimeChange(day, e.target.value)}
                          value={(schedule[day] && schedule[day].end) || ''}>
                          <option value="">Select end time</option>
                          {renderTimeOptions()}
                        </select>
                      </div>
                    </div>
                  ))}
                </div>
                {!previewImage ? (
                  <>
                    <div
                      style={{
                        width: '100%',
                        marginTop: '20px',
                        marginBottom: '2rem',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                      }}>
                      <Button title="Add Photo" onClick={onAddPhotoClick} />
                    </div>
                    <input
                      ref={imageInputForBusiness}
                      type="file"
                      className="business__screen__content__photo__container__image__input"
                      onChange={handleImagePreviewForBusiness}
                    />
                  </>
                ) : (
                  <div className="modalImage">
                    <div className="imageIconContainer">
                      <div
                        className="iconContainer"
                        onClick={() => {
                          setPreviewImage(null);
                          setImage(null);
                        }}>
                        <CancelIcon className="icon" fill={'#ff0033'} />
                      </div>
                      <img
                        alt="coverPhoto"
                        style={{ height: '250px', width: '450px', display: 'flex', alignSelf: 'center' }}
                        src={previewImage}
                      />
                    </div>
                  </div>
                )}

                <div>
                  <GoogleMap address={address} setAddress={setAddress} setLat={setLat} setLong={setLong} />
                </div>
                <div className="modal__content__button__container">
                  <Button
                    title="Cancel"
                    onClick={() => {
                      localBusinessModalRef.current.hide();
                      setImage(null);
                      setSchedule({});
                      setPreviewImage(null);
                      setLong(0);
                      setLat(0);
                    }}
                    style={{ marginRight: '2rem' }}
                    className="modal__content__button__container-btn"
                  />
                  <Button
                    title="Confirm"
                    onClick={() => {
                      if (formValid()) {
                        let formData = new FormData();

                        if (image !== null) {
                          formData.append('file', image);
                        }
                        if (address !== null) {
                          formData.append('location', address);
                        }
                        if (businessName !== null) {
                          formData.append('name', businessName);
                        }
                        if (businessType !== null) {
                          formData.append('type', businessType);
                        }
                        if (websiteLink !== null) {
                          formData.append('websiteLink', websiteLink);
                        }
                        if (discountCode !== null) {
                          formData.append('discountCode', discountCode);
                        }
                        if (id) {
                          formData.append('propertyId', id);
                        }

                        if (Object.keys(schedule).length !== 0) {
                          formData.append('hoursOfOperation', JSON.stringify(schedule));
                        }
                        if (lat) {
                          formData.append('lat', lat);
                        }
                        if (long) {
                          formData.append('long', long);
                        }
                        dispatch(addNewBusiness(formData));
                      }
                    }}
                    className="modal__content__button__container-btn"
                  />
                </div>
              </>
            ) : (
              <div>
                <div className="modal__content__loader__container">
                  <Loader className="modal__content__loader__container-loader" />
                </div>
              </div>
            )}
          </Modal>
          <Modal
            ref={referralImageModalRef}
            onClose={() => referralImageModalRef.current.hide()}
            title={'Referral photo'}
            maxWidth="500px">
            {!uploadReferralPhotoStatus.attempt ? (
              <>
                {referralPhotoPreview && (
                  <div className="modalImage">
                    <div className="imageIconContainer">
                      <div
                        className="iconContainer"
                        onClick={() => {
                          setReferralPhotoPreview(null);
                        }}>
                        <CancelIcon className="icon" fill={'#ff0033'} />
                      </div>
                      <img
                        alt="coverPhoto"
                        style={{ height: '250px', width: '450px', display: 'flex', alignSelf: 'center' }}
                        src={referralPhotoPreview}
                      />
                    </div>
                  </div>
                )}
                <div
                  style={{
                    width: '100%',
                    marginTop: '1rem',
                    marginBottom: '1rem',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  }}>
                  <Button title="Add Photo" onClick={handleAddReferralPhoto} />
                </div>
                <input
                  ref={referralImageInput}
                  type="file"
                  className="business__screen__content__photo__container__image__input"
                  onChange={handleReferralPhotoPreview}
                />
                <div
                  style={{
                    width: '100%',
                    marginTop: '1rem',
                    marginBottom: '1rem',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  }}>
                  <Button title="Submit" onClick={handleReferralPhotoSubmit} disabled={!referralPhoto} />
                </div>
              </>
            ) : (
              <div>
                <div className="modal__content__loader__container">
                  <Loader className="modal__content__loader__container-loader" />
                </div>
              </div>
            )}
          </Modal>
        </div>
      )}
    </div>
  );
};

export default PropertyScreen;
