import axiosApiClient from '..';
import { handleError } from '../../utils/errors';

const BASE_URL = '/propertyClusters';

class PropertyClusterService {
  addNewPropertyCluster = async (propertyId, clusterId) => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}/admin`, { propertyId, clusterId });
      if (response.status !== 201) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      throw handleError(err, 'Error occured while adding new property cluster.');
    }
  };

  deletePropertyClusterById = async (clusterId, propertyId) => {
    try {
      const response = await axiosApiClient.delete(`${BASE_URL}/admin/${clusterId}/${propertyId}`);
      if (response.status !== 200) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      console.log(err);
      throw handleError(err, 'Error occured while deleting property cluster.');
    }
  };
}

export default new PropertyClusterService();
