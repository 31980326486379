import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { ReactComponent as CancelIcon } from '../../assets/icons/cancelButton.svg';

const ItemComponent = ({ item, index, clusterId, modalRef, setPropertyIdForDeleting, setClusterIdForDeleting }) => {
  return (
    <Draggable draggableId={item.newId.toString()} index={index} key={item.newId} isDragDisabled={true}>
      {(provided, snapshot) => (
        <div className="item" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          {item.name}
          <div
            className="iconItemContainer"
            onClick={() => {
              setPropertyIdForDeleting(item.id);
              setClusterIdForDeleting(clusterId);
              modalRef.current.show();
            }}>
            <CancelIcon width={20} height={20} fill={'#00000'} />
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default ItemComponent;
