import propertyService from '../../api/services/property';
import {
  GET_PROPERTY_BY_ID_ERROR,
  GET_PROPERTY_BY_ID_SUCCESS,
  GET_PROPERTY_BY_ID_ATEMPT,
  GET_PROPERTY_BY_ID_RESET,
  UPDATE_OR_CREATE_SOCIAL_ACCOUNTS_ATTEMPT,
  UPDATE_OR_CREATE_SOCIAL_ACCOUNTS_SUCCESS,
  UPDATE_OR_CREATE_SOCIAL_ACCOUNTS_ERROR,
  UPDATE_OR_CREATE_SOCIAL_ACCOUNTS_RESET,
} from '../constants';

import commonService from '../../api/services/common';

export const getPropertyById = id => {
  return async (dispatch, getState) => {
    try {
      const { propertyInfo } = getState();
      if (!propertyInfo.getPropertyByIdStatus.attempt) {
        dispatch({ type: GET_PROPERTY_BY_ID_ATEMPT });

        const response = await propertyService.getPropertyById(id);
        dispatch({
          type: GET_PROPERTY_BY_ID_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({ type: GET_PROPERTY_BY_ID_ERROR, payload: error });
      console.log(error);
    }
  };
};

export const createOrUpdateSocialAccounts = (socialAccounts, propertyId) => {
  return async (dispatch, getState) => {
    try {
      const { propertyInfo } = getState();
      if (!propertyInfo.updateOrCreatePropertySocialAccountsStatus.attempt) {
        dispatch({ type: UPDATE_OR_CREATE_SOCIAL_ACCOUNTS_ATTEMPT });

        const response = await commonService.createOrUpdateSocialAccounts(socialAccounts, propertyId);
        dispatch({
          type: UPDATE_OR_CREATE_SOCIAL_ACCOUNTS_SUCCESS,
          payload: response.data.socialAccounts,
        });
      }
    } catch (error) {
      dispatch({ type: UPDATE_OR_CREATE_SOCIAL_ACCOUNTS_ERROR, payload: error });
      console.log(error);
    }
  };
};

export const updateOrCreateSocialAccountsReset = () => ({ type: UPDATE_OR_CREATE_SOCIAL_ACCOUNTS_RESET });

export const getPropertyByIdReset = () => ({ type: GET_PROPERTY_BY_ID_RESET });
