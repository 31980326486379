import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import propertiesService from '../../api/services/properties';
import { Button, ImageWrapper, Modal, Table, TableItem } from '../../components';
import { uploadAmenityPhoto, uploadPropertyPhotoReset } from '../../store/actions/properties';
import { Images } from '../../themes';
import { ReactComponent as Loader } from '../../assets/icons/loader.svg';
import { ORDER } from '../../components/Table/Table';

const AmenitiesScreen = () => {
  const [propertyAmenities, setPropertyAmenities] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [perPage, setPerPage] = useState(1);
  const uploadingPhoto = useSelector(state => state.properties.uploadPhoto);

  const [imagePreview, setImagePreview] = useState(null);
  const [amenityImage, setAmenityImage] = useState(null);
  const [amenityId, setAmenityId] = useState(null);
  const [sortOptions, setSortOptions] = useState({
    sortBy: '',
    order: ORDER.ASC,
  });
  const [amenityNameForWhichPhotoIsBeingEdited, setAmenityNameForWhichPhotoIsBeingEdited] = useState(null);

  const { id } = useParams();
  let page = +useLocation().search.split('=')[1];

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const modalRef = useRef();
  const imageInput = useRef();

  useEffect(() => {
    if (uploadingPhoto.success) {
      const amenities = [...propertyAmenities];

      const updatedAmenity = propertyAmenities.findIndex(pa => pa.id === amenityId);
      amenities[updatedAmenity] = { ...amenities[updatedAmenity], imageUrl: imagePreview };
      setPropertyAmenities(amenities);

      modalRef.current.hide();
      setAmenityId(null);
      setImagePreview(null);
      setAmenityImage(null);
      toast.success('Photo successfully uploaded');

      dispatch(uploadPropertyPhotoReset());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadingPhoto.success, dispatch]);

  useEffect(() => {
    if (uploadingPhoto.error) {
      toast.error(uploadingPhoto.error.message);
      dispatch(uploadPropertyPhotoReset());
    }
  }, [uploadingPhoto.error, dispatch]);

  const setPage = async page => {
    setCurrentPage(page);
    navigate({
      pathname: `/properties/${id}/amenities`,
      search: `?page=${page}`,
    });
  };

  const handleSort = async (sortBy, order) => {
    setSortOptions({ sortBy, order });
  };

  useEffect(() => {
    const fetchPropertyAmenities = async () => {
      try {
        const response1 = await propertiesService.getPropertyAmenities(
          id,
          Number(page),
          sortOptions.sortBy,
          sortOptions.order,
        );
        setPerPage(response1.perPage);
        setTotalCount(response1.total);
        setPropertyAmenities(response1.data);
      } catch (error) {
        console.log(error.message);
      }
    };
    setCurrentPage(page);

    if (page > 0) fetchPropertyAmenities();
  }, [page, id, sortOptions]);

  const onEditProfilePhotoClick = () => {
    imageInput.current.click();
  };

  const handleImagePreview = async e => {
    if (e.target.files.length !== 0) {
      let image_as_base64 = URL.createObjectURL(e.target.files[0]);
      let image_as_files = e.target.files[0];
      // let image = await resizeFile(image_as_files);
      setImagePreview(image_as_base64);

      let formData = new FormData();

      if (image_as_files !== null) {
        formData.append('file', image_as_files);
        formData.append('id', amenityId);

        dispatch(uploadAmenityPhoto(formData));
      }
    }
  };

  const tableHeader = [
    {
      key: 'id',
      label: 'Id',
      sortable: true,
    },
    {
      key: 'name',
      label: 'Name',
      sortable: true,
    },

    {
      key: 'type',
      label: 'Type',
      sortable: true,
    },
    {
      key: 'ILSAmenityName',
      label: 'ILS Amenity Name',
      sortable: true,
    },
  ];
  return (
    <div className="properties__screen">
      <div className="properties__screen__content">
        <div className="properties__screen__header">
          <p className="properties__screen__header-title">Property Amenities</p>
        </div>
        <Table
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={perPage}
          hasExtraActions
          handleSort={handleSort}
          setPage={setPage}
          noDataContent="No Data"
          headerInformation={tableHeader}>
          {propertyAmenities.map((propertyAmenity, index) => {
            return (
              <Fragment key={index}>
                <TableItem data={propertyAmenity.id} />
                <TableItem data={propertyAmenity.name} />
                <TableItem data={propertyAmenity.type} />
                <TableItem data={propertyAmenity.ILSAmenityName} />

                <TableItem
                  data={
                    <ImageWrapper
                      alt={propertyAmenity.name}
                      source={propertyAmenity.imageUrl ? propertyAmenity.imageUrl : Images.defaultAmenityImage}
                      onClick={() => {
                        setAmenityId(propertyAmenity.id);
                        setAmenityImage(propertyAmenity.imageUrl);
                        setAmenityNameForWhichPhotoIsBeingEdited(propertyAmenity.name);
                        modalRef.current.show();
                      }}
                      style={{ cursor: 'pointer', height: '100px' }}
                    />
                    // <img
                    //   className="tableImage"
                    //   onClick={() => {
                    //     setAmenityId(propertyAmenity.id);
                    //     setAmenityImage(propertyAmenity.imageUrl);
                    //     modalRef.current.show();
                    //   }}
                    //   onLoad={() => console.log('asd')}
                    //   alt="coverPhoto"
                    //   src={}
                    // />
                  }
                />
              </Fragment>
            );
          })}
        </Table>

        <Modal
          ref={modalRef}
          onClose={() => {
            setAmenityImage(null);
            setImagePreview(null);
            setAmenityNameForWhichPhotoIsBeingEdited(null);
            modalRef.current.hide();
          }}
          title={amenityNameForWhichPhotoIsBeingEdited + ' Amenity Photo'}
          maxWidth="700px">
          <div className="modalContent">
            <img
              className="modalImage"
              alt="coverPhoto"
              src={imagePreview ? imagePreview : amenityImage ? amenityImage : Images.defaultAmenityImage}
            />
            <div style={{ width: '300px', marginTop: '20px' }}>
              {uploadingPhoto.attempt ? <Loader /> : <Button title="Edit Photo" onClick={onEditProfilePhotoClick} />}
            </div>
            <input
              ref={imageInput}
              type="file"
              className="property__screen__content__photo__container__image__input"
              onChange={handleImagePreview}
            />
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default AmenitiesScreen;
