import axiosApiClient from '..';
import { handleError } from '../../utils/errors';

const BASE_URL = '/users';

class UserService {
  fetchUserInfo = async () => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/admin`);
      if (response.status !== 200) {
        throw new Error();
      }
      const { user } = response.data.data;
      return user;
    } catch (err) {
      throw handleError(err, "Error occured while fetching user's info.");
    }
  };

  getUserById = async id => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/${id}`);
      if (response.status !== 200) {
        throw new Error();
      }
      const { data } = response.data;
      return { data };
    } catch (err) {
      throw handleError(err, 'Error occured while fetching user.');
    }
  };

  getCustomers = async (page, sortBy, order, searchTerm) => {
    try {
      if (!sortBy) {
        sortBy = '';
      }
      if (!order) {
        order = 'ASC';
      }
      const response = await axiosApiClient.get(
        `${BASE_URL}?page=${page}&sortBy=${sortBy}&order=${order}&searchTerm=${searchTerm}`,
      );
      if (response.status !== 200) {
        throw new Error();
      }
      const { data, total, perPage } = response.data;
      return { data, total, perPage };
    } catch (err) {
      throw handleError(err, 'Error occured while fetching customers.');
    }
  };
  toggleDeleteUser = async userExternalId => {
    try {
      const response = await axiosApiClient.delete(`${BASE_URL}/admin/delete-user`, {
        data: { userExternalId },
      });
      if (response.status !== 200) {
        throw new Error();
      }
    } catch (err) {
      throw handleError(err, `Error occured while toggling delete user with externalId: ${userExternalId}.`);
    }
  };
}

export default new UserService();
