import { axiosAuthClient } from '..';
import { handleError } from '../../utils/errors';

class AuthService {
  login = async (email, password) => {
    try {
      const response = await axiosAuthClient.post('/login/admin', {
        email,
        password,
      });
      if (response.status !== 201) {
        throw new Error();
      }
      const { accessToken, refreshToken, user } = response.data.data;
      return { accessToken, refreshToken, user };
    } catch (err) {
      throw handleError(err, 'Error occured while logging in');
    }
  };

  logout = async refreshToken => {
    try {
      const response = await axiosAuthClient.post('/logout/admin', { refreshToken });
      if (response.status !== 200) {
        throw new Error();
      }
    } catch (err) {
      throw handleError(err, 'Error occured while logging out');
    }
  };
}

export default new AuthService();
