/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import { Icons } from '../../themes';
import { SidebarData } from './SidebarData';
import { logout } from '../../store/actions/auth';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import { partialSyncReset, partialSyncWithEntrata, syncReset, syncWithEntrata } from '../../store/actions/sync';
import { ReactComponent as Loader } from '../../assets/icons/loader.svg';
import { toast } from 'react-toastify';
import { adminRoles } from '../../utils/constants';

const Navbar = () => {
  const user = useSelector(state => state.userInfo);
  const syncing = useSelector(state => state.sync.syncing);
  const partialSyncing = useSelector(state => state.sync.partialSyncStatus);
  const showPartialSyncModal = useSelector(state => state.sync.showPartialSyncModal);
  const partialSyncPropertyExternalId = useSelector(state => state.sync.partialSyncPropertyExternalId);
  const numberOfUnreadNotifications = useSelector(
    state => state.notification.notificationInfo.numberOfUnreadNotifications,
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sidebar, setSidebar] = useState(false);
  const modalRef = useRef();
  const modalPartialRef = useRef();

  const showSidebar = () => setSidebar(prev => !prev);

  const handleLogout = () => {
    dispatch(logout());
  };

  const fetchAllData = () => {
    dispatch(syncWithEntrata());
  };

  const fetchPartialData = () => {
    dispatch(partialSyncWithEntrata(partialSyncPropertyExternalId));
  };

  useEffect(() => {
    if (syncing.error) {
      toast.error(syncing.error.message);
      dispatch(syncReset());
    }
  }, [syncing.error, dispatch]);

  useEffect(() => {
    if (syncing.success) {
      modalRef.current.hide();
      dispatch(syncReset());
    }
  }, [syncing.success, dispatch]);

  useEffect(() => {
    if (partialSyncing.error) {
      toast.error(partialSyncing.error.message);
      dispatch(partialSyncReset());
    }
  }, [partialSyncing.error, dispatch]);

  useEffect(() => {
    if (partialSyncing.success) {
      modalPartialRef.current.hide();
      dispatch(partialSyncReset());
    }
  }, [partialSyncing.success, dispatch]);

  useEffect(() => {
    if (showPartialSyncModal) {
      modalPartialRef.current.show();
    }
  }, [showPartialSyncModal]);

  return (
    <>
      <div className="navbar">
        <div className="menu-bars">
          <img src={Icons.menu} alt="menu" onClick={showSidebar} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <DropdownMenu
            buttonContent={
              <div className="dropdownToggler">
                <span className="headerCompanyName">{user.email}</span>
                <img className="arrowIcon" src={Icons.arrow} alt="" />
              </div>
            }>
            <div className="dropdownWrapper">
              <DropdownMenu.Item>
                <button className="dropdownButton" onClick={handleLogout}>
                  Log Out
                </button>
              </DropdownMenu.Item>
            </div>
          </DropdownMenu>
          {user?.role !== adminRoles.MARKETING && (
            <div
              onClick={() => {
                navigate({ pathname: '/notifications', search: `?page=${1}` });
              }}
              style={{ marginRight: '3.2rem', cursor: 'pointer', position: 'relative' }}>
              {numberOfUnreadNotifications > 0 && (
                <div
                  style={{
                    position: 'absolute',
                    top: -5,
                    right: 0,
                    backgroundColor: 'red',
                    width: '10px',
                    height: '10px',
                    borderRadius: '5px',
                  }}></div>
              )}

              <img src={Icons.bell} alt="notifications" />
            </div>
          )}
        </div>
      </div>
      <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
        <ul className="nav-menu-items" onClick={showSidebar}>
          <li className="navbar-toggle">
            <div className="menu-bars">
              <img src={Icons.close} alt="close" />
            </div>
          </li>
          {SidebarData.map((item, index) => {
            if (item.roles.includes(user.role)) {
              return (
                <React.Fragment key={index}>
                  {item.title === 'Marketplace' ? (
                    <li className={item.cName}>
                      <div className="header-menu">
                        <img src={item.icon} alt={item.title} />
                        <span>{item.title}</span>
                      </div>

                      {item.subMenus && (
                        <div className="sub-menus">
                          {item.subMenus.map((subMenu, index) => {
                            if (subMenu.roles.includes(user.role)) {
                              return (
                                <Link to={subMenu.path} className="sub-menu" key={subMenu.title + index}>
                                  <img src={subMenu.icon} alt={subMenu.title} />
                                  <span>{subMenu.title}</span>
                                </Link>
                              );
                            } else return null;
                          })}
                        </div>
                      )}
                    </li>
                  ) : (
                    <li className={item.cName}>
                      <Link to={item.path}>
                        <img src={item.icon} alt={item.title} />
                        <span>{item.title}</span>
                      </Link>
                    </li>
                  )}
                </React.Fragment>
              );
            } else return null;
          })}
        </ul>
        {user.role === adminRoles.SUPER ? (
          <div
            className="nav-text"
            style={{ marginBottom: '3rem', cursor: 'pointer' }}
            onClick={() => modalRef.current.show()}>
            <a>
              <img src={Icons.sync} alt="sync" />
              <span>Sync with Entrata</span>
            </a>
          </div>
        ) : null}
      </nav>
      <Modal ref={modalRef} onClose={() => modalRef.current.hide()} title="Sync with Entrata" maxWidth="500px">
        {!syncing.attempt ? (
          <>
            <p className="modal__content-text">Are you sure you want to sync with Entrata?</p>
            <div className="modal__content__button__container">
              <Button
                title="No"
                onClick={() => modalRef.current.hide()}
                style={{ marginRight: '2rem' }}
                className="modal__content__button__container-btn"
              />
              <Button title="Yes" onClick={fetchAllData} className="modal__content__button__container-btn" />
            </div>
          </>
        ) : (
          <div>
            <div className="modal__content__loader__container">
              <p className="modal__content__loader__container-text">Syncing in progress...</p>
              <Loader className="modal__content__loader__container-loader" />
            </div>
            <p className="modal__content__loader__container-small-text">Please wait. This can take up to 60 minutes</p>
          </div>
        )}
      </Modal>
      <Modal
        ref={modalPartialRef}
        onClose={() => modalPartialRef.current.hide()}
        title="Partially Sync with Entrata"
        maxWidth="500px">
        {!partialSyncing.attempt ? (
          <>
            <p className="modal__content-text">Are you sure you want to sync only this property with Entrata?</p>
            <div className="modal__content__button__container">
              <Button
                title="No"
                onClick={() => modalPartialRef.current.hide()}
                style={{ marginRight: '2rem' }}
                className="modal__content__button__container-btn"
              />
              <Button title="Yes" onClick={fetchPartialData} className="modal__content__button__container-btn" />
            </div>
          </>
        ) : (
          <div>
            <div className="modal__content__loader__container">
              <p className="modal__content__loader__container-text">Syncing in progress...</p>
              <Loader className="modal__content__loader__container-loader" />
            </div>
            <p className="modal__content__loader__container-small-text">Please wait. This can take several minutes</p>
          </div>
        )}
      </Modal>
    </>
  );
};

export default Navbar;
