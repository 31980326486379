import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import propertiesService from '../../api/services/properties';
import Table, { ORDER } from '../../components/Table/Table';
import TableItem from '../../components/Table/TableItem';
import { Button, Input } from '../../components';
import { Icons } from '../../themes';
import { useDispatch, useSelector } from 'react-redux';
import { toggleAutoSync, toggleAutoSyncReset } from '../../store/actions/properties';
import _ from 'lodash';

const PropertiesScreen = () => {
  const dispatch = useDispatch();

  const toggleAutoSyncStatus = useSelector(state => state.properties.toggleAutoSyncStatus);
  useEffect(() => {
    if (toggleAutoSyncStatus.success || toggleAutoSyncStatus.error) {
      dispatch(toggleAutoSyncReset());
    }
  }, [toggleAutoSyncStatus, dispatch]);

  const [searchTerm, setsearchTerm] = useState([]);
  const [properties, setProperties] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [perPage, setPerPage] = useState(1);
  const [sortOptions, setSortOptions] = useState({
    sortBy: '',
    order: ORDER.ASC,
  });

  const toggledPropertyExternalId = useSelector(state => state.properties.toggledPropertyExternalId);
  useEffect(() => {
    if (toggledPropertyExternalId) {
      let toggledProperty = properties.filter(property => property.externalId === toggledPropertyExternalId)[0];
      let toggledPropertyCopy = { ...toggledProperty };
      toggledPropertyCopy.isAutoSynced = !toggledPropertyCopy.isAutoSynced;
      let index = properties.findIndex(x => x.externalId === toggledPropertyExternalId);
      let newArr = [...properties];
      newArr[index] = toggledPropertyCopy;
      setProperties(newArr);
      dispatch(toggleAutoSyncReset());
    }
  }, [toggledPropertyExternalId, dispatch]);

  let page = +useLocation().search.split('=')[1];
  let navigate = useNavigate();

  const setPage = async page => {
    setCurrentPage(page);
    navigate({
      pathname: '/properties',
      search: `?page=${page}`,
    });
  };

  const tableHeader = [
    {
      key: 'name',
      label: 'Name',
      sortable: true,
    },
    {
      key: 'type',
      label: 'Type',
      sortable: true,
    },
    {
      key: 'website',
      label: 'Website',
      sortable: true,
    },
    {
      key: 'yearBuilt',
      label: 'Year Built',
      sortable: true,
    },
    {
      key: 'autoSync',
      label: 'Auto Sync',
    },
  ];

  const debouncedSearch = useCallback(
    _.debounce(async (sortOptions, searchTerm, page) => {
      try {
        const response1 = await propertiesService.getProperties(
          Number(page),
          sortOptions.sortBy,
          sortOptions.order,
          searchTerm,
        );
        setPerPage(response1.perPage);
        setTotalCount(response1.total);
        setProperties(response1.data);
      } catch (error) {
        console.log(error.message);
      }
    }, 300),
    [],
  );

  useEffect(() => {
    setCurrentPage(page);
    if (page > 0) debouncedSearch(sortOptions, searchTerm, page);
  }, [page, sortOptions, searchTerm, debouncedSearch]);

  const debouncedSetPageAndCurrentPage = useCallback(
    _.debounce(() => {
      setPage(1);
      setCurrentPage(1);
    }, 300),
    [],
  );

  useEffect(() => {
    debouncedSetPageAndCurrentPage();
  }, [searchTerm]);

  const handleSort = async (sortBy, order) => {
    setSortOptions({ sortBy, order });
  };

  return (
    <div className="properties__screen">
      <div className="properties__screen__content">
        <div className="properties__screen__header">
          <p className="properties__screen__header-title">Properties</p>
          <Input
            className="customers__screen__header-search"
            type="text"
            onChange={setsearchTerm}
            placeholder="Search"
          />
        </div>
        <Table
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={perPage}
          hasExtraActions
          setPage={setPage}
          handleSort={handleSort}
          noDataContent="No Data"
          headerInformation={tableHeader}>
          {properties.map((property, index) => {
            return (
              <Fragment key={index}>
                <TableItem data={property.name} />
                <TableItem data={property.type} />
                <TableItem data={property.website} />
                <TableItem data={property.yearBuilt} />
                <TableItem
                  data={
                    property.isAutoSynced ? (
                      <Button
                        title={<img src={Icons.checkBoxFilled} alt="autoSync" />}
                        onClick={() => dispatch(toggleAutoSync(property.externalId))}
                        className="properties__screen__content__btn__noBackground"
                      />
                    ) : (
                      <Button
                        title={<img src={Icons.checkBoxBlank} alt="autoSync" />}
                        onClick={() => dispatch(toggleAutoSync(property.externalId))}
                        className="properties__screen__content__btn__noBackground"
                      />
                    )
                  }
                />
                <TableItem
                  data={
                    <Button
                      title={<img src={Icons.search} alt="search" />}
                      onClick={() =>
                        navigate({
                          pathname: `/property/${property.id}`,
                        })
                      }
                      className="properties__screen__content__btn"
                    />
                  }
                />
              </Fragment>
            );
          })}
        </Table>
      </div>
    </div>
  );
};

export default PropertiesScreen;
