import React from 'react';

const TableItem = ({ className, data, onClick = () => {} }) => {
  return (
    <td className={`td, ${className}`} onClick={onClick}>
      {data}
    </td>
  );
};

export default TableItem;
