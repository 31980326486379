import axiosApiClient from '..';
import { handleError } from '../../utils/errors';

const BASE_URL = '/business';

class BusinessService {
  getBusinesses = async (page, sortBy, order, searchTerm) => {
    try {
      if (!sortBy) {
        sortBy = '';
      }
      if (!order) {
        order = 'ASC';
      }
      const response = await axiosApiClient.get(
        `${BASE_URL}/admin/businesses?page=${page}&sortBy=${sortBy}&order=${order}&searchTerm=${searchTerm}`,
      );
      if (response.status !== 200) {
        throw new Error();
      }
      const { data, total, perPage } = response.data;
      return { data, total, perPage };
    } catch (err) {
      throw handleError(err, 'Error occured while fetching local business partnerships.');
    }
  };
  getBusinessById = async id => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/admin/businesses/${id}`);
      if (response.status !== 200) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      console.log(err);
      throw handleError(err, 'Error occured while fetching local business partnership.');
    }
  };
  addNewBusiness = async businessRequestFormData => {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      const response = await axiosApiClient.post(`${BASE_URL}/admin/businesses`, businessRequestFormData, headers);
      if (response.status !== 201) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      throw handleError(err, 'Error occured while adding new Local Business Partnership.');
    }
  };
  updateBusiness = async (businessRequestFormData, businessId) => {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      const response = await axiosApiClient.put(
        `${BASE_URL}/admin/businesses/${businessId}`,
        businessRequestFormData,
        headers,
      );
      if (response.status !== 200) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      throw handleError(err, 'Error occured while updating Local Business Partnership.');
    }
  };

  deleteBusinessById = async id => {
    try {
      const response = await axiosApiClient.delete(`${BASE_URL}/admin/businesses/${id}`);
      if (response.status !== 200) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      console.log(err);
      throw handleError(err, 'Error occured while deleting local business partnership.');
    }
  };
}

export default new BusinessService();
