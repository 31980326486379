import React, { useEffect, useState } from 'react';
import Pagination from '../Pagination/Pagination';

export const ORDER = {
  ASC: 'ASC',
  DESC: 'DESC',
};

const Table = ({
  currentPage,
  totalCount,
  pageSize,
  setPage,
  children,
  hasExtraActions,
  headerInformation,
  noDataContent,
  handleSort,
}) => {
  const fullWidth = hasExtraActions ? headerInformation.length + 1 : headerInformation.length;
  const [sortOptions, setSortOptions] = useState({
    sortBy: '',
    order: ORDER.ASC,
  });

  const onSort = key => {
    if (sortOptions.order === ORDER.ASC && !sortOptions.sortBy) {
      setSortOptions({ ...sortOptions, sortBy: key });
    } else if (key !== sortOptions.sortBy) {
      setSortOptions({ sortBy: key, order: ORDER.ASC });
    } else if (sortOptions.order === ORDER.ASC && sortOptions.sortBy) {
      setSortOptions({ sortBy: key, order: ORDER.DESC });
    } else {
      setSortOptions({ sortBy: '', order: ORDER.ASC });
    }
  };

  useEffect(() => {
    handleSort && handleSort(sortOptions.sortBy, sortOptions.order);
    //eslint-disable-next-line
  }, [sortOptions]);

  return (
    <>
      <div className="table__wrapper">
        <table className="table">
          <thead>
            <tr>
              {headerInformation.map((item, index) => {
                return (
                  <th
                    className={`th ${item.key ? 'sortable' : ''} ${item.key === sortOptions.sortBy ? 'sorted' : ''}`}
                    key={index}
                    onClick={() => {
                      item.key && item.sortable && onSort(item.key);
                    }}>
                    {item.label}
                    {item.key && item.sortable && (
                      <span
                        className={`chevron ${
                          sortOptions.order === ORDER.DESC && item.key === sortOptions.sortBy ? 'top' : 'bottom'
                        }`}
                      />
                    )}
                  </th>
                );
              })}
              {hasExtraActions && <th className="th"></th>}
            </tr>
          </thead>
          {children?.length ? (
            <tbody className="tbody">
              {children?.map((child, index) => {
                return (
                  <tr className="tr" key={index}>
                    {child}
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody className="tbody">
              <tr className="tr">
                <td className="noDataTd" colSpan={fullWidth}>
                  {noDataContent}
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>

      {totalCount !== 0 && (
        <div className="pagination__container">
          <Pagination
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={pageSize}
            onPageChange={page => setPage(page)}
          />
        </div>
      )}
    </>
  );
};

export default Table;
