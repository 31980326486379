import {
  GET_SOCIAL_NETWORKS_ATTEMPT,
  GET_SOCIAL_NETWORKS_SUCCESS,
  GET_SOCIAL_NETWORKS_ERROR,
  GET_SOCIAL_NETWORKS_RESET,
} from '../constants';

import commonService from '../../api/services/common';

export const getSocialNetworks = () => {
  return async (dispatch, getState) => {
    try {
      const { common } = getState();
      if (!common.getSocialNetworksStatus.attempt) {
        dispatch({ type: GET_SOCIAL_NETWORKS_ATTEMPT });

        const response = await commonService.getAllSocialNetworks();

        dispatch({
          type: GET_SOCIAL_NETWORKS_SUCCESS,
          payload: response.data.socialNetworks,
        });
      }
    } catch (err) {
      dispatch({ type: GET_SOCIAL_NETWORKS_ERROR, payload: err });
    }
  };
};

export const getSocialNetworksReset = () => ({ type: GET_SOCIAL_NETWORKS_RESET });
