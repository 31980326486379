import { combineReducers } from 'redux';

import authReducer from './auth';
import userReducer from './user';
import syncReducer from './sync';
import propertiesReducer from './properties';
import commonReducer from './common';
import propertyReducer from './property';
import marketplaceReducer from './marketplace';
import businessReducer from './business';
import clusterReducer from './cluster';
import issueReducer from './issue';
import notificationReducer from './notification';
import adminReducer from './admin';
import customersReducer from './customers';

export default combineReducers({
  auth: authReducer,
  userInfo: userReducer,
  sync: syncReducer,
  properties: propertiesReducer,
  common: commonReducer,
  propertyInfo: propertyReducer,
  marketplace: marketplaceReducer,
  business: businessReducer,
  cluster: clusterReducer,
  issue: issueReducer,
  notification: notificationReducer,
  admin: adminReducer,
  customers: customersReducer,
});
