import clusterService from '../../api/services/cluster';
import {
  GET_CLUSTERS_ATTEMPT,
  GET_CLUSTERS_SUCCESS,
  GET_CLUSTERS_ERROR,
  GET_CLUSTERS_RESET,
  ADD_CLUSTER_ATTEMPT,
  ADD_CLUSTER_SUCCESS,
  ADD_CLUSTER_ERROR,
  ADD_CLUSTER_RESET,
  DELETE_CLUSTER_BY_ID_ATTEMPT,
  DELETE_CLUSTER_BY_ID_SUCCESS,
  DELETE_CLUSTER_BY_ID_ERROR,
  DELETE_CLUSTER_BY_ID_RESET,
} from '../constants';

export const getAllClusters = () => {
  return async (dispatch, getState) => {
    try {
      const { cluster } = getState();
      if (!cluster.clusterInfo.getAllClustersStatus.attempt) {
        dispatch({ type: GET_CLUSTERS_ATTEMPT });

        const response = await clusterService.getAllClusters();

        dispatch({
          type: GET_CLUSTERS_SUCCESS,
          payload: response.data,
        });
      }
    } catch (err) {
      dispatch({ type: GET_CLUSTERS_ERROR, payload: err });
      console.log(err);
    }
  };
};

export const addNewCluster = name => {
  return async (dispatch, getState) => {
    try {
      const { cluster } = getState();
      if (!cluster.clusterInfo.addClusterStatus.attempt) {
        dispatch({ type: ADD_CLUSTER_ATTEMPT });

        const response = await clusterService.addNewCluster(name);
        dispatch({
          type: ADD_CLUSTER_SUCCESS,
          payload: response.data,
        });
      }
    } catch (err) {
      dispatch({ type: ADD_CLUSTER_ERROR, payload: err });
      console.log(err);
    }
  };
};

export const deleteCluster = id => {
  return async (dispatch, getState) => {
    try {
      const { cluster } = getState();
      if (!cluster.clusterInfo.deleteClusterStatus.attempt) {
        dispatch({ type: DELETE_CLUSTER_BY_ID_ATTEMPT });

        const response = await clusterService.deleteClusterById(id);
        dispatch({
          type: DELETE_CLUSTER_BY_ID_SUCCESS,
          payload: response.data,
        });
      }
    } catch (err) {
      dispatch({ type: DELETE_CLUSTER_BY_ID_ERROR, payload: err });
      console.log(err);
    }
  };
};

export const deleteClusterReset = () => ({ type: DELETE_CLUSTER_BY_ID_RESET });

export const addNewClusterReset = () => ({ type: ADD_CLUSTER_RESET });

export const getAllClustersReset = () => ({ type: GET_CLUSTERS_RESET });
