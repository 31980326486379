import notificationService from '../../api/services/notification';
import {
  SEND_NOTIFICATIONS_ATTEMPT,
  SEND_NOTIFICATIONS_SUCCESS,
  SEND_NOTIFICATIONS_ERROR,
  SEND_NOTIFICATIONS_RESET,
  GET_NOTIFICATION_BY_ID_ATTEMPT,
  GET_NOTIFICATION_BY_ID_SUCCESS,
  GET_NOTIFICATION_BY_ID_ERROR,
  GET_NOTIFICATION_BY_ID_RESET,
  MARK_NOTIFICATION_AS_SEEN_ATTEMPT,
  MARK_NOTIFICATION_AS_SEEN_SUCCESS,
  MARK_NOTIFICATION_AS_SEEN_ERROR,
  MARK_NOTIFICATION_AS_SEEN_RESET,
  GET_NUMBER_OF_UNREAD_NOTIFICATIONS_ATTEMPT,
  GET_NUMBER_OF_UNREAD_NOTIFICATIONS_SUCCESS,
  GET_NUMBER_OF_UNREAD_NOTIFICATIONS_RESET,
} from '../constants';

export const sendNotifications = (notificationType, title, description, ids, type, propertyIds) => {
  return async (dispatch, getState) => {
    try {
      const { notification } = getState();
      if (!notification.notificationInfo.attempt) {
        dispatch({ type: SEND_NOTIFICATIONS_ATTEMPT });
        let response;
        if (propertyIds) {
          response = await notificationService.sendNotifications(
            notificationType,
            title,
            description,
            ids,
            type,
            propertyIds,
          );
        } else {
          response = await notificationService.sendNotifications(notificationType, title, description, ids, type, null);
        }
        dispatch({
          type: SEND_NOTIFICATIONS_SUCCESS,
          payload: response,
        });
      }
    } catch (error) {
      dispatch({ type: SEND_NOTIFICATIONS_ERROR, payload: error });
      console.log(error);
    }
  };
};

export const getNotificationById = id => {
  return async (dispatch, getState) => {
    try {
      const { notification } = getState();
      if (!notification.notificationInfo.getNotificationByIdStatus.attempt) {
        dispatch({ type: GET_NOTIFICATION_BY_ID_ATTEMPT });

        const response = await notificationService.getNotificationById(id);
        dispatch({
          type: GET_NOTIFICATION_BY_ID_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({ type: GET_NOTIFICATION_BY_ID_ERROR, payload: error });
      console.log(error);
    }
  };
};

export const markNotificationAsSeen = id => {
  return async (dispatch, getState) => {
    try {
      const { notification } = getState();
      if (!notification.notificationInfo.markNotificationAsSeenStatus.attempt) {
        dispatch({ type: MARK_NOTIFICATION_AS_SEEN_ATTEMPT });

        const response = await notificationService.markNotificationAsSeen(id);

        dispatch({
          type: MARK_NOTIFICATION_AS_SEEN_SUCCESS,
          payload: response.data,
        });
      }
    } catch (err) {
      dispatch({ type: MARK_NOTIFICATION_AS_SEEN_ERROR, payload: err });
    }
  };
};

export const getNumberOfUnreadNotifications = () => {
  return async (dispatch, getState) => {
    try {
      const { notification, userInfo } = getState();
      if (!notification.notificationInfo.getNumberOfUnreadNotificationsStatus.attempt) {
        dispatch({ type: GET_NUMBER_OF_UNREAD_NOTIFICATIONS_ATTEMPT });

        const response = await notificationService.getNumberOfUnreadNotifications(userInfo.id);

        dispatch({
          type: GET_NUMBER_OF_UNREAD_NOTIFICATIONS_SUCCESS,
          payload: response.data,
        });
      }
    } catch (err) {
      dispatch({ type: MARK_NOTIFICATION_AS_SEEN_ERROR, payload: err });
    }
  };
};

export const sendNotificationsReset = () => ({ type: SEND_NOTIFICATIONS_RESET });

export const getNotificationByIdReset = () => ({ type: GET_NOTIFICATION_BY_ID_RESET });

export const markNotificationAsSeenReset = () => ({ type: MARK_NOTIFICATION_AS_SEEN_RESET });

export const getNumberOfUnreadNotificationsReset = () => ({ type: GET_NUMBER_OF_UNREAD_NOTIFICATIONS_RESET });
