import {
  SYNC_ATTEMPT,
  SYNC_ERROR,
  SYNC_RESET,
  SYNC_SUCCESS,
  PARTIAL_SYNC_ATTEMPT,
  PARTIAL_SYNC_SUCCESS,
  PARTIAL_SYNC_ERROR,
  PARTIAL_SYNC_RESET,
  TOGGLE_PARTIAL_SYNC_MODAL,
} from '../constants';

const INIT_STATE = {
  syncing: {
    attempt: false,
    success: false,
    error: null,
  },
  partialSyncPropertyExternalId: null,
  showPartialSyncModal: false,
  partialSyncStatus: {
    attempt: false,
    success: false,
    error: null,
  },
};

const syncReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SYNC_ATTEMPT:
      return {
        ...state,
        syncing: { attempt: true, success: false, error: null },
      };
    case SYNC_SUCCESS:
      return {
        ...state,
        syncing: { attempt: false, success: true, error: null },
      };
    case SYNC_ERROR:
      return {
        ...state,
        syncing: { attempt: false, success: false, error: action.payload },
      };
    case SYNC_RESET:
      return {
        ...state,
        syncing: { attempt: false, success: false, error: null },
      };
    case PARTIAL_SYNC_ATTEMPT:
      return {
        ...state,
        partialSyncStatus: { attempt: true, success: false, error: null },
      };
    case PARTIAL_SYNC_SUCCESS:
      return {
        ...state,
        partialSyncStatus: { attempt: false, success: true, error: null },
      };
    case PARTIAL_SYNC_ERROR:
      return {
        ...state,
        partialSyncStatus: { attempt: false, success: false, error: action.payload },
        partialSyncPropertyExternalId: null,
      };
    case PARTIAL_SYNC_RESET:
      return {
        ...state,
        partialSyncStatus: { attempt: false, success: false, error: null },
        partialSyncPropertyExternalId: null,
      };
    case TOGGLE_PARTIAL_SYNC_MODAL:
      return {
        ...state,
        showPartialSyncModal: !state.showPartialSyncModal,
        partialSyncPropertyExternalId: action.payload,
      };
    default:
      return state;
  }
};

export default syncReducer;
