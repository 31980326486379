import axiosApiClient from '..';
import { handleError } from '../../utils/errors';

const BASE_URL = '/properties';

class propertyService {
  getPropertyById = async id => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/${id}`);
      if (response.status !== 200) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      console.log(err);
      throw handleError(err, 'Error occured while fetching property.');
    }
  };
}
export default new propertyService();
