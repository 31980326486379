class LocalStorageService {
  getItem = (key) => {
    return localStorage.getItem(key);
  };

  setItem = (key, value) => {
    if (typeof value === "object") {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      localStorage.setItem(key, value);
    }
  };

  removeItem = (key) => {
    localStorage.removeItem(key);
  };

  removeAll = async () => {
    await localStorage.removeAll();
  };
}

export default new LocalStorageService();
