import {
  ADD_BUSINESS_ATTEMPT,
  ADD_BUSINESS_SUCCESS,
  ADD_BUSINESS_ERROR,
  ADD_BUSINESS_RESET,
  GET_BUSINESS_BY_ID_ATTEMPT,
  GET_BUSINESS_BY_ID_SUCCESS,
  GET_BUSINESS_BY_ID_ERROR,
  GET_BUSINESS_BY_ID_RESET,
  DELETE_BUSINESS_BY_ID_ATTEMPT,
  DELETE_BUSINESS_BY_ID_SUCCESS,
  DELETE_BUSINESS_BY_ID_ERROR,
  DELETE_BUSINESS_BY_ID_RESET,
  UPDATE_BUSINESS_BY_ID_ATTEMPT,
  UPDATE_BUSINESS_BY_ID_SUCCESS,
  UPDATE_BUSINESS_BY_ID_ERROR,
  UPDATE_BUSINESS_BY_ID_RESET,
} from '../constants';

const INIT_STATE = {
  businessInfo: {
    attempt: false,
    success: false,
    error: null,
    data: [],
    perPage: 0,
    total: 0,
    currentBusiness: {},
    deleteBusinessStatus: {
      attempt: false,
      success: false,
      error: null,
    },
    updateBusinessStatus: {
      attempt: false,
      success: false,
      error: null,
    },
    getBusinessByIdStatus: {
      attempt: false,
      success: false,
      error: null,
    },
  },
};

const businessReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_BUSINESS_ATTEMPT:
      return {
        ...state,
        businessInfo: { ...state.businessInfo, attempt: true, success: false, error: null },
      };
    case ADD_BUSINESS_SUCCESS:
      return {
        ...state,
        businessInfo: {
          data: [action.payload.data, ...state.businessInfo.data],
          attempt: false,
          success: true,
          error: null,
        },
      };
    case ADD_BUSINESS_ERROR:
      return {
        ...state,
        businessInfo: { ...state.businessInfo, attempt: false, success: false, error: action.payload },
      };
    case ADD_BUSINESS_RESET:
      return {
        ...state,
        businessInfo: {
          ...state.businessInfo,
          attempt: false,
          success: false,
          error: null,
        },
      };
    case DELETE_BUSINESS_BY_ID_ATTEMPT:
      return {
        ...state,
        businessInfo: {
          ...state.businessInfo,
          deleteBusinessStatus: {
            attempt: true,
            success: false,
            error: null,
          },
        },
      };
    case DELETE_BUSINESS_BY_ID_SUCCESS:
      return {
        ...state,
        businessInfo: {
          ...state.businessInfo,
          currentBusiness: {},
          deleteBusinessStatus: {
            attempt: false,
            success: true,
            error: null,
          },
        },
      };
    case DELETE_BUSINESS_BY_ID_ERROR:
      return {
        ...state,
        businessInfo: {
          ...state.businessInfo,
          deleteBusinessStatus: {
            attempt: false,
            success: false,
            error: action.payload,
          },
        },
      };
    case DELETE_BUSINESS_BY_ID_RESET:
      return {
        ...state,
        businessInfo: {
          ...state.businessInfo,
          deleteBusinessStatus: {
            attempt: false,
            success: false,
            error: null,
          },
        },
      };
    case UPDATE_BUSINESS_BY_ID_ATTEMPT:
      return {
        ...state,
        businessInfo: {
          ...state.businessInfo,
          updateBusinessStatus: {
            attempt: true,
            success: false,
            error: null,
          },
        },
      };
    case UPDATE_BUSINESS_BY_ID_SUCCESS:
      return {
        ...state,
        businessInfo: {
          ...state.businessInfo,
          currentBusiness: action.payload,
          updateBusinessStatus: {
            attempt: false,
            success: true,
            error: null,
          },
        },
      };
    case UPDATE_BUSINESS_BY_ID_ERROR:
      return {
        ...state,
        businessInfo: {
          ...state.businessInfo,
          updateBusinessStatus: {
            attempt: false,
            success: false,
            error: action.payload,
          },
        },
      };
    case UPDATE_BUSINESS_BY_ID_RESET:
      return {
        ...state,
        businessInfo: {
          ...state.businessInfo,
          updateBusinessStatus: {
            attempt: false,
            success: false,
            error: null,
          },
        },
      };
    case GET_BUSINESS_BY_ID_ATTEMPT:
      return {
        ...state,
        businessInfo: { ...state.businessInfo, getBusinessByIdStatus: { attempt: true, success: false, error: null } },
      };
    case GET_BUSINESS_BY_ID_SUCCESS:
      return {
        ...state,
        businessInfo: {
          ...state.businessInfo,
          currentBusiness: action.payload,
          getBusinessByIdStatus: { attempt: false, success: true, error: null },
        },
      };
    case GET_BUSINESS_BY_ID_ERROR:
      return {
        ...state,
        businessInfo: {
          ...state.businessInfo,
          getBusinessByIdStatus: { attempt: false, success: false, error: action.payload },
        },
      };
    case GET_BUSINESS_BY_ID_RESET:
      return {
        ...state,
        businessInfo: { ...state.businessInfo, getBusinessByIdStatus: { attempt: false, success: false, error: null } },
      };
    default:
      return state;
  }
};

export default businessReducer;
