import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { formatDate } from '../../utils/util';
import { useDispatch, useSelector } from 'react-redux';
import {
  getNotificationById,
  getNotificationByIdReset,
  markNotificationAsSeen,
  markNotificationAsSeenReset,
} from '../../store/actions/notification';
import { adminRoles } from '../../utils/constants';

const NotificationsDetailsScreen = () => {
  const { id } = useParams();
  const getSelectedNotificationStatus = useSelector(
    state => state.notification.notificationInfo.getNotificationByIdStatus,
  );
  const notification = useSelector(state => state.notification.notificationInfo.selectedNotification);
  const markNotificationAsSeenStatus = useSelector(
    state => state.notification.notificationInfo.markNotificationAsSeenStatus,
  );
  const user = useSelector(state => state.userInfo);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!notification.seen && user.role === adminRoles.PROPERTY) {
      dispatch(markNotificationAsSeen(id));
    }
  }, [dispatch, id, notification.seen, user.role]);

  useEffect(() => {
    dispatch(getNotificationById(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (getSelectedNotificationStatus.success) {
      dispatch(getNotificationByIdReset());
    }
    if (getSelectedNotificationStatus.error) {
      dispatch(getNotificationByIdReset());
    }
  }, [dispatch, getSelectedNotificationStatus.success, getSelectedNotificationStatus.error]);

  useEffect(() => {
    if (markNotificationAsSeenStatus.success) {
      dispatch(markNotificationAsSeenReset());
    }
    if (markNotificationAsSeenStatus.error) {
      dispatch(markNotificationAsSeenReset());
    }
  }, [dispatch, markNotificationAsSeenStatus.success, markNotificationAsSeenStatus.error]);

  return (
    <div className="notification__details__screen">
      {notification && (
        <div className="notification__details__screen__content">
          <div className="notification__details__screen__content__container">
            <div className="notification__details__screen__content__container__box left">
              <div className="notification__details__screen__content__container__box-description">
                <h2>
                  <b>Notification</b>
                </h2>
                <p>
                  <b>Title:</b> {notification.title}
                </p>
                <p>
                  <b>Description:</b> {notification.description}
                </p>
                <p>
                  <b>Seen:</b> {notification.seen ? 'true' : 'false'}
                </p>
                <p>
                  <b>Created: </b>
                  {notification.createdAt ? formatDate(notification.createdAt) : ''}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationsDetailsScreen;
