import axiosApiClient from '..';
import { handleError } from '../../utils/errors';

const BASE_URL = '/issues';

class IssueService {
  getIssues = async (page, sortBy, order, searchTerm) => {
    try {
      if (!sortBy) {
        sortBy = '';
      }
      if (!order) {
        order = 'ASC';
      }
      const response = await axiosApiClient.get(
        `${BASE_URL}/admin/issues?page=${page}&sortBy=${sortBy}&order=${order}&searchTerm=${searchTerm}`,
      );
      if (response.status !== 200) {
        throw new Error();
      }
      const { data, total, perPage } = response.data;
      return { data, total, perPage };
    } catch (err) {
      throw handleError(err, 'Error occured while fetching issues.');
    }
  };
  getIssueById = async id => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/admin/issues/${id}`);
      if (response.status !== 200) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      console.log(err);
      throw handleError(err, 'Error occured while fetching issue.');
    }
  };
  addNewIssue = async issueRequestFormData => {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      const response = await axiosApiClient.post(`${BASE_URL}/admin/issues`, issueRequestFormData, headers);
      if (response.status !== 201) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      throw handleError(err, 'Error occured while adding new Issue.');
    }
  };
  deleteIssue = async id => {
    try {
      const response = await axiosApiClient.delete(`${BASE_URL}/admin/issues/${id}`);
      if (response.status !== 200) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      console.log(err);
      throw handleError(err, 'Error occured while deleting issue.');
    }
  };
  updateIssue = async (issueRequestFormData, issueId) => {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      const response = await axiosApiClient.put(`${BASE_URL}/admin/issues/${issueId}`, issueRequestFormData, headers);
      if (response.status !== 200) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      throw handleError(err, 'Error occured while updating issue.');
    }
  };
}

export default new IssueService();
