import React, { useState, useRef, cloneElement } from 'react';
import { useOutsideClick } from '../../utils/util';

const DropdownMenu = ({ closeOnClick = true, children, buttonContent }) => {
  const handleCloseDropdown = () => setIsOpen(false);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();
  useOutsideClick(ref, () => {
    setIsOpen(false);
  });
  return (
    <div className="container" ref={ref}>
      <button onClick={() => setIsOpen(!isOpen)} className="button">
        {buttonContent ? buttonContent : '...'}
      </button>
      <div className={isOpen ? 'dropdownShown' : 'dropdown'}>
        <ul>
          {React.Children.map(children, child =>
            cloneElement(child, {
              onClick: () => {
                closeOnClick && handleCloseDropdown();
              },
            }),
          )}
        </ul>
      </div>
    </div>
  );
};

DropdownMenu.Item = ({ onClick, children }) => {
  return <li onClick={onClick}>{children}</li>;
};

export default DropdownMenu;
