import {
  SEND_NOTIFICATIONS_ATTEMPT,
  SEND_NOTIFICATIONS_SUCCESS,
  SEND_NOTIFICATIONS_ERROR,
  SEND_NOTIFICATIONS_RESET,
  GET_NOTIFICATION_BY_ID_ATTEMPT,
  GET_NOTIFICATION_BY_ID_SUCCESS,
  GET_NOTIFICATION_BY_ID_ERROR,
  GET_NOTIFICATION_BY_ID_RESET,
  MARK_NOTIFICATION_AS_SEEN_ATTEMPT,
  MARK_NOTIFICATION_AS_SEEN_SUCCESS,
  MARK_NOTIFICATION_AS_SEEN_ERROR,
  MARK_NOTIFICATION_AS_SEEN_RESET,
  GET_NUMBER_OF_UNREAD_NOTIFICATIONS_ATTEMPT,
  GET_NUMBER_OF_UNREAD_NOTIFICATIONS_SUCCESS,
  GET_NUMBER_OF_UNREAD_NOTIFICATIONS_RESET,
} from '../constants';

const INIT_STATE = {
  notificationInfo: {
    attempt: false,
    success: false,
    error: null,
    selectedNotification: {},
    numberOfUnreadNotifications: 0,
    getNumberOfUnreadNotificationsStatus: {
      attempt: false,
      success: false,
      error: null,
    },
    getNotificationByIdStatus: {
      attempt: false,
      success: false,
      error: null,
    },
    markNotificationAsSeenStatus: {
      attempt: false,
      success: false,
      error: null,
    },
  },
};

const notificationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SEND_NOTIFICATIONS_ATTEMPT:
      return {
        ...state,
        notificationInfo: { ...state.notificationInfo, attempt: true, success: false, error: null },
      };
    case SEND_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notificationInfo: { ...state.notificationInfo, attempt: false, success: true, error: null },
      };
    case SEND_NOTIFICATIONS_ERROR:
      return {
        ...state,
        notificationInfo: { ...state.notificationInfo, attempt: false, success: false, error: action.payload?.message },
      };
    case SEND_NOTIFICATIONS_RESET:
      return {
        ...state,
        notificationInfo: { ...state.notificationInfo, attempt: false, success: false, error: null },
      };
    case GET_NOTIFICATION_BY_ID_ATTEMPT:
      return {
        ...state,
        notificationInfo: {
          ...state.notificationInfo,
          getNotificationByIdStatus: { attempt: true, success: false, error: null },
        },
      };
    case GET_NOTIFICATION_BY_ID_SUCCESS:
      return {
        ...state,
        notificationInfo: {
          ...state.notificationInfo,
          selectedNotification: action.payload,
          getNotificationByIdStatus: { attempt: false, success: true, error: null },
        },
      };
    case GET_NOTIFICATION_BY_ID_ERROR:
      return {
        ...state,
        notificationInfo: {
          ...state.notificationInfo,
          getNotificationByIdStatus: { attempt: false, success: false, error: action.payload?.message },
        },
      };
    case GET_NOTIFICATION_BY_ID_RESET:
      return {
        ...state,
        notificationInfo: {
          ...state.notificationInfo,
          getNotificationByIdStatus: { attempt: false, success: false, error: null },
        },
      };
    case MARK_NOTIFICATION_AS_SEEN_ATTEMPT:
      return {
        ...state,
        notificationInfo: {
          ...state.notificationInfo,
          markNotificationAsSeenStatus: { attempt: true, success: false, error: null },
        },
      };
    case MARK_NOTIFICATION_AS_SEEN_SUCCESS:
      return {
        ...state,
        notificationInfo: {
          ...state.notificationInfo,
          numberOfUnreadNotifications: state.notificationInfo.numberOfUnreadNotifications - 1,
          selectedNotification: action.payload,
          markNotificationAsSeenStatus: { attempt: false, success: true, error: null },
        },
      };
    case MARK_NOTIFICATION_AS_SEEN_ERROR:
      return {
        ...state,
        notificationInfo: {
          ...state.notificationInfo,
          markNotificationAsSeenStatus: { attempt: false, success: false, error: action.payload?.message },
        },
      };
    case MARK_NOTIFICATION_AS_SEEN_RESET:
      return {
        ...state,
        notificationInfo: {
          ...state.notificationInfo,
          markNotificationAsSeenStatus: { attempt: false, success: false, error: null },
        },
      };
    case GET_NUMBER_OF_UNREAD_NOTIFICATIONS_ATTEMPT:
      return {
        ...state,
        notificationInfo: {
          ...state.notificationInfo,
          getNumberOfUnreadNotificationsStatus: {
            attempt: true,
            success: false,
            error: null,
          },
        },
      };
    case GET_NUMBER_OF_UNREAD_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notificationInfo: {
          ...state.notificationInfo,
          numberOfUnreadNotifications: +action.payload,
          getNumberOfUnreadNotificationsStatus: {
            attempt: false,
            success: true,
            error: null,
          },
        },
      };
    case GET_NUMBER_OF_UNREAD_NOTIFICATIONS_RESET:
      return {
        ...state,
        notificationInfo: {
          ...state.notificationInfo,
          getNumberOfUnreadNotificationsStatus: {
            attempt: false,
            success: false,
            error: action.payload?.message,
          },
        },
      };
    default:
      return state;
  }
};

export default notificationReducer;
