import React, { useState } from 'react';
import { Icons } from '../../themes';

const MediaSlider = ({ pictures }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const previousSlide = () => {
    setCurrentIndex(prevIndex => (prevIndex === 0 ? pictures.length - 1 : prevIndex - 1));
  };

  const nextSlide = () => {
    setCurrentIndex(prevIndex => (prevIndex === pictures.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className="media__slider__container">
      <div onClick={previousSlide}>
        <img className="media__slider__container__icon_arrow" src={Icons.leftArrow} alt="left-arrow" />
      </div>
      <img src={pictures[currentIndex].url} alt="slider" />
      <div onClick={nextSlide}>
        <img className="media__slider__container__icon_arrow" src={Icons.rightArrow} alt="right-arrow" />
      </div>
    </div>
  );
};

export default MediaSlider;
