import React from 'react';
import { usePagination, DOTS } from './usePagination';
import { Icons } from '../../themes';

const Pagination = props => {
  const { onPageChange, totalCount, siblingCount = 1, currentPage, pageSize } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  let classNameFirstPage = 'arrow';
  let classNameLastPage = 'arrow';

  if (currentPage === 1) {
    classNameFirstPage += ' disabled';
  }

  if (currentPage === lastPage) {
    classNameLastPage += ' disabled';
  }

  const getClassName = pageNumber => {
    let classNamePage = 'pagination-item';
    if (pageNumber === currentPage) {
      classNamePage += ' selected';
    }
    return classNamePage;
  };
  return (
    <ul className="pagination-container">
      <li className={classNameFirstPage} onClick={onPrevious}>
        <img className="icon" src={Icons.leftArrow} alt="left-arrow" />
      </li>
      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return (
            <li key={pageNumber + index} className="pagination-item dots">
              &#8230;
            </li>
          );
        }

        return (
          <li key={pageNumber} className={getClassName(pageNumber)} onClick={() => onPageChange(pageNumber)}>
            {pageNumber}
          </li>
        );
      })}
      <li className={classNameLastPage} onClick={onNext}>
        <img className="icon" src={Icons.rightArrow} alt="right-arrow" />
      </li>
    </ul>
  );
};

export default Pagination;
