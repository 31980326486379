import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Input, Table, TableItem } from '../../components';
import { Icons } from '../../themes';
import IssueService from '../../api/services/issue';
import PropertiesService from '../../api/services/properties';
import { ORDER } from '../../components/Table/Table';
import _ from 'lodash';
import Modal from '../../components/Modal/Modal';
import Select from 'react-select';
import { formatDate } from '../../utils/util';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { addNewIssue, addNewIssueReset } from '../../store/actions/issue';
import { ReactComponent as Loader } from '../../assets/icons/loader.svg';

const IssuesScreen = () => {
  const [searchTerm, setsearchTerm] = useState('');
  const [issues, setIssues] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [perPage, setPerPage] = useState(1);
  const [issueTitle, setIssueTitle] = useState(null);
  const [issueDescription, setIssueDescription] = useState(null);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [selectedRequestTypeOption, setSelectedRequestTypeOption] = useState('');
  const [properties, setProperties] = useState([]);
  const [video, setVideo] = useState(null);
  const [checkedAll, setCheckedAll] = useState(false);
  const issueInfo = useSelector(state => state.issue.issueInfo);
  const [previewVideo, setPreviewVideo] = useState(null);
  const [sortOptions, setSortOptions] = useState({
    sortBy: '',
    order: ORDER.ASC,
  });
  const modalRef = useRef();
  const videoInput = useRef();
  const dispatch = useDispatch();

  let page = +useLocation().search.split('=')[1];
  let navigate = useNavigate();

  const setPage = async page => {
    setCurrentPage(page);
    navigate({
      pathname: '/issues',
      search: `?page=${page}`,
    });
  };

  const tableHeader = [
    {
      key: 'title',
      label: 'Title',
      sortable: true,
    },
    {
      key: 'description',
      label: 'Description',
      sortable: true,
    },
    {
      key: 'property',
      label: 'Property',
      sortable: false,
    },
    {
      key: 'createdAt',
      label: 'Created',
      sortable: true,
    },
  ];

  const onAddVideoClick = () => {
    videoInput.current.click();
  };

  const handleVideoPreview = async e => {
    if (e.target.files.length !== 0) {
      const selectedFile = e.target.files[0];

      if (selectedFile.type.startsWith('video/')) {
        let video_as_base64 = URL.createObjectURL(selectedFile);

        setVideo(selectedFile);
        setPreviewVideo(video_as_base64);
      } else {
        toast.warning('Please select a video file.');
      }
    }
  };

  const isFormValid = () => {
    if (!issueTitle) {
      toast.warning('Please add issue title');
      return false;
    }
    if (!issueDescription) {
      toast.warning('Please add issue description');
      return false;
    }
    if (selectedProperties.length === 0) {
      toast.warning('Please select a property');
      return false;
    }
    if (!video) {
      toast.warning('Please add video');
      return false;
    }
    if (selectedRequestTypeOption === '') {
      toast.warning('Please select request handler');
      return false;
    }
    return true;
  };

  const debouncedSearch = useCallback(
    _.debounce(async (sortOptions, searchTerm, page) => {
      try {
        const response1 = await IssueService.getIssues(Number(page), sortOptions.sortBy, sortOptions.order, searchTerm);
        setPerPage(response1.perPage);
        setTotalCount(response1.total);
        setIssues(response1.data);
      } catch (error) {
        console.log(error.message);
      }
    }, 400),
    [],
  );

  useEffect(() => {
    setCurrentPage(page);
    if (page > 0) debouncedSearch(sortOptions, searchTerm, page);
  }, [page, sortOptions, searchTerm, debouncedSearch]);

  const debouncedSetPageAndCurrentPage = useCallback(
    _.debounce(() => {
      setPage(1);
      setCurrentPage(1);
    }, 300),
    [],
  );

  useEffect(() => {
    if (checkedAll) {
      setSelectedProperties(properties);
    } else {
      setSelectedProperties([]);
    }
  }, [checkedAll, properties]);

  useEffect(() => {
    if (issueInfo.success) {
      dispatch(addNewIssueReset());
      setIssueTitle(null);
      setIssueDescription(null);
      setSelectedProperties([]);
      setPreviewVideo(null);
      setVideo(null);
      setSelectedRequestTypeOption('');
      modalRef.current.hide();
      toast.success('Succesfully added new Issue');
    }
    if (issueInfo.error) {
      dispatch(addNewIssueReset());
      toast.error('Error while adding new Issue');
    }
  }, [dispatch, issueInfo.success, issueInfo.error]);

  useEffect(() => {
    debouncedSetPageAndCurrentPage();
  }, [searchTerm]);

  const handleSort = async (sortBy, order) => {
    setSortOptions({ sortBy, order });
  };

  const handlePropertiesChange = selectedOptions => {
    setSelectedProperties(selectedOptions);
    if (selectedOptions.length === 0) {
      setSelectedProperties(selectedOptions);
    }
  };

  const handleRequestTypeOptionsChange = selectedOptions => {
    setSelectedRequestTypeOption(selectedOptions);
    if (selectedOptions.length === 0) {
      setSelectedRequestTypeOption(selectedOptions);
    }
  };

  return (
    <div className="issues__screen">
      <div className="issues__screen__content">
        <div className="issues__screen__header">
          <p className="issues__screen__header-title">Issues</p>
          <Input className="issues__screen__header-search" type="text" onChange={setsearchTerm} placeholder="Search" />
          <div
            className="issues__screen__header-iconContainer"
            onClick={async () => {
              modalRef.current.show();
              const response = await PropertiesService.getPropertiesForBusiness();
              setProperties(response);
            }}>
            <img className="issues__screen__header-icon" src={Icons.addIcon} alt="add-icon" />
          </div>
        </div>
        <Table
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={perPage}
          hasExtraActions
          setPage={setPage}
          noDataContent="No Data"
          handleSort={handleSort}
          headerInformation={tableHeader}>
          {issues.map((issue, index) => {
            return (
              <Fragment key={index}>
                <TableItem data={issue.title} />
                <TableItem data={issue.description} />
                <TableItem data={issue.property.name} />
                <TableItem data={issue.createdAt ? formatDate(issue.createdAt) : ''} />
                <TableItem
                  className={'issues__screen__content__search'}
                  data={
                    <Button
                      title={<img src={Icons.search} alt="search" />}
                      onClick={() =>
                        navigate({
                          pathname: `/issue/${issue.id}`,
                        })
                      }
                      className="issues__screen__content__btn"
                    />
                  }
                />
              </Fragment>
            );
          })}
        </Table>
        <Modal
          ref={modalRef}
          onClose={() => {
            modalRef.current.hide();
            setPreviewVideo(null);
            setVideo(null);
            setSelectedProperties([]);
            setIssueTitle(null);
            setIssueDescription(null);
            setSelectedRequestTypeOption('');
          }}
          title={'Add new issue'}
          maxWidth="500px">
          {issueInfo.attempt ? (
            <div>
              <div className="modal__content__loader__container">
                <p className="modal__content__loader__container-text">Adding new issue in progress...</p>
                <Loader className="modal__content__loader__container-loader" />
              </div>
            </div>
          ) : (
            <>
              <div className="checkbox_container">
                <label>Select all:</label>
                <input className="checkbox" type="checkbox" onChange={() => setCheckedAll(prev => !prev)} />
              </div>
              <div className="select-container">
                <div
                  style={{
                    width: '100%',
                    gap: 10,
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                  }}>
                  <span htmlFor="request-handler">Request Handler:</span>
                  <div style={{ flex: 1, marginLeft: 'auto' }}>
                    <Select
                      id="request-handler"
                      name="request-handler"
                      options={[{ name: 'Support' }, { name: 'Maintenance' }]}
                      value={selectedRequestTypeOption}
                      onChange={handleRequestTypeOptionsChange}
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.name}
                    />
                  </div>
                </div>
              </div>
              <div className="select-container">
                <div
                  style={{
                    width: '100%',
                    gap: 10,
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                  }}>
                  <span htmlFor="properties">Properties:</span>
                  <div style={{ flex: 1, marginLeft: 'auto' }}>
                    <Select
                      id="properties"
                      name="properties"
                      isMulti
                      options={properties}
                      value={selectedProperties}
                      onChange={handlePropertiesChange}
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.id}
                    />
                  </div>
                </div>
              </div>
              <Input type="text" onChange={setIssueTitle} placeholder="Title" />
              <Input type="text" onChange={setIssueDescription} placeholder="Description" />
              {previewVideo ? (
                <div className="video">
                  <div
                    className="iconContainer"
                    onClick={() => {
                      setPreviewVideo(null);
                      setVideo(null);
                    }}>
                    <img className="icon" src={Icons.cancelButton} alt="cancel-icon" />
                  </div>
                  <video src={previewVideo} controls width="400" height="400" />
                </div>
              ) : (
                <>
                  <div
                    style={{
                      width: '100%',
                      marginTop: '20px',
                      marginBottom: '2rem',
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                    }}>
                    <Button title="Add Video" onClick={onAddVideoClick} />
                  </div>
                  <input
                    ref={videoInput}
                    type="file"
                    className="issues__screen__content__video__container__image__input"
                    onChange={handleVideoPreview}
                  />
                </>
              )}
              <Button
                title="Submit"
                disabled={
                  !video ||
                  !issueTitle ||
                  !issueDescription ||
                  selectedProperties.length === 0 ||
                  selectedRequestTypeOption === ''
                }
                onClick={() => {
                  if (isFormValid()) {
                    let formData = new FormData();

                    if (video !== null) {
                      formData.append('file', video);
                    }
                    if (issueTitle !== null) {
                      formData.append('issueTitle', issueTitle);
                    }
                    if (issueDescription !== null) {
                      formData.append('issueDescription', issueDescription);
                    }
                    if (selectedProperties.length !== 0) {
                      for (const property of selectedProperties) {
                        formData.append('propertyIds[]', property.id);
                      }
                    }
                    if (selectedRequestTypeOption !== '') {
                      formData.append('requestHandler', selectedRequestTypeOption.toLowerCase());
                    }

                    dispatch(addNewIssue(formData));
                  }
                }}
                style={{ marginRight: '2rem' }}
                className="submit"
              />
            </>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default IssuesScreen;
