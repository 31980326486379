import menu from '../assets/icons/menu.svg';
import close from '../assets/icons/close.svg';
import people from '../assets/icons/people.svg';
import dashboard from '../assets/icons/dashboard.svg';
import home from '../assets/icons/home.svg';
import arrow from '../assets/icons/arrow.svg';
import leftArrow from '../assets/icons/left-arrow.svg';
import rightArrow from '../assets/icons/right-arrow.svg';
import sync from '../assets/icons/sync.svg';
import search from '../assets/icons/search.svg';
import amenities from '../assets/icons/amenities.svg';
import room from '../assets/icons/room.svg';
import arrowBlue from '../assets/icons/arrow_blue.svg';
import checkBoxBlank from '../assets/icons/checkBoxBlank.svg';
import checkBoxFilled from '../assets/icons/checkBoxFilled.svg';
import phone from '../assets/icons/phone.svg';
import payment from '../assets/icons/payment.svg';
import marketplace from '../assets/icons/marketplace.svg';
import blueArrowRight from '../assets/icons/BlueArrowRight.svg';
import business from '../assets/icons/business.svg';
import addIcon from '../assets/icons/addIcon.svg';
import notifications from '../assets/icons/notifications.svg';
import cancelButton from '../assets/icons/cancelButton.svg';
import issue from '../assets/icons/issues.svg';
import group from '../assets/icons/group.svg';
import link from '../assets/icons/link.svg';
import referral from '../assets/icons/referral.svg';
import admin from '../assets/icons/admin.svg';
import deleteIcon from '../assets/icons/delete.svg';
import bell from '../assets/icons/bell.svg';

const icons = {
  menu,
  close,
  people,
  dashboard,
  home,
  arrow,
  leftArrow,
  rightArrow,
  sync,
  search,
  amenities,
  room,
  arrowBlue,
  checkBoxBlank,
  checkBoxFilled,
  phone,
  payment,
  marketplace,
  blueArrowRight,
  business,
  addIcon,
  notifications,
  cancelButton,
  issue,
  group,
  link,
  referral,
  admin,
  deleteIcon,
  bell,
};

export default icons;
