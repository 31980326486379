import {
  TOGGLE_DELETE_USER_ATTEMPT,
  TOGGLE_DELETE_USER_SUCCESS,
  TOGGLE_DELETE_USER_ERROR,
  TOGGLE_DELETE_USER_RESET,
} from '../constants';

const INIT_STATE = {
  toggledCustomerExternalId: null,
  toggleDeleteUserStatus: {
    attempt: false,
    success: false,
    error: null,
  },
};

const customersReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case TOGGLE_DELETE_USER_ATTEMPT:
      return {
        ...state,
        toggleDeleteUserStatus: { attempt: true, success: false, error: null },
      };
    case TOGGLE_DELETE_USER_SUCCESS:
      return {
        ...state,
        toggledCustomerExternalId: action.payload,
        toggleDeleteUserStatus: { attempt: false, success: true, error: null },
      };
    case TOGGLE_DELETE_USER_ERROR:
      return {
        ...state,
        toggleDeleteUserStatus: { attempt: false, success: false, error: action.payload },
      };
    case TOGGLE_DELETE_USER_RESET:
      return {
        ...state,
        toggledCustomerExternalId: null,
        toggleDeleteUserStatus: { attempt: false, success: false, error: null },
      };

    default:
      return state;
  }
};

export default customersReducer;
