import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input } from '../../components';
import { login, loginReset } from '../../store/actions/auth';
import { isValidEmail } from '../../utils/validation';
import { useNavigate } from 'react-router-dom';

const LoginScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const loginAction = useSelector(state => state.auth.login);
  const loggedIn = useSelector(state => state.auth.loggedIn);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (loggedIn) {
      navigate('/');
    }
  }, [loggedIn, navigate]);

  const btnDisabled = useMemo(() => {
    return !password.trim() || !isValidEmail(email);
  }, [email, password]);

  const onLoginClick = () => {
    dispatch(login(email, password));
    setErrorMessage('');
  };

  useEffect(() => {
    if (loginAction.success) {
      dispatch(loginReset());
    }
  }, [loginAction.success, dispatch]);

  useEffect(() => {
    if (loginAction.error) {
      setErrorMessage(loginAction.error.message);
      dispatch(loginReset());
    }
  }, [loginAction.error, dispatch]);

  return (
    <div className="login__screen">
      <div className="login__screen__form">
        <p className="login__screen__form__title">Bonaventure</p>
        <div className="login__screen__form__input__container">
          <Input type="text" onChange={setEmail} placeholder="Email" />
          <Input type="password" onChange={setPassword} placeholder="Password" />
          <p className="login__screen__form__error">{errorMessage}</p>
        </div>
        <Button disabled={btnDisabled} title="Login" onClick={onLoginClick} />
      </div>
    </div>
  );
};

export default LoginScreen;
