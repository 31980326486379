import {
  GET_SOCIAL_NETWORKS_ATTEMPT,
  GET_SOCIAL_NETWORKS_ERROR,
  GET_SOCIAL_NETWORKS_RESET,
  GET_SOCIAL_NETWORKS_SUCCESS,
} from '../constants';

const INIT_STATE = {
  getSocialNetworksStatus: {
    attempt: false,
    success: false,
    error: null,
  },
  socialNetworks: [],
};

const commonReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SOCIAL_NETWORKS_ATTEMPT:
      return {
        ...state,
        getSocialNetworksStatus: { attempt: true, success: false, error: null },
      };
    case GET_SOCIAL_NETWORKS_SUCCESS:
      return {
        ...state,
        getSocialNetworksStatus: { attempt: false, success: true, error: null },
        socialNetworks: [...action.payload],
      };
    case GET_SOCIAL_NETWORKS_ERROR:
      return {
        ...state,
        getSocialNetworksStatus: { attempt: false, success: false, error: action.payload },
      };
    case GET_SOCIAL_NETWORKS_RESET:
      return {
        ...state,
        getSocialNetworksStatus: { attempt: false, success: false, error: null },
      };
    default:
      return state;
  }
};

export default commonReducer;
