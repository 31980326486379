import propertyClusterService from '../../api/services/propertyCluster';
import {
  ADD_PROPERTY_CLUSTER_ATTEMPT,
  ADD_PROPERTY_CLUSTER_SUCCESS,
  ADD_PROPERTY_CLUSTER_ERROR,
  ADD_PROPERTY_CLUSTER_RESET,
  DELETE_PROPERTY_CLUSTER_BY_ID_ATTEMPT,
  DELETE_PROPERTY_CLUSTER_BY_ID_SUCCESS,
  DELETE_PROPERTY_CLUSTER_BY_ID_ERROR,
  DELETE_PROPERTY_CLUSTER_BY_ID_RESET,
} from '../constants';

export const addNewPropertyCluster = (propertyId, clusterId) => {
  return async (dispatch, getState) => {
    try {
      const { cluster } = getState();
      if (!cluster.propertyClusterInfo.addPropertyClusterStatus.attempt) {
        dispatch({ type: ADD_PROPERTY_CLUSTER_ATTEMPT });

        const response = await propertyClusterService.addNewPropertyCluster(propertyId, clusterId);

        dispatch({
          type: ADD_PROPERTY_CLUSTER_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({ type: ADD_PROPERTY_CLUSTER_ERROR, payload: error });
      console.log(error);
    }
  };
};

export const deletePropertyCluster = (clusterId, propertyId) => {
  return async (dispatch, getState) => {
    try {
      const { cluster } = getState();
      if (!cluster.propertyClusterInfo.deletePropertyClusterStatus.attempt) {
        dispatch({ type: DELETE_PROPERTY_CLUSTER_BY_ID_ATTEMPT });

        const response = await propertyClusterService.deletePropertyClusterById(clusterId, propertyId);
        dispatch({
          type: DELETE_PROPERTY_CLUSTER_BY_ID_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({ type: DELETE_PROPERTY_CLUSTER_BY_ID_ERROR, payload: error });
      console.log(error);
    }
  };
};

export const deletePropertyClusterReset = () => ({ type: DELETE_PROPERTY_CLUSTER_BY_ID_RESET });

export const addNewPropertyClusterReset = () => ({ type: ADD_PROPERTY_CLUSTER_RESET });
