import axiosApiClient from '..';
import { handleError } from '../../utils/errors';

const BASE_URL = '/notifications';

class NotificationService {
  sendNotifications = async (notificationType, title, description, ids, type, propertyIds) => {
    try {
      let idsObject = {};
      switch (type) {
        case 'propertyUnitIds':
          idsObject = { propertyUnitIds: ids, propertyIds: propertyIds };
          break;
        case 'floorIds':
          idsObject = { floorIds: ids, propertyIds: propertyIds };
          break;
        case 'propertyIds':
          idsObject = { propertyIds: ids };
          break;
        default:
          idsObject = {};
      }
      const response = await axiosApiClient.post(`${BASE_URL}/admin`, {
        notificationType: notificationType,
        title: title,
        description: description,
        ...idsObject,
      });
      if (response.status !== 201) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      throw handleError(err, 'Error occured while adding new Issue.');
    }
  };
  getAllNotifications = async (page, sortBy, order, searchTerm) => {
    try {
      const response = await axiosApiClient.get(
        `${BASE_URL}/admin/notifications?page=${page}&sortBy=${sortBy}&order=${order}&searchTerm=${searchTerm}`,
      );
      if (response.status !== 200) {
        throw new Error();
      }

      return response.data;
    } catch (err) {
      console.log(err);
      throw handleError(err, 'Error occured while fetching notifications.');
    }
  };

  getNotificationById = async Id => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/admin/${Id}`);
      if (response.status !== 200) {
        throw new Error();
      }

      return response.data;
    } catch (err) {
      console.log(err);
      throw handleError(err, 'Error occured while fetching notification.');
    }
  };

  getNumberOfUnreadNotifications = async adminId => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/admin/unread/${adminId}`);
      if (response.status !== 200) {
        throw new Error();
      }

      return response.data;
    } catch (err) {
      console.log(err);
      throw handleError(err, 'Error occured while fetching number of unread notifications.');
    }
  };

  markNotificationAsSeen = async notificationId => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}/admin/seen`, { notificationId: notificationId });
      if (response.status !== 200) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      console.log(err);
      throw handleError(err, 'Error occured while marking notification as seen.');
    }
  };
}

export default new NotificationService();
