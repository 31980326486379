import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import propertiesService from '../../api/services/properties';
import { Table, TableItem } from '../../components';
import { ORDER } from '../../components/Table/Table';

const PropertyUnitsScreen = () => {
  const [propertyUnits, setPropertyUnits] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [perPage, setPerPage] = useState(1);
  const [sortOptions, setSortOptions] = useState({
    sortBy: '',
    order: ORDER.ASC,
  });

  const { id } = useParams();
  let page = +useLocation().search.split('=')[1];

  let navigate = useNavigate();

  const setPage = async page => {
    setCurrentPage(page);
    navigate({
      pathname: `/properties/${id}/property-units`,
      search: `?page=${page}`,
    });
  };

  useEffect(() => {
    const fetchPropertyUnits = async () => {
      try {
        const response1 = await propertiesService.getPropertyUnits(
          id,
          Number(page),
          sortOptions.sortBy,
          sortOptions.order,
        );
        setPerPage(response1.perPage);
        setTotalCount(response1.total);
        setPropertyUnits(response1.data);
      } catch (error) {
        console.log(error.message);
      }
    };
    setCurrentPage(page);

    if (page > 0) fetchPropertyUnits();
  }, [page, id, sortOptions]);

  const handleSort = async (sortBy, order) => {
    setSortOptions({ sortBy, order });
  };

  const tableHeader = [
    {
      key: 'unitNumber',
      label: 'Unit Number',
      sortable: true,
    },
    {
      key: 'buildingName',
      label: 'Building Name',
      sortable: true,
    },
    {
      key: 'floorNumber',
      label: 'Floor Number',
      sortable: true,
    },
    {
      key: 'numOfBathrooms',
      label: 'Num Of Bathrooms',
      sortable: true,
    },
    {
      key: 'numOfBedrooms',
      label: 'Num Of Bedrooms',
      sortable: true,
    },

    {
      key: 'squareFeet',
      label: 'Square Feet',
      sortable: true,
    },
  ];

  return (
    <div className="properties__screen">
      <div className="properties__screen__content">
        <div className="properties__screen__header">
          <p className="properties__screen__header-title">Property Units</p>
        </div>
        <Table
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={perPage}
          hasExtraActions
          setPage={setPage}
          handleSort={handleSort}
          noDataContent="No Data"
          headerInformation={tableHeader}>
          {propertyUnits.map((propertyUnit, index) => {
            return (
              <Fragment key={index}>
                <TableItem data={propertyUnit.unitNumber} />
                <TableItem data={propertyUnit.buildingName} />
                <TableItem data={propertyUnit.floorNumber} />
                <TableItem data={propertyUnit.numOfBathrooms} />
                <TableItem data={propertyUnit.numOfBedrooms} />
                <TableItem data={propertyUnit.squareFeet} />
                <TableItem />
              </Fragment>
            );
          })}
        </Table>
      </div>
    </div>
  );
};

export default PropertyUnitsScreen;
