import React, { useEffect, useState } from 'react';
import { googlePlacesKey } from '../../utils/constants';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import Input from '../Input/Input';

const libraries = ['places'];

const PlacesAutocomplete = ({ setCenter, setZoom, setSelected, setAddress, setLat, setLong }) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 400,
  });

  const handleInput = val => {
    setValue(val);
  };

  const handleSelect = ({ description }) => {
    setValue(description, false);
    clearSuggestions();

    getGeocode({ address: description })
      .then(results => {
        const { lat, lng } = getLatLng(results[0]);
        setCenter({ lat: lat, lng: lng });
        setZoom(6);
        setLat(lat);
        setLong(lng);
        const addressString = results[0].formatted_address;
        setAddress(addressString);
        setSelected({ lat, lng });
      })
      .catch(error => {
        console.log(error);
      });
  };

  const renderSuggestions = () =>
    data.map(suggestion => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <div
          className="sugestion"
          key={place_id}
          onClick={() => {
            handleSelect(suggestion);
          }}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </div>
      );
    });

  return (
    <div>
      <Input disabled={!ready} value={value} type="text" onChange={handleInput} placeholder="Search places" />
      {status === 'OK' && <div className="suggestions-list">{renderSuggestions()}</div>}
    </div>
  );
};

const Map = ({ setAddress, setLat, setLong, lat, long }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googlePlacesKey,
    libraries: libraries,
  });
  const [center, setCenter] = useState({ lat: 39.8283, lng: -98.5795 });
  const [selected, setSelected] = useState(null);
  const [zoom, setZoom] = useState(4);

  useEffect(() => {
    if (lat && long) {
      setSelected({ lat: lat, lng: long });
      setCenter({ lat: lat, lng: long });
      setZoom(6);
    }
  }, []);

  if (!isLoaded) return <div>Loading...</div>;
  return (
    <>
      <div className="places-container">
        <PlacesAutocomplete
          setAddress={setAddress}
          setLat={setLat}
          setLong={setLong}
          setSelected={setSelected}
          setCenter={setCenter}
          setZoom={setZoom}
          lat={lat}
          long={long}
        />
      </div>
      <GoogleMap zoom={zoom} center={center} mapContainerClassName="google-map">
        {selected && <Marker position={selected} />}
      </GoogleMap>
    </>
  );
};

export default Map;
