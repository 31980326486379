import React from 'react';
import { useState } from 'react';
import { ReactComponent as Loader } from '../../assets/icons/loader.svg';

const ImageWrapper = ({ source, alt, onClick, style }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <div className={imageLoaded ? 'image' : 'imageLoader'} onClick={onClick}>
      <img src={source} alt={alt} loading="lazy" style={style} onLoad={() => setImageLoaded(true)} />

      {!imageLoaded && <Loader className="loader" />}
    </div>
  );
};

export default ImageWrapper;
