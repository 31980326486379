import marketplaceService from '../../api/services/marketplace';
import {
  GET_ITEMS_ATTEMPT,
  GET_ITEMS_SUCCESS,
  GET_ITEMS_ERROR,
  GET_ITEMS_RESET,
  GET_ITEM_BY_ID_ATEMPT,
  GET_ITEM_BY_ID_SUCCESS,
  GET_ITEM_BY_ID_ERROR,
  GET_ITEM_BY_ID_RESET,
  APPROVE_ITEM_BY_ID_ATTEMPT,
  APPROVE_ITEM_BY_ID_SUCCESS,
  APPROVE_ITEM_BY_ID_ERROR,
  APPROVE_ITEM_BY_ID_RESET,
  DELETE_ITEM_BY_ID_ATTEMPT,
  DELETE_ITEM_BY_ID_SUCCESS,
  DELETE_ITEM_BY_ID_ERROR,
  DELETE_ITEM_BY_ID_RESET,
} from '../constants';

export const getItemById = id => {
  return async (dispatch, getState) => {
    try {
      const { marketplace } = getState();
      if (!marketplace.getSelectedItemInfo.attempt) {
        dispatch({ type: GET_ITEM_BY_ID_ATEMPT });

        const response = await marketplaceService.getItemById(id);

        dispatch({
          type: GET_ITEM_BY_ID_SUCCESS,
          payload: response.data,
        });
      }
    } catch (err) {
      dispatch({ type: GET_ITEM_BY_ID_ERROR, payload: err });
      console.log(err);
    }
  };
};

export const getItems = (page, sortBy, order, searchTerm) => {
  return async (dispatch, getState) => {
    try {
      const { marketplace } = getState();
      if (!marketplace.getItemsInfo.attempt) {
        dispatch({ type: GET_ITEMS_ATTEMPT });

        const { data, total, perPage } = await marketplaceService.getItems(page, sortBy, order, searchTerm);

        dispatch({
          type: GET_ITEMS_SUCCESS,
          payload: { data: data, total: total, perPage: perPage },
        });
      }
    } catch (err) {
      dispatch({ type: GET_ITEMS_ERROR, payload: err });
    }
  };
};

export const approveItemById = (id, isApproved, rejectionReason) => {
  return async (dispatch, getState) => {
    try {
      const { marketplace } = getState();
      if (!marketplace.approveItemStatus.attempt) {
        dispatch({ type: APPROVE_ITEM_BY_ID_ATTEMPT });
        if (isApproved) {
          rejectionReason = '';
        }
        const response = await marketplaceService.approveItemById(id, isApproved, rejectionReason);

        dispatch({
          type: APPROVE_ITEM_BY_ID_SUCCESS,
          payload: response.data,
        });
      }
    } catch (err) {
      dispatch({ type: APPROVE_ITEM_BY_ID_ERROR, payload: err });
    }
  };
};

export const deleteItemById = (id, deleteReason) => {
  return async (dispatch, getState) => {
    try {
      const { marketplace } = getState();
      if (!marketplace.deleteItemStatus.attempt) {
        dispatch({ type: DELETE_ITEM_BY_ID_ATTEMPT });

        const response = await marketplaceService.deleteItem(id, deleteReason);
        dispatch({
          type: DELETE_ITEM_BY_ID_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({ type: DELETE_ITEM_BY_ID_ERROR, payload: error });
      console.log(error);
    }
  };
};

export const approveItemReset = () => ({ type: APPROVE_ITEM_BY_ID_RESET });

export const getItemsReset = () => ({ type: GET_ITEMS_RESET });

export const getItemByIdReset = () => ({ type: GET_ITEM_BY_ID_RESET });

export const deleteItemByIdReset = () => ({ type: DELETE_ITEM_BY_ID_RESET });
