// auth
export const SIGN_UP_ATTEMPT = 'SIGN_UP_ATTEMPT';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_ERROR = 'SIGN_UP_ERROR';
export const SIGN_UP_RESET = 'SIGN_UP_RESET';
export const LOGIN_ATTEMPT = 'LOGIN_ATTEMPT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_RESET = 'LOGIN_RESET';
export const LOGOUT = 'LOGOUT';

// user
export const FETCH_USER_INFO_SUCCESS = 'FETCH_USER_INFO_SUCCESS';
export const FETCH_USER_INFO_ERROR = 'FETCH_USER_INFO_ERROR';
export const SET_USERS_ALL_INFO = 'SET_USERS_ALL_INFO';

export const TOGGLE_DELETE_USER_ATTEMPT = 'TOGGLE_DELETE_USER_ATTEMPT';
export const TOGGLE_DELETE_USER_SUCCESS = 'TOGGLE_DELETE_USER_SUCCESS';
export const TOGGLE_DELETE_USER_ERROR = 'TOGGLE_DELETE_USER_ERROR';
export const TOGGLE_DELETE_USER_RESET = 'TOGGLE_DELETE_USER_RESET';

// sync
export const SYNC_ATTEMPT = 'SYNC_ATTEMPT';
export const SYNC_SUCCESS = 'SYNC_SUCCESS';
export const SYNC_ERROR = 'SYNC_ERROR';
export const SYNC_RESET = 'SYNC_RESET';

export const PARTIAL_SYNC_ATTEMPT = 'PARTIAL_SYNC_ATTEMPT';
export const PARTIAL_SYNC_SUCCESS = 'PARTIAL_SYNC_SUCCESS';
export const PARTIAL_SYNC_ERROR = 'PARTIAL_SYNC_ERROR';
export const PARTIAL_SYNC_RESET = 'PARTIAL_SYNC_RESET';

export const TOGGLE_PARTIAL_SYNC_MODAL = 'TOGGLE_PARTIAL_SYNC_MODAL';

// properties
export const PROPERTIES_UPLOAD_PHOTO_ATTEMPT = 'PROPERTIES_UPLOAD_PHOTO_ATTEMPT';
export const PROPERTIES_UPLOAD_PHOTO_SUCCESS = 'PROPERTIES_UPLOAD_PHOTO_SUCCESS';
export const PROPERTIES_UPLOAD_PHOTO_ERROR = 'PROPERTIES_UPLOAD_PHOTO_ERROR';
export const PROPERTIES_UPLOAD_PHOTO_RESET = 'PROPERTIES_UPLOAD_PHOTO_RESET';

export const TOGGLE_AUTO_SYNC_ATTEMPT = 'TOGGLE_AUTO_SYNC_ATTEMPT';
export const TOGGLE_AUTO_SYNC_SUCCESS = 'TOGGLE_AUTO_SYNC_SUCCESS';
export const TOGGLE_AUTO_SYNC_ERROR = 'TOGGLE_AUTO_SYNC_ERROR';
export const TOGGLE_AUTO_SYNC_RESET = 'TOGGLE_AUTO_SYNC_RESET';

export const SET_EMERGENCY_PHONE_NUMBER_ATTEMPT = 'SET_EMERGENCY_PHONE_NUMBER_ATTEMPT';
export const SET_EMERGENCY_PHONE_NUMBER_SUCCESS = 'SET_EMERGENCY_PHONE_NUMBER_SUCCESS';
export const SET_EMERGENCY_PHONE_NUMBER_ERROR = 'SET_EMERGENCY_PHONE_NUMBER_ERROR';
export const SET_EMERGENCY_PHONE_NUMBER_RESET = 'SET_EMERGENCY_PHONE_NUMBER_RESET';

export const SET_PAYMENT_URL_ATTEMPT = 'SET_PAYMENT_URL_ATTEMPT';
export const SET_PAYMENT_URL_SUCCESS = 'SET_PAYMENT_URL_SUCCESS';
export const SET_PAYMENT_URL_ERROR = 'SET_PAYMENT_URL_ERROR';
export const SET_PAYMENT_URL_RESET = 'SET_PAYMENT_URL_RESET';

export const SET_RESERVATON_LINK_ATTEMPT = 'SET_RESERVATON_LINK_ATTEMPT';
export const SET_RESERVATON_LINK_SUCCESS = 'SET_RESERVATON_LINK_SUCCESS';
export const SET_RESERVATON_LINK_ERROR = 'SET_RESERVATON_LINK_ERROR';
export const SET_RESERVATON_LINK_RESET = 'SET_RESERVATON_LINK_RESET';

export const PROPERTIES_UPLOAD_REFERRAL_PHOTO_ATTEMPT = 'PROPERTIES_UPLOAD_REFERRAL_PHOTO_ATTEMPT';
export const PROPERTIES_UPLOAD_REFERRAL_PHOTO_ERROR = 'PROPERTIES_UPLOAD_REFERRAL_PHOTO_ERROR';
export const PROPERTIES_UPLOAD_REFERRAL_PHOTO_SUCCESS = 'PROPERTIES_UPLOAD_REFERRAL_PHOTO_SUCCESS';
export const PROPERTIES_UPLOAD_REFERRAL_PHOTO_RESET = 'PROPERTIES_UPLOAD_REFERRAL_PHOTO_RESET';

// social networks

export const GET_SOCIAL_NETWORKS_ATTEMPT = 'GET_SOCIAL_NETWORKS_ATTEMPT';
export const GET_SOCIAL_NETWORKS_ERROR = 'GET_SOCIAL_NETWORKS_ERROR';
export const GET_SOCIAL_NETWORKS_RESET = 'GET_SOCIAL_NETWORKS_RESET';
export const GET_SOCIAL_NETWORKS_SUCCESS = 'GET_SOCIAL_NETWORKS_SUCCESS';

export const UPDATE_OR_CREATE_SOCIAL_ACCOUNTS_RESET = 'UPDATE_OR_CREATE_SOCIAL_ACCOUNTS_RESET';
export const UPDATE_OR_CREATE_SOCIAL_ACCOUNTS_ERROR = 'UPDATE_OR_CREATE_SOCIAL_ACCOUNTS_ERROR';
export const UPDATE_OR_CREATE_SOCIAL_ACCOUNTS_SUCCESS = 'UPDATE_OR_CREATE_SOCIAL_ACCOUNTS_SUCCESS';
export const UPDATE_OR_CREATE_SOCIAL_ACCOUNTS_ATTEMPT = 'UPDATE_OR_CREATE_SOCIAL_ACCOUNTS_ATTEMPT';

// property

export const GET_PROPERTY_BY_ID_ERROR = 'GET_PROPERTY_BY_ID_ERROR';
export const GET_PROPERTY_BY_ID_SUCCESS = 'GET_PROPERTY_BY_ID_SUCCESS';
export const GET_PROPERTY_BY_ID_ATEMPT = 'GET_PROPERTY_BY_ID_ATEMPT';
export const GET_PROPERTY_BY_ID_RESET = 'GET_PROPERTY_BY_ID_RESET';

//  marketplace
export const GET_ITEMS_ATTEMPT = 'GET_ITEMS_ATTEMPT';
export const GET_ITEMS_SUCCESS = 'GET_ITEMS_SUCCESS';
export const GET_ITEMS_ERROR = 'GET_ITEMS_ERROR';
export const GET_ITEMS_RESET = 'GET_ITEMS_RESET';
export const GET_ITEM_BY_ID_ATEMPT = 'GET_ITEM_BY_ID_ATEMPT';
export const GET_ITEM_BY_ID_SUCCESS = 'GET_ITEM_BY_ID_SUCCESS';
export const GET_ITEM_BY_ID_ERROR = 'GET_ITEM_BY_ID_ERROR';
export const GET_ITEM_BY_ID_RESET = 'GET_ITEM_BY_ID_RESET';
export const APPROVE_ITEM_BY_ID_ATTEMPT = 'APPROVE_ITEM_BY_ID_ATTEMPT';
export const APPROVE_ITEM_BY_ID_SUCCESS = 'APPROVE_ITEM_BY_ID_SUCCESS';
export const APPROVE_ITEM_BY_ID_ERROR = 'APPROVE_ITEM_BY_ID_ERROR';
export const APPROVE_ITEM_BY_ID_RESET = 'APPROVE_ITEM_BY_ID_RESET';
export const DELETE_ITEM_BY_ID_ATTEMPT = 'DELETE_ITEM_BY_ID_ATTEMPT';
export const DELETE_ITEM_BY_ID_SUCCESS = 'DELETE_ITEM_BY_ID_SUCCESS';
export const DELETE_ITEM_BY_ID_ERROR = 'DELETE_ITEM_BY_ID_ERROR';
export const DELETE_ITEM_BY_ID_RESET = 'DELETE_ITEM_BY_ID_RESET';

//  business
export const ADD_BUSINESS_ATTEMPT = 'ADD_BUSINESS_ATTEMPT';
export const ADD_BUSINESS_SUCCESS = 'ADD_BUSINESS_SUCCESS';
export const ADD_BUSINESS_ERROR = 'ADD_BUSINESS_ERROR';
export const ADD_BUSINESS_RESET = 'ADD_BUSINESS_RESET';
export const GET_BUSINESS_BY_ID_ATTEMPT = 'GET_BUSINESS_BY_ID_ATTEMPT';
export const GET_BUSINESS_BY_ID_SUCCESS = 'GET_BUSINESS_BY_ID_SUCCESS';
export const GET_BUSINESS_BY_ID_ERROR = 'GET_BUSINESS_BY_ID_ERROR';
export const GET_BUSINESS_BY_ID_RESET = 'GET_BUSINESS_BY_ID_RESET';
export const DELETE_BUSINESS_BY_ID_ATTEMPT = 'DELETE_BUSINESS_BY_ID_ATTEMPT';
export const DELETE_BUSINESS_BY_ID_SUCCESS = 'DELETE_BUSINESS_BY_ID_SUCCESS';
export const DELETE_BUSINESS_BY_ID_ERROR = 'DELETE_BUSINESS_BY_ID_ERROR';
export const DELETE_BUSINESS_BY_ID_RESET = 'DELETE_BUSINESS_BY_ID_RESET';
export const UPDATE_BUSINESS_BY_ID_ATTEMPT = 'UPDATE_BUSINESS_BY_ID_ATTEMPT';
export const UPDATE_BUSINESS_BY_ID_SUCCESS = 'UPDATE_BUSINESS_BY_ID_SUCCESS';
export const UPDATE_BUSINESS_BY_ID_ERROR = 'UPDATE_BUSINESS_BY_ID_ERROR';
export const UPDATE_BUSINESS_BY_ID_RESET = 'UPDATE_BUSINESS_BY_ID_RESET';

// cluster
export const ADD_CLUSTER_ATTEMPT = 'ADD_CLUSTER_ATTEMPT';
export const ADD_CLUSTER_SUCCESS = 'ADD_CLUSTER_SUCCESS';
export const ADD_CLUSTER_ERROR = 'ADD_CLUSTER_ERROR';
export const ADD_CLUSTER_RESET = 'ADD_CLUSTER_RESET';
export const DELETE_CLUSTER_BY_ID_ATTEMPT = 'DELETE_CLUSTER_BY_ID_ATTEMPT';
export const DELETE_CLUSTER_BY_ID_SUCCESS = 'DELETE_CLUSTER_BY_ID_SUCCESS';
export const DELETE_CLUSTER_BY_ID_ERROR = 'DELETE_CLUSTER_BY_ID_ERROR';
export const DELETE_CLUSTER_BY_ID_RESET = 'DELETE_CLUSTER_BY_ID_RESET';
export const GET_CLUSTERS_ATTEMPT = 'GET_CLUSTERS_ATTEMPT';
export const GET_CLUSTERS_SUCCESS = 'GET_CLUSTERS_SUCCESS';
export const GET_CLUSTERS_ERROR = 'GET_CLUSTERS_ERROR';
export const GET_CLUSTERS_RESET = 'GET_CLUSTERS_RESET';

// property cluster
export const ADD_PROPERTY_CLUSTER_ATTEMPT = 'ADD_PROPERTY_CLUSTER_ATTEMPT';
export const ADD_PROPERTY_CLUSTER_SUCCESS = 'ADD_PROPERTY_CLUSTER_SUCCESS';
export const ADD_PROPERTY_CLUSTER_ERROR = 'ADD_PROPERTY_CLUSTER_ERROR';
export const ADD_PROPERTY_CLUSTER_RESET = 'ADD_PROPERTY_CLUSTER_RESET';
export const DELETE_PROPERTY_CLUSTER_BY_ID_ATTEMPT = 'DELETE_PROPERTY_CLUSTER_BY_ID_ATTEMPT';
export const DELETE_PROPERTY_CLUSTER_BY_ID_SUCCESS = 'DELETE_PROPERTY_CLUSTER_BY_ID_SUCCESS';
export const DELETE_PROPERTY_CLUSTER_BY_ID_ERROR = 'DELETE_PROPERTY_CLUSTER_BY_ID_ERROR';
export const DELETE_PROPERTY_CLUSTER_BY_ID_RESET = 'DELETE_PROPERTY_CLUSTER_BY_ID_RESET';

// issue
export const ADD_ISSUE_ATTEMPT = 'ADD_ISSUE_ATTEMPT';
export const ADD_ISSUE_SUCCESS = 'ADD_ISSUE_SUCCESS';
export const ADD_ISSUE_ERROR = 'ADD_ISSUE_ERROR';
export const ADD_ISSUE_RESET = 'ADD_ISSUE_RESET';
export const GET_ISSUE_BY_ID_ATTEMPT = 'GET_ISSUE_BY_ID_ATTEMPT';
export const GET_ISSUE_BY_ID_SUCCESS = 'GET_ISSUE_BY_ID_SUCCESS';
export const GET_ISSUE_BY_ID_ERROR = 'GET_ISSUE_BY_ID_ERROR';
export const GET_ISSUE_BY_ID_RESET = 'GET_ISSUE_BY_ID_RESET';
export const DELETE_ISSUE_ATTEMPT = 'DELETE_ISSUE_ATTEMPT';
export const DELETE_ISSUE_SUCCESS = 'DELETE_ISSUE_SUCCESS';
export const DELETE_ISSUE_ERROR = 'DELETE_ISSUE_ERROR';
export const DELETE_ISSUE_RESET = 'DELETE_ISSUE_RESET';
export const UUPDATE_ISSUE_ATTEMPT = 'UUPDATE_ISSUE_ATTEMPT';
export const UPDATE_ISSUE_SUCCESS = 'UPDATE_ISSUE_SUCCESS';
export const UPDATE_ISSUE_ERROR = 'UPDATE_ISSUE_ERROR';
export const UPDATE_ISSUE_RESET = 'UPDATE_ISSUE_RESET';

// notification
export const SEND_NOTIFICATIONS_ATTEMPT = 'SEND_NOTIFICATIONS_ATTEMPT';
export const SEND_NOTIFICATIONS_SUCCESS = 'SEND_NOTIFICATIONS_SUCCESS';
export const SEND_NOTIFICATIONS_ERROR = 'SEND_NOTIFICATIONS_ERROR';
export const SEND_NOTIFICATIONS_RESET = 'SEND_NOTIFICATIONS_RESET';
export const GET_NOTIFICATION_BY_ID_ATTEMPT = 'GET_NOTIFICATION_BY_ID_ATTEMPT';
export const GET_NOTIFICATION_BY_ID_SUCCESS = 'GET_NOTIFICATION_BY_ID_SUCCESS';
export const GET_NOTIFICATION_BY_ID_ERROR = 'GET_NOTIFICATION_BY_ID_ERROR';
export const GET_NOTIFICATION_BY_ID_RESET = 'GET_NOTIFICATION_BY_ID_RESET';
export const MARK_NOTIFICATION_AS_SEEN_ATTEMPT = 'MARK_NOTIFICATION_AS_SEEN_ATTEMPT';
export const MARK_NOTIFICATION_AS_SEEN_SUCCESS = 'MARK_NOTIFICATION_AS_SEEN_SUCCESS';
export const MARK_NOTIFICATION_AS_SEEN_ERROR = 'MARK_NOTIFICATION_AS_SEEN_ERROR';
export const MARK_NOTIFICATION_AS_SEEN_RESET = 'MARK_NOTIFICATION_AS_SEEN_RESET';
export const GET_NUMBER_OF_UNREAD_NOTIFICATIONS_ATTEMPT = 'GET_NUMBER_OF_UNREAD_NOTIFICATIONS_ATTEMPT';
export const GET_NUMBER_OF_UNREAD_NOTIFICATIONS_SUCCESS = 'GET_NUMBER_OF_UNREAD_NOTIFICATIONS_SUCCESS';
export const GET_NUMBER_OF_UNREAD_NOTIFICATIONS_RESET = 'GET_NUMBER_OF_UNREAD_NOTIFICATIONS_RESET';

// admin
export const ADD_ADMIN_ATTEMPT = 'ADD_ADMIN_ATTEMPT';
export const ADD_ADMIN_SUCCESS = 'ADD_ADMIN_SUCCESS';
export const ADD_ADMIN_ERROR = 'ADD_ADMIN_ERROR';
export const ADD_ADMIN_RESET = 'ADD_ADMIN_RESET';
export const GET_ADMIN_BY_ID_ATTEMPT = 'GET_ADMIN_BY_ID_ATTEMPT';
export const GET_ADMIN_BY_ID_SUCCESS = 'GET_ADMIN_BY_ID_SUCCESS';
export const GET_ADMIN_BY_ID_ERROR = 'GET_ADMIN_BY_ID_ERROR';
export const GET_ADMIN_BY_ID_RESET = 'GET_ADMIN_BY_ID_RESET';
export const DELETE_ADMIN_ATTEMPT = 'DELETE_ADMIN_ATTEMPT';
export const DELETE_ADMIN_SUCCESS = 'DELETE_ADMIN_SUCCESS';
export const DELETE_ADMIN_ERROR = 'DELETE_ADMIN_ERROR';
export const DELETE_ADMIN_RESET = 'DELETE_ADMIN_RESET';
export const UUPDATE_ADMIN_ATTEMPT = 'UUPDATE_ADMIN_ATTEMPT';
export const UPDATE_ADMIN_SUCCESS = 'UPDATE_ADMIN_SUCCESS';
export const UPDATE_ADMIN_ERROR = 'UPDATE_ADMIN_ERROR';
export const UPDATE_ADMIN_RESET = 'UPDATE_ADMIN_RESET';
