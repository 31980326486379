import {
  PROPERTIES_UPLOAD_PHOTO_ATTEMPT,
  PROPERTIES_UPLOAD_PHOTO_ERROR,
  PROPERTIES_UPLOAD_PHOTO_RESET,
  PROPERTIES_UPLOAD_PHOTO_SUCCESS,
  TOGGLE_AUTO_SYNC_ATTEMPT,
  TOGGLE_AUTO_SYNC_SUCCESS,
  TOGGLE_AUTO_SYNC_ERROR,
  TOGGLE_AUTO_SYNC_RESET,
  SET_EMERGENCY_PHONE_NUMBER_ATTEMPT,
  SET_EMERGENCY_PHONE_NUMBER_SUCCESS,
  SET_EMERGENCY_PHONE_NUMBER_ERROR,
  SET_EMERGENCY_PHONE_NUMBER_RESET,
  SET_PAYMENT_URL_ATTEMPT,
  SET_PAYMENT_URL_SUCCESS,
  SET_PAYMENT_URL_ERROR,
  SET_PAYMENT_URL_RESET,
  SET_RESERVATON_LINK_ATTEMPT,
  SET_RESERVATON_LINK_SUCCESS,
  SET_RESERVATON_LINK_ERROR,
  SET_RESERVATON_LINK_RESET,
  PROPERTIES_UPLOAD_REFERRAL_PHOTO_ATTEMPT,
  PROPERTIES_UPLOAD_REFERRAL_PHOTO_ERROR,
  PROPERTIES_UPLOAD_REFERRAL_PHOTO_SUCCESS,
  PROPERTIES_UPLOAD_REFERRAL_PHOTO_RESET,
} from '../constants';

import propertiesService from '../../api/services/properties';

export const uploadPropertyPhoto = data => {
  return async dispatch => {
    try {
      dispatch({ type: PROPERTIES_UPLOAD_PHOTO_ATTEMPT });
      await propertiesService.uploadPropertyImage(data);
      dispatch({ type: PROPERTIES_UPLOAD_PHOTO_SUCCESS });
    } catch (err) {
      dispatch({ type: PROPERTIES_UPLOAD_PHOTO_ERROR, payload: err });
    }
  };
};

export const uploadAmenityPhoto = data => {
  return async dispatch => {
    try {
      dispatch({ type: PROPERTIES_UPLOAD_PHOTO_ATTEMPT });
      await propertiesService.uploadAmenityImage(data);
      dispatch({ type: PROPERTIES_UPLOAD_PHOTO_SUCCESS });
    } catch (err) {
      dispatch({ type: PROPERTIES_UPLOAD_PHOTO_ERROR, payload: err });
    }
  };
};

export const toggleAutoSync = propertyExternalId => {
  return async dispatch => {
    try {
      dispatch({ type: TOGGLE_AUTO_SYNC_ATTEMPT });
      await propertiesService.toggleAutoSync(propertyExternalId);
      dispatch({ type: TOGGLE_AUTO_SYNC_SUCCESS, payload: propertyExternalId });
    } catch (err) {
      dispatch({ type: TOGGLE_AUTO_SYNC_ERROR, payload: err });
    }
  };
};

export const toggleAutoSyncReset = () => ({ type: TOGGLE_AUTO_SYNC_RESET });

export const uploadPropertyPhotoReset = () => ({ type: PROPERTIES_UPLOAD_PHOTO_RESET });

export const setEmergencyPhoneNumber = (emergencyPhoneNumber, propertyExternalId) => {
  return async dispatch => {
    try {
      dispatch({ type: SET_EMERGENCY_PHONE_NUMBER_ATTEMPT });
      await propertiesService.setEmergencyPhoneNumber(emergencyPhoneNumber, propertyExternalId);
      dispatch({ type: SET_EMERGENCY_PHONE_NUMBER_SUCCESS, payload: emergencyPhoneNumber });
    } catch (err) {
      dispatch({ type: SET_EMERGENCY_PHONE_NUMBER_ERROR, payload: err });
    }
  };
};

export const setEmergencyPhoneNumberReset = () => ({ type: SET_EMERGENCY_PHONE_NUMBER_RESET });

export const setPaymentUrl = (paymentUrl, propertyExternalId) => {
  return async dispatch => {
    try {
      dispatch({ type: SET_PAYMENT_URL_ATTEMPT });
      await propertiesService.setPaymentUrl(paymentUrl, propertyExternalId);
      dispatch({ type: SET_PAYMENT_URL_SUCCESS, payload: paymentUrl });
    } catch (err) {
      dispatch({ type: SET_PAYMENT_URL_ERROR, payload: err });
    }
  };
};

export const setReservationLink = (reservationLink, propertyExternalId) => {
  return async dispatch => {
    try {
      dispatch({ type: SET_RESERVATON_LINK_ATTEMPT });
      await propertiesService.setReservationLink(reservationLink, propertyExternalId);
      dispatch({ type: SET_RESERVATON_LINK_SUCCESS, payload: reservationLink });
    } catch (err) {
      dispatch({ type: SET_RESERVATON_LINK_ERROR, payload: err });
    }
  };
};

export const uploadReferralPhoto = data => {
  return async dispatch => {
    try {
      dispatch({ type: PROPERTIES_UPLOAD_REFERRAL_PHOTO_ATTEMPT });
      await propertiesService.uploadReferralPhoto(data);
      dispatch({ type: PROPERTIES_UPLOAD_REFERRAL_PHOTO_SUCCESS });
    } catch (err) {
      dispatch({ type: PROPERTIES_UPLOAD_REFERRAL_PHOTO_ERROR, payload: err });
    }
  };
};

export const uploadReferralPhotoReset = () => ({ type: PROPERTIES_UPLOAD_REFERRAL_PHOTO_RESET });

export const setPaymentUrlReset = () => ({ type: SET_PAYMENT_URL_RESET });

export const setReservationLinkReset = () => ({ type: SET_RESERVATON_LINK_RESET });
