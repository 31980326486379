import axiosApiClient from '..';
import { handleError } from '../../utils/errors';

const BASE_URL = '/admins';

class AdminService {
  getAdmins = async (page, sortBy, order, searchTerm) => {
    try {
      if (!sortBy) {
        sortBy = '';
      }
      if (!order) {
        order = 'ASC';
      }
      const response = await axiosApiClient.get(
        `${BASE_URL}/?page=${page}&sortBy=${sortBy}&order=${order}&searchTerm=${searchTerm}`,
      );
      if (response.status !== 200) {
        throw new Error();
      }
      const { data, total, perPage } = response.data;
      return { data, total, perPage };
    } catch (err) {
      throw handleError(err, 'Error occured while fetching admins.');
    }
  };
  getAdminById = async id => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/${id}`);
      if (response.status !== 200) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      console.log(err);
      throw handleError(err, 'Error occured while fetching admin.');
    }
  };
  addNewAdmin = async adminData => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}/`, adminData);
      if (response.status !== 201) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      throw handleError(err, 'Error occured while adding new Admin.');
    }
  };
  deleteAdmin = async id => {
    try {
      const response = await axiosApiClient.delete(`${BASE_URL}/${id}`);
      if (response.status !== 200) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      console.log(err);
      throw handleError(err, 'Error occured while deleting admin.');
    }
  };
  updateAdmin = async (adminData, adminId) => {
    try {
      const response = await axiosApiClient.put(`${BASE_URL}/${adminId}`, adminData);
      if (response.status !== 200) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      throw handleError(err, 'Error occured while updating admin.');
    }
  };
}

export default new AdminService();
