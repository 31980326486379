import {
  FETCH_USER_INFO_ERROR,
  FETCH_USER_INFO_SUCCESS,
  LOGIN_ATTEMPT,
  LOGIN_ERROR,
  LOGIN_RESET,
  LOGIN_SUCCESS,
  LOGOUT,
} from '../constants';

const INIT_STATE = {
  loggedIn: null,
  login: {
    attempt: false,
    success: false,
    error: null,
  },
};

const authReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGOUT:
      return {
        ...INIT_STATE,
        loggedIn: false,
      };

    case FETCH_USER_INFO_SUCCESS:
      return {
        ...state,
        loggedIn: true,
      };
    case FETCH_USER_INFO_ERROR:
      return {
        ...state,
        loggedIn: false,
      };

    case LOGIN_ATTEMPT:
      return {
        ...state,
        login: { attempt: true, success: false, error: null },
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        login: { attempt: false, success: true, error: null },
      };
    case LOGIN_ERROR:
      return {
        ...state,
        login: { attempt: false, success: false, error: action.payload },
      };
    case LOGIN_RESET:
      return {
        ...state,
        login: { attempt: false, success: false, error: null },
      };
    default:
      return state;
  }
};

export default authReducer;
