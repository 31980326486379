import { SET_USERS_ALL_INFO, LOGOUT } from '../constants';

const INIT_STATE = {
  id: null,
  email: null,
  role: null,
};

const userReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_USERS_ALL_INFO:
      return {
        ...state,
        id: action.payload.id,
        email: action.payload.email,
        role: action.payload.role,
      };
    case LOGOUT:
      return {
        ...INIT_STATE,
      };

    default:
      return state;
  }
};

export default userReducer;
