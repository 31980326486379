import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  SplashScreen,
  ErrorScreen,
  LoginScreen,
  CustomersScreen,
  PropertiesScreen,
  PropertyScreen,
  CustomerScreen,
  PropertyUnitsScreen,
  AmenitiesScreen,
  MarketplaceScreen,
  MarketplaceItemScreen,
  LocalBusinessScreen,
  LocalBusinessDetailsScreen,
  NotificationScreen,
  ClusterScreen,
  IssuesScreen,
  IssuesDetailsScreen,
  ClustersScreen,
  AdminsScreen,
  AdminDetailsScreen,
  NotificationsScreen,
  NotificationsDetailsScreen,
} from '../../screens';
import { fetchUserInfo } from '../../store/actions/user';
import { toast, ToastContainer } from 'react-toastify';
import PrivateRoute from '../PrivateRoute/PrivateRoute';
import 'react-toastify/dist/ReactToastify.css';
import { Navbar } from '..';
import { adminRoles } from '../../utils/constants';
import { getNumberOfUnreadNotifications, getNumberOfUnreadNotificationsReset } from '../../store/actions/notification';

const App = () => {
  const [minimumDurationPassed, setMinimumDurationPassed] = useState(false);
  const loggedIn = useSelector(state => state.auth.loggedIn);
  const numberOfUnreadNotificationsStatus = useSelector(
    state => state.notification.notificationInfo.getNumberOfUnreadNotificationsStatus,
  );
  const user = useSelector(state => state.userInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserInfo());
  }, [dispatch]);

  useEffect(() => {
    if (user?.role === adminRoles.PROPERTY) {
      setInterval(() => {
        dispatch(getNumberOfUnreadNotifications());
      }, 30000);
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (numberOfUnreadNotificationsStatus.success) {
      dispatch(getNumberOfUnreadNotificationsReset());
    }
  }, [numberOfUnreadNotificationsStatus.success, dispatch]);

  useEffect(() => {
    if (numberOfUnreadNotificationsStatus.error) {
      dispatch(getNumberOfUnreadNotificationsReset());
    }
  }, [numberOfUnreadNotificationsStatus.error, dispatch]);

  useEffect(() => {
    const timer = setTimeout(() => setMinimumDurationPassed(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const canAccessRoute = (allowedRoles, userRole) => {
    if (userRole) {
      return allowedRoles.includes(userRole);
    }
  };

  return (
    <Fragment>
      {loggedIn !== null && minimumDurationPassed ? (
        <div className="app" style={{ height: '100%' }}>
          <ToastContainer newestOnTop={true} autoClose={3500} position={toast.POSITION.TOP_RIGHT} />
          {loggedIn && <Navbar />}
          <Routes>
            <Route exact path="/login" element={<LoginScreen />} />
            <Route element={<PrivateRoute />}>
              {/* <Route path="/" element={<Navigate replace to="/properties?page=1" />} /> */}
              {canAccessRoute([adminRoles.SUPER, adminRoles.PROPERTY, adminRoles.MARKETING], user.role) && loggedIn && (
                <Route exact path="/properties" element={<PropertiesScreen />} />
              )}
              {canAccessRoute([adminRoles.SUPER, adminRoles.PROPERTY, adminRoles.MARKETING], user.role) && loggedIn && (
                <Route exact path="/property/:id" element={<PropertyScreen />} />
              )}
              {canAccessRoute([adminRoles.SUPER, adminRoles.PROPERTY], user.role) && loggedIn && (
                <Route exact path="/customers" element={<CustomersScreen />} />
              )}
              {canAccessRoute([adminRoles.SUPER, adminRoles.PROPERTY], user.role) && loggedIn && (
                <Route exact path="/businesses" element={<LocalBusinessScreen />} />
              )}
              {canAccessRoute([adminRoles.SUPER, adminRoles.PROPERTY], user.role) && loggedIn && (
                <Route exact path="/business/:id" element={<LocalBusinessDetailsScreen />} />
              )}
              {canAccessRoute([adminRoles.SUPER, adminRoles.PROPERTY], user.role) && loggedIn && (
                <Route exact path="/issues" element={<IssuesScreen />} />
              )}
              {canAccessRoute([adminRoles.SUPER, adminRoles.PROPERTY], user.role) && loggedIn && (
                <Route exact path="/issue/:id" element={<IssuesDetailsScreen />} />
              )}
              {canAccessRoute([adminRoles.SUPER], user.role) && loggedIn && (
                <Route exact path="/admins" element={<AdminsScreen />} />
              )}
              {canAccessRoute([adminRoles.SUPER], user.role) && loggedIn && (
                <Route exact path="/admin/:id" element={<AdminDetailsScreen />} />
              )}
              {canAccessRoute([adminRoles.SUPER, adminRoles.PROPERTY], user.role) && loggedIn && (
                <Route exact path="/send-notifications" element={<NotificationScreen />} />
              )}
              {canAccessRoute([adminRoles.SUPER, adminRoles.PROPERTY], user.role) && loggedIn && (
                <Route exact path="/notifications" element={<NotificationsScreen />} />
              )}
              {canAccessRoute([adminRoles.SUPER, adminRoles.PROPERTY], user.role) && loggedIn && (
                <Route exact path="/notification/:id" element={<NotificationsDetailsScreen />} />
              )}
              {canAccessRoute([adminRoles.SUPER, adminRoles.PROPERTY], user.role) && loggedIn && (
                <Route exact path="/marketplace" element={<MarketplaceScreen />} />
              )}
              {canAccessRoute([adminRoles.SUPER, adminRoles.PROPERTY], user.role) && loggedIn && (
                <Route exact path="/item/:id" element={<MarketplaceItemScreen />} />
              )}
              {canAccessRoute([adminRoles.SUPER], user.role) && loggedIn && (
                <Route exact path="/clusters" element={<ClustersScreen />} />
              )}
              {canAccessRoute([adminRoles.SUPER], user.role) && loggedIn && (
                <Route exact path="/clusters/addNew" element={<ClusterScreen />} />
              )}
              {canAccessRoute([adminRoles.SUPER, adminRoles.PROPERTY], user.role) && loggedIn && (
                <Route exact path="/user/:id" element={<CustomerScreen />} />
              )}
              {canAccessRoute([adminRoles.SUPER, adminRoles.PROPERTY], user.role) && loggedIn && (
                <Route exact path="/properties/:id/property-units" element={<PropertyUnitsScreen />} />
              )}
              {canAccessRoute([adminRoles.SUPER, adminRoles.PROPERTY], user.role) && loggedIn && (
                <Route exact path="/properties/:id/amenities" element={<AmenitiesScreen />} />
              )}
              <Route path="/*" element={<Navigate replace to="/properties?page=1" />} />
            </Route>
            <Route path="*" element={<ErrorScreen />} />
          </Routes>
        </div>
      ) : (
        <SplashScreen />
      )}
    </Fragment>
  );
};

export default App;
