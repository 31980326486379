import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { formatDate } from '../../utils/util';
import { Modal, Button, Input } from '../../components';
import { ReactComponent as Loader } from '../../assets/icons/loader.svg';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Icons } from '../../themes';
import {
  deleteIssue,
  deleteIssueReset,
  getIssueById,
  getIssueByIdReset,
  updateIssue,
  updateIssueReset,
} from '../../store/actions/issue';

const IssuesDetailsScreen = () => {
  const { id } = useParams();
  const getSelectedIssueStatus = useSelector(state => state.issue.issueInfo.getIssueByIdStatus);
  const updateIssueStatus = useSelector(state => state.issue.issueInfo.updateIssueStatus);
  const deleteIssueStatus = useSelector(state => state.issue.issueInfo.deleteIssueStatus);
  const issue = useSelector(state => state.issue.issueInfo.selectedIssue);
  const [issueTitle, setIssueTitle] = useState(null);
  const [issueDescription, setIssueDescription] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [video, setVideo] = useState(null);
  const dispatch = useDispatch();
  const deleteModalRef = useRef();
  const updateModalRef = useRef();
  const videoInput = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getIssueById(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (getSelectedIssueStatus.success) {
      dispatch(getIssueByIdReset());
      updateModalRef.current.hide();
      setIssueTitle(issue?.title);
      setIssueDescription(issue?.description);
      setVideoUrl(issue?.medium.url);
    }
    if (getSelectedIssueStatus.error) {
      dispatch(getIssueByIdReset());
      updateModalRef.current.hide();
    }
  }, [dispatch, getSelectedIssueStatus.success, getSelectedIssueStatus.error]);

  useEffect(() => {
    if (deleteIssueStatus.success) {
      dispatch(deleteIssueReset());
      deleteModalRef.current.hide();
      toast.success('Succesfully deleted a issue');
      navigate({ pathname: '/issues', search: `?page=${1}` });
    }
    if (deleteIssueStatus.error) {
      dispatch(deleteIssueReset());
      deleteModalRef.current.hide();
      toast.error('Error occured during deleting a issue');
    }
  }, [dispatch, deleteIssueStatus.success, deleteIssueStatus.error, navigate]);

  useEffect(() => {
    if (updateIssueStatus.success) {
      dispatch(updateIssueReset());
      updateModalRef.current.hide();
      toast.success('Succesfully updated a issue');
    }
    if (updateIssueStatus.error) {
      dispatch(updateIssueReset());
      updateModalRef.current.hide();
      toast.error('Error occured while updating a issue');
    }
  }, [dispatch, updateIssueStatus.success, updateIssueStatus.error]);

  const isFormValid = () => {
    if (!issueTitle) {
      toast.warning('Please add issue title');
      return false;
    }
    if (!issueDescription) {
      toast.warning('Please add issue description');
      return false;
    }
    if (!videoUrl) {
      toast.warning('Please add a video');
      return false;
    }
    return true;
  };

  const onAddVideoClick = () => {
    videoInput.current.click();
  };

  const handleVideoPreview = async e => {
    if (e.target.files.length !== 0) {
      const selectedFile = e.target.files[0];

      if (selectedFile.type.startsWith('video/')) {
        let video_as_base64 = URL.createObjectURL(selectedFile);

        setVideo(selectedFile);
        setVideoUrl(video_as_base64);
      } else {
        toast.warning('Please select a video file.');
      }
    }
  };

  return (
    <div className="issue__details__screen">
      {issue && (
        <div className="issue__details__screen__content">
          <div className="issue__details__screen__content__container">
            <div className="issue__details__screen__content__container__box left">
              <div className="issue__details__screen__content__container__box-description">
                <h2>
                  <b>Issue</b>
                </h2>
                <p>
                  <b>Title:</b> {issue.title}
                </p>
                <p>
                  <b>Description:</b> {issue.description}
                </p>
                <p>
                  <b>Property:</b> {issue.property?.name}
                </p>
                <p>
                  <b>Created: </b>
                  {issue.createdAt ? formatDate(issue.createdAt) : ''}
                </p>
              </div>
              <div className="issue__details__screen__content__container__box__row">
                <div className="video">
                  <video src={issue?.medium?.url} controls width="400" height="400" />
                </div>

                <div className="issue__details__screen__content__container__box__row__buttons">
                  <Button
                    title="Update"
                    onClick={() => {
                      updateModalRef.current.show();
                    }}
                  />
                  <Button
                    title="Delete"
                    onClick={() => {
                      deleteModalRef.current.show();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        ref={deleteModalRef}
        onClose={() => deleteModalRef.current.hide()}
        title={`${issue.title}`}
        maxWidth="500px">
        {!deleteIssueStatus.attempt ? (
          <>
            <p className="modal__content-text">Are you sure you want to delete this issue?</p>
            <div className="modal__content__button__container">
              <Button
                title="No"
                onClick={() => deleteModalRef.current.hide()}
                style={{ marginRight: '2rem' }}
                className="modal__content__button__container-btn"
              />
              <Button
                title="Yes"
                onClick={() => {
                  dispatch(deleteIssue(issue.id));
                }}
                className="modal__content__button__container-btn"
              />
            </div>
          </>
        ) : (
          <div>
            <div className="modal__content__loader__container">
              <p className="modal__content__loader__container-text">Deleting issue...</p>
              <Loader className="modal__content__loader__container-loader" />
            </div>
          </div>
        )}
      </Modal>
      <Modal
        ref={updateModalRef}
        onClose={() => {
          updateModalRef.current.hide();
          setVideoUrl(issue.medium?.url);
          setIssueTitle(issue.title);
          setIssueDescription(issue.description);
        }}
        title={'Update Issue'}
        maxWidth="500px">
        {!updateIssueStatus.attempt ? (
          <>
            <Input value={issueTitle} type="text" onChange={setIssueTitle} placeholder="Name" />
            <Input value={issueDescription} type="text" onChange={setIssueDescription} placeholder="Description" />
            {videoUrl ? (
              <div className="video">
                <div
                  className="iconContainer"
                  onClick={() => {
                    setVideoUrl(null);
                  }}>
                  <img className="icon" src={Icons.cancelButton} alt="cancel-icon" />
                </div>
                <video src={videoUrl} controls width="400" height="400" />
              </div>
            ) : (
              <>
                <div
                  style={{
                    width: '100%',
                    marginTop: '20px',
                    marginBottom: '2rem',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  }}>
                  <Button title="Add Video" onClick={onAddVideoClick} />
                </div>
                <input
                  ref={videoInput}
                  type="file"
                  className="issues__screen__content__video__container__image__input"
                  onChange={handleVideoPreview}
                />
              </>
            )}
            <div className="modal__content__button__container">
              <Button
                title="Cancel"
                onClick={() => updateModalRef.current.hide()}
                style={{ marginRight: '2rem' }}
                className="modal__content__button__container-btn"
              />
              <Button
                title="Confirm"
                onClick={() => {
                  if (isFormValid()) {
                    let formData = new FormData();

                    if (video !== null) {
                      formData.append('file', video);
                    }
                    if (issueTitle !== null) {
                      formData.append('issueTitle', issueTitle);
                    }
                    if (issueDescription !== null) {
                      formData.append('issueDescription', issueDescription);
                    }
                    dispatch(updateIssue(formData, issue.id));
                  }
                }}
                className="modal__content__button__container-btn"
              />
            </div>
          </>
        ) : (
          <div>
            <div className="modal__content__loader__container">
              <p className="modal__content__loader__container-text">Update in progress...</p>
              <Loader className="modal__content__loader__container-loader" />
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default IssuesDetailsScreen;
