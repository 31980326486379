import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Input, Table, TableItem } from '../../components';
import { Icons } from '../../themes';
import PropertiesService from '../../api/services/properties';
import adminService from '../../api/services/admin';
import { ORDER } from '../../components/Table/Table';
import _ from 'lodash';
import Modal from '../../components/Modal/Modal';
import { formatDate } from '../../utils/util';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Loader } from '../../assets/icons/loader.svg';
import { addNewAdmin, addNewAdminReset } from '../../store/actions/admin';

const AdminsScreen = () => {
  const [searchTerm, setsearchTerm] = useState('');
  const [admins, setAdmins] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [perPage, setPerPage] = useState(1);
  const [adminEmail, setAdminEmail] = useState(null);
  const [adminPassword, setAdminPassword] = useState(null);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [adminRole, setAdminRole] = useState('');
  const [properties, setProperties] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const adminInfo = useSelector(state => state.admin.adminInfo);
  const [sortOptions, setSortOptions] = useState({
    sortBy: '',
    order: ORDER.ASC,
  });
  const modalRef = useRef();
  const dispatch = useDispatch();

  let page = +useLocation().search.split('=')[1];
  let navigate = useNavigate();

  const setPage = async page => {
    setCurrentPage(page);
    navigate({
      pathname: '/admins',
      search: `?page=${page}`,
    });
  };

  const roles = [
    { id: 1, name: 'SUPER_ADMIN' },
    { id: 2, name: 'PROPERTY_ADMIN' },
    { id: 3, name: 'MARKETING_ADMIN' },
  ];

  const tableHeader = [
    {
      key: 'email',
      label: 'Email',
      sortable: true,
    },
    {
      key: 'role',
      label: 'Role',
      sortable: true,
    },
    {
      key: 'createdAt',
      label: 'Created',
      sortable: true,
    },
  ];

  const handleRoleChange = event => {
    setAdminRole(event.target.value);
  };

  const handlePropertiesChange = selectedOptions => {
    setSelectedProperties(selectedOptions);
    setMenuOpen(true);
    if (selectedOptions.length === 0) {
      setSelectedProperties(selectedOptions);
    }
  };

  const isFormValid = () => {
    if (!adminEmail) {
      toast.warning('Please add admin email');
      return false;
    }
    if (!adminPassword) {
      toast.warning('Please add admin password');
      return false;
    }
    if (!selectedProperties.length && adminRole === 'PROPERTY_ADMIN') {
      toast.warning('Please select a property');
      return false;
    }
    if (adminRole === '') {
      toast.warning('Please add admin role');
      return false;
    }
    return true;
  };

  const debouncedSearch = useCallback(
    _.debounce(async (sortOptions, searchTerm, page) => {
      try {
        const response1 = await adminService.getAdmins(Number(page), sortOptions.sortBy, sortOptions.order, searchTerm);
        setPerPage(response1.perPage);
        setTotalCount(response1.total);
        setAdmins(response1.data);
      } catch (error) {
        console.log(error.message);
      }
    }, 400),
    [],
  );

  useEffect(() => {
    setCurrentPage(page);
    if (page > 0) debouncedSearch(sortOptions, searchTerm, page);
  }, [page, sortOptions, searchTerm, debouncedSearch]);

  const debouncedSetPageAndCurrentPage = useCallback(
    _.debounce(() => {
      setPage(1);
      setCurrentPage(1);
    }, 300),
    [],
  );

  useEffect(() => {
    if (adminInfo.success) {
      dispatch(addNewAdminReset());
      modalRef.current.hide();
      setSelectedProperties([]);
      setAdminEmail(null);
      setAdminPassword(null);
      setAdminRole('');
      toast.success('Succesfully added new Admin');
    }
    if (adminInfo.error) {
      dispatch(addNewAdminReset());
      toast.error('Error while adding new Admin');
    }
  }, [dispatch, adminInfo.success, adminInfo.error, navigate]);

  useEffect(() => {
    debouncedSetPageAndCurrentPage();
  }, [searchTerm]);

  const handleSort = async (sortBy, order) => {
    setSortOptions({ sortBy, order });
  };

  return (
    <div className="admins__screen">
      <div className="admins__screen__content">
        <div className="admins__screen__header">
          <p className="admins__screen__header-title">Admins</p>
          <Input className="admins__screen__header-search" type="text" onChange={setsearchTerm} placeholder="Search" />
          <div
            className="admins__screen__header-iconContainer"
            onClick={async () => {
              modalRef.current.show();
              const response = await PropertiesService.getPropertiesForBusiness();
              setProperties(response);
            }}>
            <img className="admins__screen__header-icon" src={Icons.addIcon} alt="add-icon" />
          </div>
        </div>
        <Table
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={perPage}
          hasExtraActions
          setPage={setPage}
          noDataContent="No Data"
          handleSort={handleSort}
          headerInformation={tableHeader}>
          {admins.map((admin, index) => {
            return (
              <Fragment key={index}>
                <TableItem data={admin.email} />
                <TableItem data={admin.role} />
                <TableItem data={admin.createdAt ? formatDate(admin.createdAt) : ''} />
                <TableItem
                  className={'admins__screen__content__search'}
                  data={
                    <Button
                      title={<img src={Icons.search} alt="search" />}
                      onClick={() =>
                        navigate({
                          pathname: `/admin/${admin.id}`,
                        })
                      }
                      className="admins__screen__content__btn"
                    />
                  }
                />
              </Fragment>
            );
          })}
        </Table>
        <Modal
          ref={modalRef}
          onClose={() => {
            modalRef.current.hide();
            setSelectedProperties([]);
            setAdminEmail(null);
            setAdminPassword(null);
            setAdminRole('');
          }}
          title={'Add new Admin'}
          maxWidth="500px">
          {adminInfo.attempt ? (
            <div>
              <div className="modal__content__loader__container">
                <p className="modal__content__loader__container-text">Adding new admin in progress...</p>
                <Loader className="modal__content__loader__container-loader" />
              </div>
            </div>
          ) : (
            <>
              <div className="select-container">
                <div
                  style={{
                    width: '100%',

                    gap: 10,
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                  }}>
                  <span htmlFor="properties">Properties:</span>
                  <div style={{ flex: 1 }}>
                    <Select
                      id="properties"
                      name="properties"
                      isMulti
                      options={properties}
                      value={selectedProperties}
                      onChange={handlePropertiesChange}
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.id}
                      menuIsOpen={menuOpen}
                      onMenuClose={() => setMenuOpen(false)}
                      onMenuOpen={() => setMenuOpen(true)}
                    />
                  </div>
                </div>
              </div>
              <div style={{ marginBottom: '2rem' }} className="property-dropdown">
                <label htmlFor="property-select" className="label">
                  Role:
                </label>
                <select id="property-select" value={adminRole} onChange={handleRoleChange}>
                  <option value="">Select a Role</option>
                  {roles.map(role => (
                    <option key={role.id} value={role.name}>
                      {role.name}
                    </option>
                  ))}
                </select>
              </div>

              <Input type="text" onChange={setAdminEmail} placeholder="Email" />
              <Input type="text" onChange={setAdminPassword} placeholder="Password" />

              <Button
                title="Submit"
                onClick={() => {
                  if (isFormValid()) {
                    if (adminRole === 'SUPER ADMIN' && adminRole === 'MARKETING') {
                      dispatch(addNewAdmin({ email: adminEmail, password: adminPassword, role: adminRole }));
                    } else {
                      dispatch(
                        addNewAdmin({
                          email: adminEmail,
                          password: adminPassword,
                          role: adminRole,
                          propertyIds: [...selectedProperties.map(property => property.id)],
                        }),
                      );
                    }
                  }
                }}
                style={{ marginRight: '2rem' }}
                className="submit"
              />
            </>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default AdminsScreen;
