import React from 'react';

const ErrorScreen = () => {
  return (
    <div className="error__screen">
      <div className="error__screen__content">
        <p style={{ color: 'black' }}>Page Not Found</p>
      </div>
    </div>
  );
};

export default ErrorScreen;
