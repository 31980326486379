import React from 'react';
import Select from 'react-select';

const PropertyCard = ({
  property,
  floors,
  units,
  selectedFloors,
  handleFloorsChange,
  selectedUnits,
  handleUnitsChange,
}) => {
  return (
    <div className="select-container">
      <h3>{property.name}</h3>
      <p htmlFor="floors">Floors</p>
      <Select
        id="floors"
        name="floors"
        isMulti
        options={floors[property.id]}
        value={selectedFloors[property.id]}
        onChange={s => handleFloorsChange(s, property.id)}
        getOptionLabel={option => option.floorNumber}
        getOptionValue={option => option.floorNumber}
        isDisabled={!property}
      />
      {selectedFloors[property.id] && !!selectedFloors[property.id].length && (
        <div>
          <p htmlFor="units">Units</p>
          <Select
            id="units"
            name="units"
            isMulti
            options={units[property.id]}
            value={selectedUnits[property.id]}
            onChange={s => handleUnitsChange(s, property.id)}
            getOptionLabel={option => `${option.unitNumber} - ${option.buildingName}`}
            getOptionValue={option => option.unitNumber}
            isDisabled={!property}
          />
        </div>
      )}
    </div>
  );
};

export default PropertyCard;
