import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Input, Table, TableItem } from '../../components';
import { Icons } from '../../themes';
import ClusterService from '../../api/services/cluster';
import { ORDER } from '../../components/Table/Table';
import _ from 'lodash';
import { formatDate } from '../../utils/util';

const ClustersScreen = () => {
  const [searchTerm, setsearchTerm] = useState('');
  const [clusters, setClusters] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [perPage, setPerPage] = useState(1);
  const [sortOptions, setSortOptions] = useState({
    sortBy: '',
    order: ORDER.ASC,
  });

  let page = +useLocation().search.split('=')[1];
  let navigate = useNavigate();

  const setPage = async page => {
    setCurrentPage(page);
    navigate({
      pathname: '/clusters',
      search: `?page=${page}`,
    });
  };

  const tableHeader = [
    {
      key: 'name',
      label: 'Name',
      sortable: true,
    },
    {
      key: 'property',
      label: 'Property',
      sortable: false,
    },
    {
      key: 'createdAt',
      label: 'Created',
      sortable: true,
    },
  ];

  const debouncedSearch = useCallback(
    _.debounce(async (sortOptions, searchTerm, page) => {
      try {
        const response1 = await ClusterService.getAllClustersForTable(
          Number(page),
          sortOptions.sortBy,
          sortOptions.order,
          searchTerm,
        );
        setPerPage(response1.perPage);
        setTotalCount(response1.total);
        setClusters(response1.data);
      } catch (error) {
        console.log(error.message);
      }
    }, 400),
    [],
  );

  useEffect(() => {
    setCurrentPage(page);
    if (page > 0) debouncedSearch(sortOptions, searchTerm, page);
  }, [page, sortOptions, searchTerm, debouncedSearch]);

  const debouncedSetPageAndCurrentPage = useCallback(
    _.debounce(() => {
      setPage(1);
      setCurrentPage(1);
    }, 300),
    [],
  );

  useEffect(() => {
    debouncedSetPageAndCurrentPage();
  }, [searchTerm]);

  const handleSort = async (sortBy, order) => {
    setSortOptions({ sortBy, order });
  };

  return (
    <div className="clusters__screen">
      <div className="clusters__screen__content">
        <div className="clusters__screen__header">
          <p className="clusters__screen__header-title">Clusters</p>
          <Input
            className="clusters__screen__header-search"
            type="text"
            onChange={setsearchTerm}
            placeholder="Search"
          />
          <div
            className="business__screen__header-iconContainer"
            onClick={() => {
              navigate({
                pathname: 'addNew',
              });
            }}>
            <img className="business__screen__header-icon" src={Icons.addIcon} alt="add-icon" />
          </div>
        </div>
        <Table
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={perPage}
          hasExtraActions
          setPage={setPage}
          noDataContent="No Data"
          handleSort={handleSort}
          headerInformation={tableHeader}>
          {clusters.map((cluster, index) => {
            return (
              <Fragment key={index}>
                <TableItem data={cluster?.name} />
                <TableItem data={cluster?.properties.map(property => `${property.name}`).join(', ')} />
                <TableItem data={cluster.createdAt ? formatDate(cluster.createdAt) : ''} />
                {/* <TableItem
                  className={'clusters__screen__content__search'}
                  data={
                    <Button
                      title={<img src={Icons.search} alt="search" />}
                      onClick={() =>
                        navigate({
                          pathname: `/item/${cluster.id}`,
                          //   This is not good ... change it
                        })
                      }
                      className="clusters__screen__content__btn"
                    />
                  }
                /> */}
              </Fragment>
            );
          })}
        </Table>
      </div>
    </div>
  );
};

export default ClustersScreen;
