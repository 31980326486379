import {
  ADD_ADMIN_ATTEMPT,
  ADD_ADMIN_SUCCESS,
  ADD_ADMIN_ERROR,
  ADD_ADMIN_RESET,
  GET_ADMIN_BY_ID_ATTEMPT,
  GET_ADMIN_BY_ID_SUCCESS,
  GET_ADMIN_BY_ID_ERROR,
  GET_ADMIN_BY_ID_RESET,
  DELETE_ADMIN_ATTEMPT,
  DELETE_ADMIN_SUCCESS,
  DELETE_ADMIN_ERROR,
  DELETE_ADMIN_RESET,
  UUPDATE_ADMIN_ATTEMPT,
  UPDATE_ADMIN_SUCCESS,
  UPDATE_ADMIN_ERROR,
  UPDATE_ADMIN_RESET,
} from '../constants';

const INIT_STATE = {
  adminInfo: {
    attempt: false,
    success: false,
    error: null,
    selectedAdmin: {},
    getAdminByIdStatus: {
      attempt: false,
      success: false,
      error: null,
    },
    deleteAdminStatus: {
      attempt: false,
      success: false,
      error: null,
    },
    updateAdminStatus: {
      attempt: false,
      success: false,
      error: null,
    },
  },
};

const adminReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_ADMIN_ATTEMPT:
      return {
        ...state,
        adminInfo: { attempt: true, success: false, error: null },
      };
    case ADD_ADMIN_SUCCESS:
      return {
        ...state,
        adminInfo: { attempt: false, success: true, error: null },
      };
    case ADD_ADMIN_ERROR:
      return {
        ...state,
        adminInfo: { attempt: false, success: false, error: action.payload },
      };
    case ADD_ADMIN_RESET:
      return {
        ...state,
        adminInfo: { attempt: false, success: false, error: null },
      };
    case GET_ADMIN_BY_ID_ATTEMPT:
      return {
        ...state,
        adminInfo: { ...state.adminInfo, getAdminByIdStatus: { attempt: true, success: false, error: null } },
      };
    case GET_ADMIN_BY_ID_SUCCESS:
      return {
        ...state,
        adminInfo: {
          ...state.adminInfo,
          selectedAdmin: action.payload,
          getAdminByIdStatus: { attempt: false, success: true, error: null },
        },
      };
    case GET_ADMIN_BY_ID_ERROR:
      return {
        ...state,
        adminInfo: {
          ...state.adminInfo,
          getAdminByIdStatus: { attempt: false, success: false, error: action.payload },
        },
      };
    case GET_ADMIN_BY_ID_RESET:
      return {
        ...state,
        adminInfo: { ...state.adminInfo, getAdminByIdStatus: { attempt: false, success: false, error: null } },
      };
    case DELETE_ADMIN_ATTEMPT:
      return {
        ...state,
        adminInfo: {
          ...state.adminInfo,
          deleteAdminStatus: {
            attempt: true,
            success: false,
            error: null,
          },
        },
      };
    case DELETE_ADMIN_SUCCESS:
      return {
        ...state,
        adminInfo: {
          ...state.adminInfo,
          selectedAdmin: {},
          deleteAdminStatus: {
            attempt: false,
            success: true,
            error: null,
          },
        },
      };
    case DELETE_ADMIN_ERROR:
      return {
        ...state,
        adminInfo: {
          ...state.adminInfo,
          deleteAdminStatus: {
            attempt: false,
            success: false,
            error: action.payload,
          },
        },
      };
    case DELETE_ADMIN_RESET:
      return {
        ...state,
        adminInfo: {
          ...state.adminInfo,
          deleteAdminStatus: {
            attempt: false,
            success: false,
            error: null,
          },
        },
      };
    case UUPDATE_ADMIN_ATTEMPT:
      return {
        ...state,
        adminInfo: {
          ...state.adminInfo,
          updateAdminStatus: {
            attempt: true,
            success: false,
            error: null,
          },
        },
      };
    case UPDATE_ADMIN_SUCCESS:
      return {
        ...state,
        adminInfo: {
          ...state.adminInfo,
          selectedAdmin: action.payload,
          updateAdminStatus: {
            attempt: false,
            success: true,
            error: null,
          },
        },
      };
    case UPDATE_ADMIN_ERROR:
      return {
        ...state,
        adminInfo: {
          ...state.adminInfo,
          updateAdminStatus: {
            attempt: false,
            success: false,
            error: action.payload,
          },
        },
      };
    case UPDATE_ADMIN_RESET:
      return {
        ...state,
        adminInfo: {
          ...state.adminInfo,
          updateAdminStatus: {
            attempt: false,
            success: false,
            error: null,
          },
        },
      };
    default:
      return state;
  }
};

export default adminReducer;
