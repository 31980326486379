import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PropertiesService from '../../api/services/properties';
import { formatDate } from '../../utils/util';
import { Button, GoogleMap, ImageWrapper, Input, Modal, MultilineInput } from '../../components';
import { Images } from '../../themes';
import { ReactComponent as Loader } from '../../assets/icons/loader.svg';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  deleteBusiness,
  deleteBusinessReset,
  getBusinessById,
  getBusinessByIdReset,
  updateBusiness,
  updateBusinessReset,
} from '../../store/actions/business';
import isURL from 'validator/lib/isURL';
import { Icons } from '../../themes';

const LocalBusinessDetailsScreen = () => {
  const { id } = useParams();
  const deleteModalRef = useRef();
  const modalRef = useRef();
  const [address, setAddress] = useState(null);
  const [businessName, setBusinessName] = useState('');
  const [businessType, setBusinessType] = useState('');
  const [websiteLink, setWebsiteLink] = useState('');
  const [discountCode, setDiscountCode] = useState('');
  const [selectedProperty, setSelectedProperty] = useState(0);
  const updateBusinessStatus = useSelector(state => state.business.businessInfo.updateBusinessStatus);
  const [properties, setProperties] = useState([]);
  const [image, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [lat, setLat] = useState(0);
  const [long, setLong] = useState(0);
  const [schedule, setSchedule] = useState({});
  const [discountCodeOffer, setDiscountCodeOffer] = useState('');
  const deleteBusinessStatus = useSelector(state => state.business.businessInfo.deleteBusinessStatus);
  const selectedBusiness = useSelector(state => state.business.businessInfo.currentBusiness);
  const getBusinessByIdStatus = useSelector(state => state.business.businessInfo.getBusinessByIdStatus);
  const navigate = useNavigate();
  const imageInput = useRef();
  const dispatch = useDispatch();

  const sortedDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  const SortedHoursOfOperation = () => {
    const days = Object.keys(selectedBusiness.hoursOfOperation);

    const sortedDaysData = days.sort((a, b) => sortedDays.indexOf(a) - sortedDays.indexOf(b));

    const sortedHoursOfOperation = sortedDaysData.reduce((acc, day) => {
      acc[day] = selectedBusiness.hoursOfOperation[day];
      return acc;
    }, {});

    return (
      <>
        <b>Hours of Operation:</b>
        <>
          {Object.entries(sortedHoursOfOperation).map(([day, timeRange]) => (
            <p key={day}>
              {day}: {timeRange.start} - {timeRange.end}
            </p>
          ))}
        </>
      </>
    );
  };

  useEffect(() => {
    dispatch(getBusinessById(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (getBusinessByIdStatus.success) {
      dispatch(getBusinessByIdReset());
      modalRef.current.hide();
      setAddress(selectedBusiness.location);
      setLat(selectedBusiness.latitude);
      setLong(selectedBusiness.longitude);
    }
    if (getBusinessByIdStatus.error) {
      dispatch(getBusinessByIdReset());
      modalRef.current.hide();
    }
  }, [dispatch, getBusinessByIdStatus.success, getBusinessByIdStatus.error]);

  useEffect(() => {
    if (deleteBusinessStatus.success) {
      dispatch(deleteBusinessReset());
      deleteModalRef.current.hide();
      toast.success('Succesfully deleted a business');
      navigate({ pathname: '/businesses', search: `?page=${1}` });
    }
    if (deleteBusinessStatus.error) {
      dispatch(deleteBusinessReset());
      deleteModalRef.current.hide();
      toast.error('Error occured during deleting a business');
    }
  }, [dispatch, deleteBusinessStatus.success, deleteBusinessStatus.error, navigate]);

  useEffect(() => {
    if (updateBusinessStatus.success) {
      dispatch(updateBusinessReset());
      modalRef.current.hide();
      toast.success('Succesfully updated a business');
    }
    if (updateBusinessStatus.error) {
      dispatch(updateBusinessReset());
      modalRef.current.hide();
      toast.error('Error occured while updating a business');
    }
  }, [dispatch, updateBusinessStatus.success, updateBusinessStatus.error]);

  const handlePropertyChange = event => {
    setSelectedProperty(event.target.value);
  };

  const handleImagePreview = async e => {
    if (e.target.files.length !== 0) {
      let image_as_base64 = URL.createObjectURL(e.target.files[0]);
      let image_as_files = e.target.files[0];

      setImage(image_as_files);
      setPreviewImage(image_as_base64);
    }
  };

  const onAddPhotoClick = () => {
    imageInput.current.click();
  };

  const formValid = () => {
    if (!businessName) {
      toast.warning('Please add business name');
      return false;
    }
    if (!businessType) {
      toast.warning('Please add business type');
      return false;
    }
    if (!(websiteLink && isURL(websiteLink))) {
      toast.warning('Please enter a valid website link');
      return false;
    }
    if (!address) {
      toast.warning('Please select addres on the map');
      return false;
    }
    if (Object.keys(schedule).length === 0) {
      toast.warning('Please select working hours');
      return false;
    }
    if (selectedProperty === 0) {
      toast.warning('Please select a property');
      return false;
    }
    return true;
  };

  const handleStartTimeChange = (day, time) => {
    setSchedule(prevSchedule => ({
      ...prevSchedule,
      [day]: {
        ...prevSchedule[day],
        start: time,
      },
    }));
  };

  const handleEndTimeChange = (day, time) => {
    setSchedule(prevSchedule => ({
      ...prevSchedule,
      [day]: {
        ...prevSchedule[day],
        end: time,
      },
    }));
  };

  const renderTimeOptions = () => {
    const hours = Array.from({ length: 12 }, (_, i) => i + 1);
    const timeOptions = [];

    for (const period of ['am', 'pm']) {
      for (const hour of hours) {
        timeOptions.push(`${hour}:00${period}`);
      }
    }

    return timeOptions.map(time => (
      <option key={time} value={time}>
        {time}
      </option>
    ));
  };

  return (
    <div className="business__details__screen">
      {selectedBusiness && (
        <div className="business__details__screen__content">
          <div className="business__details__screen__content__container">
            <div className="business__details__screen__content__container__box left">
              <div>
                <h2>
                  <b>Business</b>
                </h2>
                <p>
                  <b>Name:</b> {selectedBusiness.name}
                </p>
                <p>
                  <b>Type: </b>
                  {selectedBusiness.type}
                </p>
                <p>
                  <b>Location:</b> {selectedBusiness.location}
                </p>
                <p>{selectedBusiness.hoursOfOperation && SortedHoursOfOperation()}</p>
                <p>
                  <b>Website link: </b>
                  {selectedBusiness.websiteLink}
                </p>
                <p>
                  <b>Created: </b>
                  {selectedBusiness.createdAt ? formatDate(selectedBusiness.createdAt) : ''}
                </p>
                {selectedBusiness.discountCode ? (
                  <p>
                    <b>Discount code: </b>
                    {selectedBusiness.discountCode}
                  </p>
                ) : null}

                <p>
                  <b>Property: </b>
                  {selectedBusiness?.property?.name}
                </p>
              </div>
              <div className="business__details__screen__content__container__box__row">
                <ImageWrapper
                  alt={selectedBusiness.name}
                  source={selectedBusiness?.photo ? selectedBusiness.photo.url : Images.logo}
                  style={{ height: '200px', borderRadius: '0.5rem' }}
                />
                <div className="business__details__screen__content__container__box__row__buttons">
                  <Button
                    title="Update"
                    onClick={async () => {
                      modalRef.current.show();
                      const response = await PropertiesService.getPropertiesForBusiness();
                      setProperties(response);
                      setBusinessName(selectedBusiness.name);
                      setBusinessType(selectedBusiness.type);
                      setWebsiteLink(selectedBusiness.websiteLink);
                      setDiscountCode(selectedBusiness?.discountCode ? selectedBusiness.discountCode : '');
                      setDiscountCodeOffer(
                        selectedBusiness?.discountCodeOffer ? selectedBusiness.discountCodeOffer : '',
                      );
                      setSelectedProperty(selectedBusiness.property.id);
                      setSchedule(selectedBusiness.hoursOfOperation);
                      setPreviewImage(selectedBusiness.photo.url);
                    }}
                  />
                  <Button
                    title="Delete"
                    onClick={() => {
                      deleteModalRef.current.show();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        ref={deleteModalRef}
        onClose={() => deleteModalRef.current.hide()}
        title={`${selectedBusiness?.name}`}
        maxWidth="500px">
        {!deleteBusinessStatus.attempt ? (
          <>
            <p className="modal__content-text">Are you sure you want to delete this local business partnership?</p>
            <div className="modal__content__button__container">
              <Button
                title="No"
                onClick={() => deleteModalRef.current.hide()}
                style={{ marginRight: '2rem' }}
                className="modal__content__button__container-btn"
              />
              <Button
                title="Yes"
                onClick={() => {
                  dispatch(deleteBusiness(selectedBusiness.id));
                }}
                className="modal__content__button__container-btn"
              />
            </div>
          </>
        ) : (
          <div>
            <div className="modal__content__loader__container">
              <p className="modal__content__loader__container-text">Deleting in progress...</p>
              <Loader className="modal__content__loader__container-loader" />
            </div>
          </div>
        )}
      </Modal>
      <Modal
        ref={modalRef}
        onClose={() => {
          modalRef.current.hide();
          setLat(selectedBusiness.latitude);
          setLong(selectedBusiness.longitude);
          setAddress(selectedBusiness.location);
          setPreviewImage(selectedBusiness.photo.url);
        }}
        title={'Update Local Business Partnership'}
        maxWidth="500px">
        {!updateBusinessStatus.attempt ? (
          <>
            <Input value={businessName} type="text" onChange={setBusinessName} placeholder="Name" />
            <Input value={businessType} type="text" onChange={setBusinessType} placeholder="Type" />
            <Input value={websiteLink} type="text" onChange={setWebsiteLink} placeholder="Website Link" />
            <Input value={discountCode} type="text" onChange={setDiscountCode} placeholder="Discount Code" />
            <MultilineInput
              style={{ marginBottom: '2rem', width: '100%' }}
              value={discountCodeOffer}
              onChange={setDiscountCodeOffer}
              placeholder={'Discount Code Offer'}
            />
            <div className="time-picker-container">
              {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(day => (
                <div key={day}>
                  <label className="time-picker-label" htmlFor={`${day}-start`}>
                    {day}:
                  </label>
                  <div className="first">
                    <select
                      id={`${day}-start`}
                      className="time-picker-select"
                      onChange={e => handleStartTimeChange(day, e.target.value)}
                      value={(schedule[day] && schedule[day].start) || ''}>
                      <option value="">Select start time</option>
                      {renderTimeOptions()}
                    </select>
                  </div>
                  <div>
                    <select
                      id={`${day}-end`}
                      className="time-picker-select"
                      onChange={e => handleEndTimeChange(day, e.target.value)}
                      value={(schedule[day] && schedule[day].end) || ''}>
                      <option value="">Select end time</option>
                      {renderTimeOptions()}
                    </select>
                  </div>
                </div>
              ))}
            </div>
            <div className="property-dropdown">
              <label htmlFor="property-select" className="label">
                Property:
              </label>
              <select id="property-select" value={selectedProperty} onChange={handlePropertyChange}>
                <option value="">Select a property</option>
                {properties.map(property => (
                  <option key={property.id} value={property.id}>
                    {property.name}
                  </option>
                ))}
              </select>
            </div>
            {!previewImage ? (
              <>
                <div
                  style={{
                    width: '100%',
                    marginTop: '20px',
                    marginBottom: '2rem',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  }}>
                  <Button title="Add Photo" onClick={onAddPhotoClick} />
                </div>
                <input
                  ref={imageInput}
                  type="file"
                  className="business__screen__content__photo__container__image__input"
                  onChange={handleImagePreview}
                />
              </>
            ) : (
              <div className="modalImage">
                <div className="imageIconContainer">
                  <div
                    className="iconContainer"
                    onClick={() => {
                      setPreviewImage(null);
                    }}>
                    <img className="icon" src={Icons.cancelButton} alt="delete-icon" />
                  </div>
                  <img
                    alt="coverPhoto"
                    style={{ height: '250px', display: 'flex', alignSelf: 'center' }}
                    src={previewImage}
                  />
                </div>
              </div>
            )}

            <div>
              <GoogleMap
                address={address}
                setAddress={setAddress}
                setLat={setLat}
                setLong={setLong}
                lat={lat}
                long={long}
              />
            </div>
            <div className="modal__content__button__container">
              <Button
                title="Cancel"
                onClick={() => {
                  modalRef.current.hide();
                  setLat(selectedBusiness.latitude);
                  setLong(selectedBusiness.longitude);
                  setAddress(selectedBusiness.location);
                  setPreviewImage(selectedBusiness.photo.url);
                }}
                style={{ marginRight: '2rem' }}
                className="modal__content__button__container-btn"
              />
              <Button
                title="Confirm"
                onClick={() => {
                  if (formValid()) {
                    let formData = new FormData();

                    if (image !== null) {
                      formData.append('file', image);
                    }
                    if (address !== null) {
                      formData.append('location', address);
                    }
                    if (businessName !== '') {
                      formData.append('name', businessName);
                    }
                    if (businessType !== '') {
                      formData.append('type', businessType);
                    }
                    if (websiteLink !== '') {
                      formData.append('websiteLink', websiteLink);
                    }
                    if (discountCode !== '') {
                      formData.append('discountCode', discountCode);
                    }
                    if (discountCodeOffer !== '') {
                      formData.append('discountCodeOffer', discountCodeOffer);
                    }
                    if (selectedProperty !== 0) {
                      formData.append('propertyId', selectedProperty);
                    }
                    if (Object.keys(schedule).length !== 0) {
                      formData.append('hoursOfOperation', JSON.stringify(schedule));
                    }
                    if (lat) {
                      formData.append('lat', lat);
                    }
                    if (long) {
                      formData.append('long', long);
                    }

                    dispatch(updateBusiness(formData, id));
                  }
                }}
                className="modal__content__button__container-btn"
              />
            </div>
          </>
        ) : (
          <div>
            <div className="modal__content__loader__container">
              <Loader className="modal__content__loader__container-loader" />
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default LocalBusinessDetailsScreen;
