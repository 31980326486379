import { Icons } from '../../themes';
import { adminRoles } from '../../utils/constants';

export const SidebarData = [
  {
    title: 'Properties',
    path: '/properties?page=1',
    icon: Icons.home,
    cName: 'nav-text',
    roles: [adminRoles.SUPER, adminRoles.PROPERTY, adminRoles.MARKETING],
  },
  {
    title: 'Residents',
    path: '/customers?page=1',
    icon: Icons.people,
    cName: 'nav-text',
    roles: [adminRoles.SUPER, adminRoles.PROPERTY],
  },
  {
    title: 'Business',
    path: '/businesses?page=1',
    icon: Icons.business,
    cName: 'nav-text',
    roles: [adminRoles.SUPER, adminRoles.PROPERTY],
  },
  {
    title: 'Send Notifications',
    path: '/send-notifications',
    icon: Icons.notifications,
    cName: 'nav-text',
    roles: [adminRoles.SUPER, adminRoles.PROPERTY],
  },
  {
    title: 'Marketplace',
    icon: Icons.marketplace,
    cName: 'nav-text-marketplace',
    roles: [adminRoles.SUPER, adminRoles.PROPERTY],
    subMenus: [
      {
        title: 'Items',
        path: '/marketplace?page=1',
        icon: Icons.blueArrowRight,
        roles: [adminRoles.SUPER, adminRoles.PROPERTY],
      },
      {
        title: 'Clusters',
        path: '/clusters',
        icon: Icons.blueArrowRight,
        roles: [adminRoles.SUPER],
      },
    ],
  },
  {
    title: 'Issues',
    path: '/issues?page=1',
    icon: Icons.issue,
    cName: 'nav-text',
    roles: [adminRoles.SUPER, adminRoles.PROPERTY],
  },
  {
    title: 'Admins',
    path: '/admins?page=1',
    icon: Icons.admin,
    cName: 'nav-text',
    roles: [adminRoles.SUPER],
  },
];
