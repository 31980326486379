import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, GoogleMap, Input, MultilineInput, Table, TableItem } from '../../components';
import { Icons } from '../../themes';
import BusinessService from '../../api/services/business';
import { ORDER } from '../../components/Table/Table';
import _ from 'lodash';
import { formatDate } from '../../utils/util';
import Modal from '../../components/Modal/Modal';
import { ReactComponent as Loader } from '../../assets/icons/loader.svg';
import { toast } from 'react-toastify';
import isURL from 'validator/lib/isURL';
import PropertiesService from '../../api/services/properties';
import { useDispatch, useSelector } from 'react-redux';
import { addNewBusiness, addNewBusinessReset } from '../../store/actions/business';
import { ReactComponent as CancelIcon } from '../../assets/icons/cancelButton.svg';

const LocalBusinessScreen = () => {
  const [searchTerm, setsearchTerm] = useState('');
  const [businesses, setBusinesses] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [perPage, setPerPage] = useState(1);
  const [address, setAddress] = useState(null);
  const [businessName, setBusinessName] = useState(null);
  const [businessType, setBusinessType] = useState(null);
  const [websiteLink, setWebsiteLink] = useState(null);
  const [discountCode, setDiscountCode] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(0);
  const businessInfo = useSelector(state => state.business.businessInfo);
  const [properties, setProperties] = useState([]);
  const [image, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [lat, setLat] = useState(0);
  const [long, setLong] = useState(0);
  const [discountCodeOffer, setDiscountCodeOffer] = useState('');
  const [schedule, setSchedule] = useState({});
  const imageInput = useRef();
  const dispatch = useDispatch();

  const [sortOptions, setSortOptions] = useState({
    sortBy: '',
    order: ORDER.ASC,
  });
  const modalRef = useRef();

  let page = +useLocation().search.split('=')[1];
  let navigate = useNavigate();

  const setPage = async page => {
    setCurrentPage(page);
    navigate({
      pathname: '/businesses',
      search: `?page=${page}`,
    });
  };

  const handleStartTimeChange = (day, time) => {
    setSchedule(prevSchedule => ({
      ...prevSchedule,
      [day]: {
        ...prevSchedule[day],
        start: time,
      },
    }));
  };

  const handleEndTimeChange = (day, time) => {
    setSchedule(prevSchedule => ({
      ...prevSchedule,
      [day]: {
        ...prevSchedule[day],
        end: time,
      },
    }));
  };

  const renderTimeOptions = () => {
    const hours = Array.from({ length: 12 }, (_, i) => i + 1);
    const timeOptions = [];

    for (const period of ['am', 'pm']) {
      for (const hour of hours) {
        timeOptions.push(`${hour}:00${period}`);
      }
    }

    return timeOptions.map(time => (
      <option key={time} value={time}>
        {time}
      </option>
    ));
  };

  const tableHeader = [
    {
      key: 'name',
      label: 'Name',
      sortable: true,
    },
    {
      key: 'type',
      label: 'Type',
      sortable: true,
    },
    {
      key: 'websiteLink',
      label: 'Website',
      sortable: true,
    },
    {
      key: 'property',
      label: 'Property',
      sortable: false,
    },
    {
      key: 'location',
      label: 'Location',
      sortable: true,
    },
    {
      key: 'createdAt',
      label: 'Created',
      sortable: true,
    },
  ];

  useEffect(() => {
    if (businessInfo.success) {
      dispatch(addNewBusinessReset());
      setImage(null);
      setSchedule({});
      setSelectedProperty(0);
      setPreviewImage(null);
      setLong(0);
      setLat(0);
      modalRef.current.hide();
      toast.success('Succesfully added new Business');
    }
    if (businessInfo.error) {
      dispatch(addNewBusinessReset());
      toast.error('Error while adding new Business');
    }
  }, [dispatch, businessInfo.success, businessInfo.error]);

  const debouncedSearch = useCallback(
    _.debounce(async (sortOptions, searchTerm, page) => {
      try {
        const response1 = await BusinessService.getBusinesses(
          Number(page),
          sortOptions.sortBy,
          sortOptions.order,
          searchTerm,
        );
        setPerPage(response1.perPage);
        setTotalCount(response1.total);
        setBusinesses(response1.data);
      } catch (error) {
        console.log(error.message);
      }
    }, 400),
    [],
  );

  const onAddPhotoClick = () => {
    imageInput.current.click();
  };

  const handleImagePreview = async e => {
    if (e.target.files.length !== 0) {
      let image_as_files = e.target.files[0];

      if (image_as_files.type.startsWith('image/')) {
        let image_as_base64 = URL.createObjectURL(e.target.files[0]);

        setImage(image_as_files);
        setPreviewImage(image_as_base64);
      } else {
        toast.warning('Please select a image file.');
      }
    }
  };

  const formValid = () => {
    if (!businessName) {
      toast.warning('Please add business name');
      return false;
    }
    if (!businessType) {
      toast.warning('Please add business type');
      return false;
    }
    if (!(websiteLink && isURL(websiteLink))) {
      toast.warning('Please enter a valid website link');
      return false;
    }
    if (!address) {
      toast.warning('Please select addres on the map');
      return false;
    }
    if (Object.keys(schedule).length === 0) {
      toast.warning('Please select working hours');
    }
    if (selectedProperty === 0) {
      toast.warning('Please select a property');
      return false;
    }
    if (image === null) {
      toast.warning('Please add a image');
      return false;
    }
    return true;
  };

  useEffect(() => {
    setCurrentPage(page);
    if (page > 0) debouncedSearch(sortOptions, searchTerm, page);
  }, [page, sortOptions, searchTerm, debouncedSearch]);

  const debouncedSetPageAndCurrentPage = useCallback(
    _.debounce(() => {
      setPage(1);
      setCurrentPage(1);
    }, 300),
    [],
  );

  useEffect(() => {
    debouncedSetPageAndCurrentPage();
  }, [searchTerm]);

  const handleSort = async (sortBy, order) => {
    setSortOptions({ sortBy, order });
  };

  const handlePropertyChange = event => {
    setSelectedProperty(event.target.value);
  };

  return (
    <div className="business__screen">
      <div className="business__screen__content">
        <div className="business__screen__header">
          <p className="business__screen__header-title">Business</p>
          <Input
            className="business__screen__header-search"
            type="text"
            onChange={setsearchTerm}
            placeholder="Search"
          />
          <div
            className="business__screen__header-iconContainer"
            onClick={async () => {
              modalRef.current.show();
              const response = await PropertiesService.getPropertiesForBusiness();
              setProperties(response);
            }}>
            <img className="business__screen__header-icon" src={Icons.addIcon} alt="add-icon" />
          </div>
        </div>
        <Table
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={perPage}
          hasExtraActions
          setPage={setPage}
          noDataContent="No Data"
          handleSort={handleSort}
          headerInformation={tableHeader}>
          {businesses.map((business, index) => {
            return (
              <Fragment key={index}>
                <TableItem data={business.name} />
                <TableItem data={business.type} />
                <TableItem data={business.websiteLink} />
                <TableItem data={business.property.name} />
                <TableItem data={business.location} />
                <TableItem data={business.createdAt ? formatDate(business.createdAt) : ''} />
                <TableItem
                  className={'business__screen__content__search'}
                  data={
                    <Button
                      title={<img src={Icons.search} alt="search" />}
                      onClick={() =>
                        navigate({
                          pathname: `/business/${business.id}`,
                        })
                      }
                      className="business__screen__content__btn"
                    />
                  }
                />
              </Fragment>
            );
          })}
        </Table>
        <Modal
          ref={modalRef}
          onClose={() => {
            modalRef.current.hide();
            setImage(null);
            setSchedule({});
            setAddress(null);
            setPreviewImage(null);
            setSelectedProperty(0);
            setLong(0);
            setLat(0);
          }}
          title={'Add new Local Business Partnership'}
          maxWidth="500px">
          {!businessInfo.attempt ? (
            <>
              <Input type="text" value={businessName} onChange={setBusinessName} placeholder="Name (required)" />
              <Input type="text" value={businessType} onChange={setBusinessType} placeholder="Type (required)" />
              <Input type="text" value={websiteLink} onChange={setWebsiteLink} placeholder="Website Link (required)" />
              <Input type="text" value={discountCode} onChange={setDiscountCode} placeholder="Discount Code" />
              <MultilineInput
                style={{ marginBottom: '2rem', width: '100%' }}
                value={discountCodeOffer}
                onChange={setDiscountCodeOffer}
                placeholder={'Discount Code Offer'}
              />

              <div className="time-picker-container">
                {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(day => (
                  <div key={day}>
                    <label className="time-picker-label" htmlFor={`${day}-start`}>
                      {day}:
                    </label>

                    <div className="first">
                      <select
                        id={`${day}-start`}
                        className="time-picker-select"
                        onChange={e => handleStartTimeChange(day, e.target.value)}
                        value={(schedule[day] && schedule[day].start) || ''}>
                        <option value="">Select start time</option>
                        {renderTimeOptions()}
                      </select>
                    </div>
                    <div>
                      <select
                        id={`${day}-end`}
                        className="time-picker-select"
                        onChange={e => handleEndTimeChange(day, e.target.value)}
                        value={(schedule[day] && schedule[day].end) || ''}>
                        <option value="">Select end time</option>
                        {renderTimeOptions()}
                      </select>
                    </div>
                  </div>
                ))}
              </div>
              <div className="property-dropdown">
                <label htmlFor="property-select" className="label">
                  Property:
                </label>
                <select id="property-select" value={selectedProperty} onChange={handlePropertyChange}>
                  <option value="">Select a property</option>
                  {properties.map(property => (
                    <option key={property.id} value={property.id}>
                      {property.name}
                    </option>
                  ))}
                </select>
              </div>
              {!previewImage ? (
                <>
                  <div
                    style={{
                      width: '100%',
                      marginTop: '20px',
                      marginBottom: '2rem',
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                    }}>
                    <Button title="Add Photo" onClick={onAddPhotoClick} />
                  </div>
                  <input
                    ref={imageInput}
                    type="file"
                    className="business__screen__content__photo__container__image__input"
                    onChange={handleImagePreview}
                  />
                </>
              ) : (
                <div className="modalImage">
                  <div className="imageIconContainer">
                    <div
                      className="iconContainer"
                      onClick={() => {
                        setPreviewImage(null);
                        setImage(null);
                      }}>
                      <CancelIcon className="icon" fill={'#ff0033'} />
                    </div>
                    <img
                      alt="coverPhoto"
                      style={{ height: '250px', width: '450px', display: 'flex', alignSelf: 'center' }}
                      src={previewImage}
                    />
                  </div>
                </div>
              )}

              <div>
                <GoogleMap setAddress={setAddress} setLat={setLat} setLong={setLong} />
              </div>
              <div className="modal__content__button__container">
                <Button
                  title="Cancel"
                  onClick={() => {
                    modalRef.current.hide();
                    setImage(null);
                    setSchedule({});
                    setAddress(null);
                    setSelectedProperty(0);
                    setPreviewImage(null);
                    setLong(0);
                    setLat(0);
                  }}
                  style={{ marginRight: '2rem' }}
                  className="modal__content__button__container-btn"
                />
                <Button
                  title="Confirm"
                  onClick={() => {
                    if (formValid()) {
                      let formData = new FormData();

                      if (image !== null) {
                        formData.append('file', image);
                      }
                      if (address !== null) {
                        formData.append('location', address);
                      }
                      if (businessName !== null) {
                        formData.append('name', businessName);
                      }
                      if (businessType !== null) {
                        formData.append('type', businessType);
                      }
                      if (websiteLink !== null) {
                        formData.append('websiteLink', websiteLink);
                      }
                      if (discountCode !== null) {
                        formData.append('discountCode', discountCode);
                      }
                      if (discountCodeOffer !== '') {
                        formData.append('discountCodeOffer', discountCodeOffer);
                      }
                      if (selectedProperty !== 0) {
                        formData.append('propertyId', selectedProperty);
                      }
                      if (Object.keys(schedule).length !== 0) {
                        formData.append('hoursOfOperation', JSON.stringify(schedule));
                      }
                      if (lat) {
                        formData.append('lat', lat);
                      }
                      if (long) {
                        formData.append('long', long);
                      }
                      dispatch(addNewBusiness(formData));
                    }
                  }}
                  className="modal__content__button__container-btn"
                />
              </div>
            </>
          ) : (
            <div>
              <div className="modal__content__loader__container">
                <Loader className="modal__content__loader__container-loader" />
              </div>
            </div>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default LocalBusinessScreen;
