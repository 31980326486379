import { LOGIN_ATTEMPT, LOGIN_ERROR, LOGIN_RESET, LOGIN_SUCCESS, LOGOUT, SET_USERS_ALL_INFO } from '../constants';
import authService from '../../api/services/auth';
import localStorageService from '../../services/localStorageService';
import { LOCAL_STORAGE_ACCESS_TOKEN, LOCAL_STORAGE_REFRESH_TOKEN } from '../../utils/constants';

export const login = (email, password) => {
  return async dispatch => {
    try {
      dispatch({ type: LOGIN_ATTEMPT });
      const { accessToken, refreshToken, user } = await authService.login(email, password);
      localStorageService.setItem(LOCAL_STORAGE_ACCESS_TOKEN, accessToken);
      localStorageService.setItem(LOCAL_STORAGE_REFRESH_TOKEN, refreshToken);
      dispatch({ type: SET_USERS_ALL_INFO, payload: user });
      dispatch({ type: LOGIN_SUCCESS });
    } catch (err) {
      console.log(err);
      dispatch({ type: LOGIN_ERROR, payload: err });
    }
  };
};

export const loginReset = () => ({ type: LOGIN_RESET });

export const logout = () => {
  return async dispatch => {
    try {
      localStorageService.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
      localStorageService.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);
      dispatch({ type: LOGOUT });
      await authService.logout(localStorageService.getItem(LOCAL_STORAGE_REFRESH_TOKEN));
    } catch (err) {
      console.log('error: ', err);
    }
  };
};
