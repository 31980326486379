import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { formatDate } from '../../utils/util';
import { Modal, Button, Input } from '../../components';
import { ReactComponent as Loader } from '../../assets/icons/loader.svg';
import { useDispatch, useSelector } from 'react-redux';
import PropertiesService from '../../api/services/properties';
import { toast } from 'react-toastify';
import Select from 'react-select';
import {
  deleteAdmin,
  deleteAdminReset,
  getAdminById,
  getAdminByIdReset,
  updateAdmin,
  updateAdminReset,
} from '../../store/actions/admin';

const AdminDetailsScreen = () => {
  const { id } = useParams();
  const getAdminByIdStatus = useSelector(state => state.admin.adminInfo.getAdminByIdStatus);
  const updateAdminStatus = useSelector(state => state.admin.adminInfo.updateAdminStatus);
  const deleteAdminStatus = useSelector(state => state.admin.adminInfo.deleteAdminStatus);
  const admin = useSelector(state => state.admin.adminInfo.selectedAdmin);
  const [adminEmail, setAdminEmail] = useState(null);
  const [adminRole, setAdminRole] = useState(null);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [properties, setProperties] = useState([]);
  const dispatch = useDispatch();
  const deleteModalRef = useRef();
  const updateModalRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAdminById(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (getAdminByIdStatus.success) {
      dispatch(getAdminByIdReset());
      setAdminEmail(admin.email);
      if (admin?.properties.length) {
        setSelectedProperties(
          admin?.properties.map(property => {
            return { id: property.id, name: property.name };
          }),
        );
      }
      setAdminRole(admin.role);
    }
    if (getAdminByIdStatus.error) {
      dispatch(getAdminByIdReset());
    }
  }, [dispatch, getAdminByIdStatus.success, getAdminByIdStatus.error, admin]);

  useEffect(() => {
    if (deleteAdminStatus.success) {
      dispatch(deleteAdminReset());
      deleteModalRef.current.hide();
      toast.success('Succesfully deleted admin');
      navigate({ pathname: '/admins', search: `?page=${1}` });
    }
    if (deleteAdminStatus.error) {
      dispatch(deleteAdminReset());
      deleteModalRef.current.hide();
      toast.error('Error occured while deleting admin');
    }
  }, [dispatch, deleteAdminStatus.success, deleteAdminStatus.error, navigate]);

  useEffect(() => {
    if (updateAdminStatus.success) {
      dispatch(updateAdminReset());
      updateModalRef.current.hide();
      toast.success('Succesfully updated admin');
    }
    if (updateAdminStatus.error) {
      dispatch(updateAdminReset());
      updateModalRef.current.hide();
      toast.error('Error occured while updating admin');
    }
  }, [dispatch, updateAdminStatus.success, updateAdminStatus.error]);

  const isFormValid = () => {
    if (!adminEmail) {
      toast.warning('Please add admin email');
      return false;
    }
    if (!adminRole) {
      toast.warning('Please add admin role');
      return false;
    }
    if (selectedProperties.length === 0 && adminRole === 'PROPERTY_ADMIN') {
      toast.warning('Please add property to admin');
      return false;
    }
    return true;
  };

  const roles = [
    { id: 1, name: 'SUPER_ADMIN' },
    { id: 2, name: 'PROPERTY_ADMIN' },
    { id: 3, name: 'MARKETING_ADMIN' },
  ];

  const handlePropertiesChange = selectedOptions => {
    setSelectedProperties(selectedOptions);
    if (selectedOptions.length === 0) {
      setSelectedProperties(selectedOptions);
    }
  };

  const handleRoleChange = event => {
    setAdminRole(event.target.value);
  };

  return (
    <div className="admin__details__screen">
      {admin && (
        <div className="admin__details__screen__content">
          <div className="admin__details__screen__content__container">
            <div className="admin__details__screen__content__container__box left">
              <div className="admin__details__screen__content__container__box-description">
                <h2>
                  <b>Admin</b>
                </h2>
                <p>
                  <b>Email:</b> {admin.email}
                </p>
                <p>
                  <b>Role:</b> {admin.role}
                </p>
                <p>
                  <b>Properties:</b> {admin.properties?.map(property => `${property.name}`).join(', ')}
                </p>
                <p>
                  <b>Created: </b>
                  {admin.createdAt ? formatDate(admin.createdAt) : ''}
                </p>
              </div>
              <div className="admin__details__screen__content__container__box__column">
                <Button
                  style={{ marginBottom: '1rem' }}
                  title="Update"
                  onClick={async () => {
                    const response = await PropertiesService.getPropertiesForBusiness();
                    setProperties(response);
                    updateModalRef.current.show();
                  }}
                />

                <Button
                  title="Delete"
                  onClick={() => {
                    deleteModalRef.current.show();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        ref={deleteModalRef}
        onClose={() => deleteModalRef.current.hide()}
        title={`${admin.email}`}
        maxWidth="500px">
        {!deleteAdminStatus.attempt ? (
          <>
            <p className="modal__content-text">Are you sure you want to delete this admin?</p>
            <div className="modal__content__button__container">
              <Button
                title="No"
                onClick={() => deleteModalRef.current.hide()}
                style={{ marginRight: '2rem' }}
                className="modal__content__button__container-btn"
              />
              <Button
                title="Yes"
                onClick={() => {
                  dispatch(deleteAdmin(admin.id));
                }}
                className="modal__content__button__container-btn"
              />
            </div>
          </>
        ) : (
          <div>
            <div className="modal__content__loader__container">
              <p className="modal__content__loader__container-text">Deleting admin...</p>
              <Loader className="modal__content__loader__container-loader" />
            </div>
          </div>
        )}
      </Modal>
      <Modal
        ref={updateModalRef}
        onClose={() => {
          updateModalRef.current.hide();
        }}
        title={'Update Admin'}
        maxWidth="500px">
        {!updateAdminStatus.attempt ? (
          <>
            <div className="select-container">
              <div
                style={{
                  width: '100%',
                  gap: 10,
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                }}>
                <span htmlFor="properties">Properties:</span>
                <div style={{ flex: 1 }}>
                  <Select
                    id="properties"
                    name="properties"
                    isMulti
                    options={properties}
                    value={selectedProperties}
                    onChange={handlePropertiesChange}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                  />
                </div>
              </div>
            </div>
            <div style={{ marginBottom: '2rem' }} className="property-dropdown">
              <label htmlFor="property-select" className="label">
                Role:
              </label>
              <select id="property-select" value={adminRole} onChange={handleRoleChange}>
                <option value="">Select a Role</option>
                {roles.map(role => (
                  <option key={role.id} value={role.name}>
                    {role.name}
                  </option>
                ))}
              </select>
            </div>
            <Input value={adminEmail} type="text" onChange={setAdminEmail} placeholder="Email" />
            <div className="modal__content__button__container">
              <Button
                title="Cancel"
                onClick={() => updateModalRef.current.hide()}
                style={{ marginRight: '2rem' }}
                className="modal__content__button__container-btn"
              />
              <Button
                title="Confirm"
                onClick={() => {
                  if (isFormValid()) {
                    dispatch(
                      updateAdmin(
                        {
                          email: adminEmail,
                          role: adminRole,
                          propertyIds: [...selectedProperties.map(property => property.id)],
                        },
                        admin.id,
                      ),
                    );
                  }
                }}
                className="modal__content__button__container-btn"
              />
            </div>
          </>
        ) : (
          <div>
            <div className="modal__content__loader__container">
              <p className="modal__content__loader__container-text">Update in progress...</p>
              <Loader className="modal__content__loader__container-loader" />
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default AdminDetailsScreen;
