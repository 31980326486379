import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import propertyService from '../../api/services/properties';
import PropertyCard from './PropertyCard';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Modal, MultilineInput } from '../../components';
import { sendNotifications, sendNotificationsReset } from '../../store/actions/notification';
import { toast } from 'react-toastify';
import { ReactComponent as Loader } from '../../assets/icons/loader.svg';

const NotificationScreen = () => {
  const [properties, setProperties] = useState([]);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [units, setUnits] = useState({});
  const [selectedUnits, setSelectedUnits] = useState({});
  const [floors, setFloors] = useState({});
  const [selectedFloors, setSelectedFloors] = useState({});
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationDescription, setNotificationDescription] = useState('');
  const [notificationType, setNotificationType] = useState([]);
  const notificationInfo = useSelector(state => state.notification.notificationInfo);
  const modalRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchProperties() {
      try {
        const response = await propertyService.getPropertiesForNotifications();
        setProperties(response);
      } catch (error) {
        console.log(error.message);
      }
    }
    fetchProperties();
  }, []);

  useEffect(() => {
    if (notificationInfo.success) {
      dispatch(sendNotificationsReset());
      setNotificationTitle('');
      setNotificationDescription('');
      setSelectedProperties([]);
      setSelectedFloors({});
      setSelectedUnits({});
      modalRef.current.hide();
      toast.success('Succesfully send Notification');
    }
    if (notificationInfo.error) {
      dispatch(sendNotificationsReset());
      modalRef.current.hide();
      toast.error('Error occured while sending notification');
    }
  }, [dispatch, notificationInfo.success, notificationInfo.error]);

  const handlePropertiesChange = selectedOptions => {
    let newFloors = {};
    for (const option of selectedOptions) {
      newFloors[option.id] = Object.values(option.groupedPropertyUnitsByFloor).map(v => {
        return {
          floorNumber: v.floorNumber,
          propertyUnits: v.propertyUnits,
          propertyId: option.id,
          floorId: v.floorId,
        };
      });
    }

    setSelectedProperties(selectedOptions);
    setFloors(newFloors);
    if (selectedOptions.length === 0) {
      setSelectedProperties(selectedOptions);
      setSelectedUnits({});
      setSelectedFloors({});
    }
  };

  const handleFloorsChange = (selectedOptions, id) => {
    let newUnits = {};

    const propertyUnits = [];
    for (const option of selectedOptions) {
      propertyUnits.push(
        ...Object.values(option.propertyUnits).map(v => {
          return { ...v, propertyId: id };
        }),
      );
    }

    if (selectedUnits[id]) {
      for (const selectedUnit of selectedUnits[id]) {
        if (!propertyUnits.find(pu => pu.id === selectedUnit.id)) {
          setSelectedUnits(prev => ({ ...prev, [id]: prev[id].filter(p => p.id !== selectedUnit.id) }));
        }
      }
    }

    newUnits[id] = propertyUnits;

    const newGlobalUnits = { ...units };

    newGlobalUnits[id] = newUnits[id];

    let newSelectedFloors = { ...selectedFloors };
    newSelectedFloors[id] = selectedOptions;

    setSelectedFloors(prevFloors => ({
      ...prevFloors,
      ...newSelectedFloors,
    }));

    setUnits(newGlobalUnits);
  };

  const handleUnitsChange = (selectedOptions, id) => {
    let newSelectedUnits = { ...selectedUnits };

    newSelectedUnits[id] = selectedOptions;

    setSelectedUnits(prevUnits => ({
      ...prevUnits,
      ...newSelectedUnits,
    }));
  };

  const handleNotificationTypeChange = type => {
    if (notificationType.includes(type)) {
      setNotificationType(notificationType.filter(t => t !== type));
    } else {
      setNotificationType([...notificationType, type]);
    }
  };

  const handleSubmit = () => {
    let ids;

    if (Object.keys(selectedUnits).length) {
      ids = Object.values(selectedUnits).flatMap(units => {
        return units.map(unit => unit.id);
      });
      const propertyIds = selectedProperties.map(property => property.id);
      dispatch(
        sendNotifications(
          notificationType,
          notificationTitle,
          notificationDescription,
          ids,
          'propertyUnitIds',
          propertyIds,
        ),
      );
    } else if (Object.keys(selectedFloors).length) {
      ids = Object.values(selectedFloors).flatMap(floors => {
        return floors.map(floor => floor.floorId);
      });
      const propertyIds = selectedProperties.map(property => property.id);
      dispatch(
        sendNotifications(notificationType, notificationTitle, notificationDescription, ids, 'floorIds', propertyIds),
      );
    } else if (selectedProperties.length) {
      ids = selectedProperties.map(property => property.id);
      dispatch(sendNotifications(notificationType, notificationTitle, notificationDescription, ids, 'propertyIds'));
    }
  };

  return (
    <div className="notification__screen">
      <div className="notification-container">
        <div className="notification-container-input">
          <Input
            value={notificationTitle}
            type="text"
            onChange={setNotificationTitle}
            placeholder="Notification Title"
          />
          <MultilineInput
            value={notificationDescription}
            onChange={setNotificationDescription}
            placeholder={'Notification Description'}
          />
          <div className="notification-container-input-checkbox_container">
            <input className="checkbox" type="checkbox" onChange={() => handleNotificationTypeChange('in-app')} />
            <label>In-App</label>
            <input className="checkbox" type="checkbox" onChange={() => handleNotificationTypeChange('push')} />
            <label>Push</label>
            <input className="checkbox" type="checkbox" onChange={() => handleNotificationTypeChange('sms')} />
            <label>SMS</label>
          </div>

          <Button
            title="Send"
            onClick={() => modalRef.current.show()}
            className=""
            disabled={
              selectedProperties.length === 0 ||
              notificationTitle === '' ||
              notificationDescription === '' ||
              notificationType.length === 0 ||
              notificationInfo.attempt
            }
          />
        </div>
        <div className="select-container" style={{ marginLeft: 400 }}>
          <p htmlFor="properties">Properties</p>
          <Select
            id="properties"
            name="properties"
            isMulti
            options={properties}
            value={selectedProperties}
            onChange={handlePropertiesChange}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
          />
        </div>
        {selectedProperties.length > 0 && (
          <div className="property-container">
            {selectedProperties.map(property => (
              <PropertyCard
                key={property.id}
                property={property}
                floors={floors}
                units={units}
                selectedFloors={selectedFloors}
                handleFloorsChange={handleFloorsChange}
                selectedUnits={selectedUnits}
                handleUnitsChange={handleUnitsChange}
              />
            ))}
          </div>
        )}
      </div>
      <Modal ref={modalRef} onClose={() => modalRef.current.hide()} title="Send notification" maxWidth="500px">
        {!notificationInfo.attempt ? (
          <>
            <p className="modal__content-text">Are you sure you want to send a notification?</p>
            <div className="modal__content__button__container">
              <Button
                title="No"
                onClick={() => modalRef.current.hide()}
                style={{ marginRight: '2rem' }}
                className="modal__content__button__container-btn"
              />
              <Button title="Yes" onClick={handleSubmit} className="modal__content__button__container-btn" />
            </div>
          </>
        ) : (
          <div>
            <div className="modal__content__loader__container">
              <p className="modal__content__loader__container-text">Sending notification...</p>
              <Loader className="modal__content__loader__container-loader" />
            </div>
            <p className="modal__content__loader__container-small-text">Please wait. This may take a while</p>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default NotificationScreen;
