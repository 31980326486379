import issueService from '../../api/services/issue';
import {
  ADD_ISSUE_ATTEMPT,
  ADD_ISSUE_SUCCESS,
  ADD_ISSUE_ERROR,
  ADD_ISSUE_RESET,
  GET_ISSUE_BY_ID_ATTEMPT,
  GET_ISSUE_BY_ID_SUCCESS,
  GET_ISSUE_BY_ID_ERROR,
  GET_ISSUE_BY_ID_RESET,
  DELETE_ISSUE_ATTEMPT,
  DELETE_ISSUE_SUCCESS,
  DELETE_ISSUE_ERROR,
  DELETE_ISSUE_RESET,
  UUPDATE_ISSUE_ATTEMPT,
  UPDATE_ISSUE_SUCCESS,
  UPDATE_ISSUE_ERROR,
  UPDATE_ISSUE_RESET,
} from '../constants';

export const addNewIssue = formData => {
  return async (dispatch, getState) => {
    try {
      const { issue } = getState();
      if (!issue.issueInfo.attempt) {
        dispatch({ type: ADD_ISSUE_ATTEMPT });

        const response = await issueService.addNewIssue(formData);
        dispatch({
          type: ADD_ISSUE_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({ type: ADD_ISSUE_ERROR, payload: error });
      console.log(error);
    }
  };
};

export const getIssueById = id => {
  return async (dispatch, getState) => {
    try {
      const { issue } = getState();
      if (!issue.issueInfo.getIssueByIdStatus.attempt) {
        dispatch({ type: GET_ISSUE_BY_ID_ATTEMPT });

        const response = await issueService.getIssueById(id);
        dispatch({
          type: GET_ISSUE_BY_ID_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({ type: GET_ISSUE_BY_ID_ERROR, payload: error });
      console.log(error);
    }
  };
};

export const deleteIssue = id => {
  return async (dispatch, getState) => {
    try {
      const { issue } = getState();
      if (!issue.issueInfo.deleteIssueStatus.attempt) {
        dispatch({ type: DELETE_ISSUE_ATTEMPT });

        const response = await issueService.deleteIssue(id);
        dispatch({
          type: DELETE_ISSUE_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({ type: DELETE_ISSUE_ERROR, payload: error });
      console.log(error);
    }
  };
};

export const updateIssue = (formData, id) => {
  return async (dispatch, getState) => {
    try {
      const { issue } = getState();
      if (!issue.issueInfo.updateIssueStatus.attempt) {
        dispatch({ type: UUPDATE_ISSUE_ATTEMPT });

        const response = await issueService.updateIssue(formData, id);
        dispatch({
          type: UPDATE_ISSUE_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({ type: UPDATE_ISSUE_ERROR, payload: error });
      console.log(error);
    }
  };
};

export const addNewIssueReset = () => ({ type: ADD_ISSUE_RESET });

export const getIssueByIdReset = () => ({ type: GET_ISSUE_BY_ID_RESET });

export const deleteIssueReset = () => ({ type: DELETE_ISSUE_RESET });

export const updateIssueReset = () => ({ type: UPDATE_ISSUE_RESET });
