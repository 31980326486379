import {
  GET_ITEMS_ATTEMPT,
  GET_ITEMS_SUCCESS,
  GET_ITEMS_ERROR,
  GET_ITEMS_RESET,
  GET_ITEM_BY_ID_ATEMPT,
  GET_ITEM_BY_ID_SUCCESS,
  GET_ITEM_BY_ID_ERROR,
  GET_ITEM_BY_ID_RESET,
  APPROVE_ITEM_BY_ID_ATTEMPT,
  APPROVE_ITEM_BY_ID_SUCCESS,
  APPROVE_ITEM_BY_ID_ERROR,
  APPROVE_ITEM_BY_ID_RESET,
  DELETE_ITEM_BY_ID_ATTEMPT,
  DELETE_ITEM_BY_ID_SUCCESS,
  DELETE_ITEM_BY_ID_ERROR,
  DELETE_ITEM_BY_ID_RESET,
} from '../constants';

const INIT_STATE = {
  getItemsInfo: {
    attempt: false,
    success: false,
    error: null,
    data: [],
    perPage: 0,
    total: 0,
  },
  approveItemStatus: {
    attempt: false,
    success: false,
    error: null,
  },
  getSelectedItemInfo: {
    selectedItem: {},
    attempt: false,
    success: false,
    error: null,
  },
  deleteItemStatus: {
    attempt: false,
    success: false,
    error: null,
  },
};

const marketplaceReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ITEMS_ATTEMPT:
      return {
        ...state,
        getItemsInfo: { ...state.getItemsInfo, attempt: true, success: false, error: null },
      };
    case GET_ITEMS_SUCCESS:
      return {
        ...state,
        getItemsInfo: {
          data: [...action.payload.data],
          perPage: +action.payload.perPage,
          total: +action.payload.total,
          attempt: false,
          success: true,
          error: null,
        },
      };
    case GET_ITEMS_ERROR:
      return {
        ...state,
        getItemsInfo: { ...state.getItemsInfo, attempt: false, success: false, error: action.payload },
      };
    case GET_ITEMS_RESET:
      return {
        ...state,
        getItemsInfo: {
          ...state.getItemsInfo,
          attempt: false,
          success: false,
          error: null,
        },
      };
    case GET_ITEM_BY_ID_ATEMPT:
      return {
        ...state,
        getSelectedItemInfo: { attempt: true, success: false, error: null },
      };
    case GET_ITEM_BY_ID_SUCCESS:
      return {
        ...state,
        getSelectedItemInfo: { selectedItem: action.payload, attempt: false, success: true, error: null },
      };
    case GET_ITEM_BY_ID_ERROR:
      return {
        ...state,
        getSelectedItemInfo: { attempt: false, success: false, error: action.payload },
      };
    case GET_ITEM_BY_ID_RESET:
      return {
        ...state,
        getSelectedItemInfo: { ...state.getSelectedItemInfo, attempt: false, success: false, error: null },
      };
    case APPROVE_ITEM_BY_ID_ATTEMPT:
      return {
        ...state,
        approveItemStatus: { attempt: true, success: false, error: null },
      };
    case APPROVE_ITEM_BY_ID_SUCCESS:
      return {
        ...state,
        getSelectedItemInfo: {
          ...state.getSelectedItemInfo,
          selectedItem: action.payload,
        },
        approveItemStatus: { attempt: false, success: true, error: null },
      };
    case APPROVE_ITEM_BY_ID_ERROR:
      return {
        ...state,
        approveItemStatus: { attempt: false, success: false, error: action.payload },
      };
    case APPROVE_ITEM_BY_ID_RESET:
      return {
        ...state,
        approveItemStatus: { attempt: false, success: false, error: null },
      };
    case DELETE_ITEM_BY_ID_ATTEMPT:
      return {
        ...state,
        deleteItemStatus: { attempt: true, success: false, error: null },
      };
    case DELETE_ITEM_BY_ID_SUCCESS:
      return {
        ...state,
        getSelectedItemInfo: { ...state.getSelectedItemInfo, selectedItem: {} },
        deleteItemStatus: { attempt: false, success: true, error: null },
      };
    case DELETE_ITEM_BY_ID_ERROR:
      return {
        ...state,
        deleteItemStatus: { attempt: false, success: false, error: action.payload },
      };
    case DELETE_ITEM_BY_ID_RESET:
      return {
        ...state,
        deleteItemStatus: { attempt: false, success: false, error: null },
      };
    default:
      return state;
  }
};

export default marketplaceReducer;
