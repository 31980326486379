import userService from '../../api/services/user';
import {
  FETCH_USER_INFO_ERROR,
  FETCH_USER_INFO_SUCCESS,
  SET_USERS_ALL_INFO,
  TOGGLE_DELETE_USER_ATTEMPT,
  TOGGLE_DELETE_USER_SUCCESS,
  TOGGLE_DELETE_USER_ERROR,
  TOGGLE_DELETE_USER_RESET,
} from '../constants';

export const fetchUserInfo = () => {
  return async dispatch => {
    try {
      const user = await userService.fetchUserInfo();

      dispatch({ type: SET_USERS_ALL_INFO, payload: user });
      dispatch({ type: FETCH_USER_INFO_SUCCESS, payload: user });
    } catch (err) {
      dispatch({ type: FETCH_USER_INFO_ERROR, payload: err });
    }
  };
};

export const toggleDeletedAtUser = userExternalId => {
  return async dispatch => {
    try {
      dispatch({ type: TOGGLE_DELETE_USER_ATTEMPT });
      await userService.toggleDeleteUser(userExternalId);
      dispatch({ type: TOGGLE_DELETE_USER_SUCCESS, payload: userExternalId });
    } catch (err) {
      dispatch({ type: TOGGLE_DELETE_USER_ERROR, payload: err });
    }
  };
};

export const toggleDeletedAtUserReset = () => ({ type: TOGGLE_DELETE_USER_RESET });
