import React, { useState, useEffect, useRef } from 'react';
import { Input, Button, Modal } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import propertyService from '../../api/services/properties';
import {
  addNewCluster,
  addNewClusterReset,
  deleteClusterReset,
  getAllClusters,
  getAllClustersReset,
} from '../../store/actions/cluster';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ColumnComponent from './ColumnComponent';
import {
  addNewPropertyCluster,
  addNewPropertyClusterReset,
  deletePropertyClusterReset,
} from '../../store/actions/propertyCluster';
import { ReactComponent as Loader } from '../../assets/icons/loader.svg';
import { toast } from 'react-toastify';
import { deleteCluster } from '../../store/actions/cluster';
import { deletePropertyCluster } from '../../store/actions/propertyCluster';
const { v4: uuidv4 } = require('uuid');

const ClusterScreen = () => {
  const [clusterName, setClusterName] = useState('');
  const [properties, setProperties] = useState();
  const clusters = useSelector(state => state.cluster.clusterInfo.data);
  const addClusterStatus = useSelector(state => state.cluster.clusterInfo.addClusterStatus);
  const deleteClusterStatus = useSelector(state => state.cluster.clusterInfo.deleteClusterStatus);
  const getAllClusterStatus = useSelector(state => state.cluster.clusterInfo.getAllClustersStatus);
  const addPropertyClusterStatus = useSelector(state => state.cluster.propertyClusterInfo.addPropertyClusterStatus);
  const deletePropertyClusterStatus = useSelector(
    state => state.cluster.propertyClusterInfo.deletePropertyClusterStatus,
  );
  const [columns, setColumns] = useState([]);
  const [clusterIdForDeleting, setClusterIdForDeleting] = useState(0);
  const [propertyIdForDeleting, setPropertyIdForDeleting] = useState(0);
  const deletePropertyModalRef = useRef();
  const deleteClusterModalRef = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    setColumns(
      clusters.map(cluster => {
        return {
          ...cluster,
          properties: [
            ...cluster.properties.map(property => {
              return { ...property, newId: uuidv4() };
            }),
          ],
        };
      }),
    );
  }, [clusters]);

  useEffect(() => {
    dispatch(getAllClusters());
  }, [dispatch]);

  useEffect(() => {
    if (addClusterStatus.success) {
      dispatch(addNewClusterReset());
      toast.success('Successfully added new cluster');
    }
    if (addClusterStatus.error) {
      dispatch(addNewClusterReset());
      toast.error('Error while adding new cluster');
    }
  }, [dispatch, addClusterStatus.success, addClusterStatus.error]);

  useEffect(() => {
    if (getAllClusterStatus.success) {
      dispatch(getAllClustersReset());
    }
    if (getAllClusterStatus.error) {
      dispatch(getAllClustersReset());
    }
  }, [dispatch, getAllClusterStatus.success, getAllClusterStatus.error]);

  useEffect(() => {
    if (deleteClusterStatus.success) {
      dispatch(deleteClusterReset());
      setClusterIdForDeleting(0);
      deleteClusterModalRef.current.hide();
      toast.success('Successfully deleted a cluster');
    }
    if (deleteClusterStatus.error) {
      dispatch(deleteClusterReset());
      setClusterIdForDeleting(0);
      deleteClusterModalRef.current.hide();
      toast.error('Error while deleting a cluster');
    }
  }, [dispatch, deleteClusterStatus.success, deleteClusterStatus.error]);

  useEffect(() => {
    if (deletePropertyClusterStatus.success) {
      dispatch(deletePropertyClusterReset());
      setClusterIdForDeleting(0);
      setPropertyIdForDeleting(0);
      deletePropertyModalRef.current.hide();
      toast.success('Successfully deleted property from cluster');
    }
    if (deletePropertyClusterStatus.error) {
      dispatch(deletePropertyClusterReset());
      setClusterIdForDeleting(0);
      setPropertyIdForDeleting(0);
      deletePropertyModalRef.current.hide();
      toast.error('Error while deleting property from cluster');
    }
  }, [dispatch, deletePropertyClusterStatus.success, deletePropertyClusterStatus.error]);

  useEffect(() => {
    if (addPropertyClusterStatus.success) {
      dispatch(addNewPropertyClusterReset());
      toast.success('Successfully added property to cluster');
    }
    if (addPropertyClusterStatus.error) {
      dispatch(addNewPropertyClusterReset());
      toast.error(addPropertyClusterStatus.error);
    }
  }, [dispatch, addPropertyClusterStatus.success, addPropertyClusterStatus.error]);

  useEffect(() => {
    async function fetchProperties() {
      try {
        const response = await propertyService.getPropertiesForNotifications();

        setProperties(
          response.map(property => {
            return { ...property, newId: uuidv4() };
          }),
        );
      } catch (error) {
        console.log(error.message);
      }
    }
    fetchProperties();
  }, []);

  const onDragEnd = result => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    switch (destination.droppableId) {
      case destination.droppableId:
        for (const column of columns) {
          if (+destination.droppableId === column.id) {
            dispatch(addNewPropertyCluster(properties[source.index].id, column.id));
          }
        }
        break;
      case 'PROPERTIES':
        break;
      default:
        break;
    }
  };

  return (
    <div className="cluster__screen">
      <div className="cluster__screen__createContainer">
        <Input value={clusterName} type="text" onChange={setClusterName} placeholder="Cluster name" />
        <Button
          title="Create"
          onClick={() => {
            dispatch(addNewCluster(clusterName));
            setClusterName('');
          }}
          style={{ marginLeft: '1rem', width: '24rem', marginTop: '-2rem' }}
        />
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="columns">
          <Droppable droppableId="PROPERTIES" isDropDisabled={true}>
            {(provided, snapshot) => (
              <div
                className="propertyColumn"
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}>
                {properties &&
                  properties.map((item, index) => (
                    <Draggable key={item.newId} draggableId={item.newId.toString()} index={index}>
                      {(provided, snapshot) => (
                        <React.Fragment>
                          <div
                            className="item"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}>
                            {item.name}
                          </div>
                          {snapshot.isDragging && <div className="clone">{item.name}</div>}
                        </React.Fragment>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>

          <div className="rowContainer">
            {columns.map(col => (
              <ColumnComponent
                col={col}
                key={col.id}
                deleteClusterModalRef={deleteClusterModalRef}
                setClusterIdForDeleting={setClusterIdForDeleting}
                deletePropertyModalRef={deletePropertyModalRef}
                setPropertyIdForDeleting={setPropertyIdForDeleting}
              />
            ))}
          </div>
        </div>
      </DragDropContext>
      <Modal
        ref={deleteClusterModalRef}
        onClose={() => deleteClusterModalRef.current.hide()}
        title={'Delete Cluster'}
        maxWidth="500px">
        {!deleteClusterStatus.attempt ? (
          <>
            <p className="modal__content-text">Are you sure you want to delete this cluster?</p>
            <div className="modal__content__button__container">
              <Button
                title="No"
                onClick={() => deleteClusterModalRef.current.hide()}
                style={{ marginRight: '2rem' }}
                className="modal__content__button__container-btn"
              />
              <Button
                title="Yes"
                onClick={() => {
                  dispatch(deleteCluster(clusterIdForDeleting));
                }}
                className="modal__content__button__container-btn"
              />
            </div>
          </>
        ) : (
          <div>
            <div className="modal__content__loader__container">
              <p className="modal__content__loader__container-text">Deleting in progress...</p>
              <Loader className="modal__content__loader__container-loader" />
            </div>
          </div>
        )}
      </Modal>
      <Modal
        ref={deletePropertyModalRef}
        onClose={() => deletePropertyModalRef.current.hide()}
        title={'Delete Property from Cluster'}
        maxWidth="500px">
        {!deletePropertyClusterStatus.attempt ? (
          <>
            <p className="modal__content-text">Are you sure you want to delete this property from cluster?</p>
            <div className="modal__content__button__container">
              <Button
                title="No"
                onClick={() => deletePropertyModalRef.current.hide()}
                style={{ marginRight: '2rem' }}
                className="modal__content__button__container-btn"
              />
              <Button
                title="Yes"
                onClick={() => {
                  dispatch(deletePropertyCluster(clusterIdForDeleting, propertyIdForDeleting));
                }}
                className="modal__content__button__container-btn"
              />
            </div>
          </>
        ) : (
          <div>
            <div className="modal__content__loader__container">
              <p className="modal__content__loader__container-text">Deleting in progress...</p>
              <Loader className="modal__content__loader__container-loader" />
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ClusterScreen;
