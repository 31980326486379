import axiosApiClient from '..';
import { handleError } from '../../utils/errors';

const BASE_SERVICE_URL = '/social-networks';

class commonService {
  getAllSocialNetworks = async () => {
    try {
      const response = await axiosApiClient.get(`${BASE_SERVICE_URL}/admin`);
      if (response.status !== 200) {
        throw new Error();
      }

      return response.data;
    } catch (err) {
      console.log(err);
      throw handleError(err, 'Error occured while fetching social networks.');
    }
  };
  createOrUpdateSocialAccounts = async (data, propertyId) => {
    try {
      const response = await axiosApiClient.post(`${BASE_SERVICE_URL}/${propertyId}`, data);
      if (response.status !== 200) {
        throw new Error();
      }
      return response.data;
    } catch (error) {
      console.log(error);
      throw handleError(error, 'Error occured while saving social accounts.');
    }
  };
}

export default new commonService();
