import businessService from '../../api/services/business';
import {
  ADD_BUSINESS_ATTEMPT,
  ADD_BUSINESS_SUCCESS,
  ADD_BUSINESS_ERROR,
  ADD_BUSINESS_RESET,
  GET_BUSINESS_BY_ID_ATTEMPT,
  GET_BUSINESS_BY_ID_SUCCESS,
  GET_BUSINESS_BY_ID_ERROR,
  GET_BUSINESS_BY_ID_RESET,
  DELETE_BUSINESS_BY_ID_ATTEMPT,
  DELETE_BUSINESS_BY_ID_SUCCESS,
  DELETE_BUSINESS_BY_ID_ERROR,
  DELETE_BUSINESS_BY_ID_RESET,
  UPDATE_BUSINESS_BY_ID_ATTEMPT,
  UPDATE_BUSINESS_BY_ID_SUCCESS,
  UPDATE_BUSINESS_BY_ID_ERROR,
  UPDATE_BUSINESS_BY_ID_RESET,
} from '../constants';

export const addNewBusiness = formData => {
  return async (dispatch, getState) => {
    try {
      const { business } = getState();
      if (!business.businessInfo.attempt) {
        dispatch({ type: ADD_BUSINESS_ATTEMPT });

        const response = await businessService.addNewBusiness(formData);
        dispatch({
          type: ADD_BUSINESS_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({ type: ADD_BUSINESS_ERROR, payload: error });
      console.log(error);
    }
  };
};

export const deleteBusiness = id => {
  return async (dispatch, getState) => {
    try {
      const { business } = getState();
      if (!business.businessInfo.deleteBusinessStatus.attempt) {
        dispatch({ type: DELETE_BUSINESS_BY_ID_ATTEMPT });

        const response = await businessService.deleteBusinessById(id);
        dispatch({
          type: DELETE_BUSINESS_BY_ID_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({ type: DELETE_BUSINESS_BY_ID_ERROR, payload: error });
      console.log(error);
    }
  };
};

export const updateBusiness = (formData, id) => {
  return async (dispatch, getState) => {
    try {
      const { business } = getState();
      if (!business.businessInfo.updateBusinessStatus.attempt) {
        dispatch({ type: UPDATE_BUSINESS_BY_ID_ATTEMPT });

        const response = await businessService.updateBusiness(formData, id);
        dispatch({
          type: UPDATE_BUSINESS_BY_ID_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({ type: UPDATE_BUSINESS_BY_ID_ERROR, payload: error });
      console.log(error);
    }
  };
};

export const getBusinessById = id => {
  return async (dispatch, getState) => {
    try {
      const { business } = getState();
      if (!business.businessInfo.getBusinessByIdStatus.attempt) {
        dispatch({ type: GET_BUSINESS_BY_ID_ATTEMPT });

        const response = await businessService.getBusinessById(id);
        dispatch({
          type: GET_BUSINESS_BY_ID_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({ type: GET_BUSINESS_BY_ID_ERROR, payload: error });
      console.log(error);
    }
  };
};

export const getBusinessByIdReset = () => ({ type: GET_BUSINESS_BY_ID_RESET });

export const deleteBusinessReset = () => ({ type: DELETE_BUSINESS_BY_ID_RESET });

export const addNewBusinessReset = () => ({ type: ADD_BUSINESS_RESET });

export const updateBusinessReset = () => ({ type: UPDATE_BUSINESS_BY_ID_RESET });
