import React from 'react';
import ItemComponent from './ItemComponent';
import { Droppable } from 'react-beautiful-dnd';
import { ReactComponent as CancelIcon } from '../../assets/icons/cancelButton.svg';

const ColumnComponent = ({
  col: { name, id, properties },
  deleteClusterModalRef,
  setClusterIdForDeleting,
  deletePropertyModalRef,
  setPropertyIdForDeleting,
}) => {
  return (
    <Droppable droppableId={id.toString()}>
      {(provided, snapshot) => (
        <div className="column">
          <div
            className="iconContainer"
            onClick={() => {
              setClusterIdForDeleting(id);
              deleteClusterModalRef.current.show();
            }}>
            <CancelIcon width={28} height={28} fill={'#00000'} />
          </div>
          <h2>{name}</h2>
          <div className="list" {...provided.droppableProps} ref={provided.innerRef}>
            {properties.map((property, index) => (
              <ItemComponent
                key={property.id}
                item={property}
                index={index}
                clusterId={id}
                modalRef={deletePropertyModalRef}
                setPropertyIdForDeleting={setPropertyIdForDeleting}
                setClusterIdForDeleting={setClusterIdForDeleting}
              />
            ))}
            {provided.placeholder}
          </div>
        </div>
      )}
    </Droppable>
  );
};

export default ColumnComponent;
