import {
  GET_CLUSTERS_ATTEMPT,
  GET_CLUSTERS_SUCCESS,
  GET_CLUSTERS_ERROR,
  GET_CLUSTERS_RESET,
  ADD_CLUSTER_ATTEMPT,
  ADD_CLUSTER_SUCCESS,
  ADD_CLUSTER_ERROR,
  ADD_CLUSTER_RESET,
  DELETE_CLUSTER_BY_ID_ATTEMPT,
  DELETE_CLUSTER_BY_ID_SUCCESS,
  DELETE_CLUSTER_BY_ID_ERROR,
  DELETE_CLUSTER_BY_ID_RESET,
  ADD_PROPERTY_CLUSTER_ATTEMPT,
  ADD_PROPERTY_CLUSTER_SUCCESS,
  ADD_PROPERTY_CLUSTER_ERROR,
  ADD_PROPERTY_CLUSTER_RESET,
  DELETE_PROPERTY_CLUSTER_BY_ID_ATTEMPT,
  DELETE_PROPERTY_CLUSTER_BY_ID_SUCCESS,
  DELETE_PROPERTY_CLUSTER_BY_ID_ERROR,
  DELETE_PROPERTY_CLUSTER_BY_ID_RESET,
} from '../constants';

const INIT_STATE = {
  clusterInfo: {
    data: [],
    currentCluster: {},
    addClusterStatus: {
      attempt: false,
      success: false,
      error: null,
    },
    getAllClustersStatus: {
      attempt: false,
      success: false,
      error: null,
    },
    deleteClusterStatus: {
      attempt: false,
      success: false,
      error: null,
    },
  },
  propertyClusterInfo: {
    addPropertyClusterStatus: {
      attempt: false,
      success: false,
      error: null,
    },
    deletePropertyClusterStatus: {
      attempt: false,
      success: false,
      error: null,
    },
  },
};

const clusterReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CLUSTERS_ATTEMPT:
      return {
        ...state,
        clusterInfo: {
          ...state.clusterInfo,
          getAllClustersStatus: {
            attempt: true,
            success: false,
            error: null,
          },
        },
      };
    case GET_CLUSTERS_SUCCESS:
      return {
        ...state,
        clusterInfo: {
          ...state.clusterInfo,
          data: [...action.payload],
          getAllClustersStatus: {
            attempt: false,
            success: true,
            error: null,
          },
        },
      };
    case GET_CLUSTERS_ERROR:
      return {
        ...state,
        clusterInfo: {
          ...state.clusterInfo,
          getAllClustersStatus: {
            attempt: false,
            success: false,
            error: action.payload,
          },
        },
      };
    case GET_CLUSTERS_RESET:
      return {
        ...state,
        clusterInfo: {
          ...state.clusterInfo,
          getAllClustersStatus: {
            attempt: false,
            success: false,
            error: null,
          },
        },
      };
    case ADD_CLUSTER_ATTEMPT:
      return {
        ...state,
        clusterInfo: { ...state.clusterInfo, addClusterStatus: { attempt: true, success: false, error: null } },
      };
    case ADD_CLUSTER_SUCCESS:
      return {
        ...state,
        clusterInfo: {
          ...state.clusterInfo,
          data: [...state.clusterInfo.data, action.payload.cluster],
          addClusterStatus: {
            attempt: false,
            success: true,
            error: null,
          },
        },
      };
    case ADD_CLUSTER_ERROR:
      return {
        ...state,
        clusterInfo: {
          ...state.clusterInfo,
          addClusterStatus: { attempt: false, success: false, error: action.payload },
        },
      };
    case ADD_CLUSTER_RESET:
      return {
        ...state,
        clusterInfo: {
          ...state.clusterInfo,
          addClusterStatus: {
            attempt: false,
            success: false,
            error: null,
          },
        },
      };
    case DELETE_CLUSTER_BY_ID_ATTEMPT:
      return {
        ...state,
        clusterInfo: {
          ...state.clusterInfo,
          deleteClusterStatus: {
            attempt: true,
            success: false,
            error: null,
          },
        },
      };
    case DELETE_CLUSTER_BY_ID_SUCCESS:
      return {
        ...state,
        clusterInfo: {
          ...state.clusterInfo,
          currentCluster: {},
          data: [...state.clusterInfo.data.filter(cluster => cluster.id !== action.payload.id)],
          deleteClusterStatus: {
            attempt: false,
            success: true,
            error: null,
          },
        },
      };
    case DELETE_CLUSTER_BY_ID_ERROR:
      return {
        ...state,
        clusterInfo: {
          ...state.clusterInfo,
          deleteClusterStatus: {
            attempt: false,
            success: false,
            error: action.payload,
          },
        },
      };
    case DELETE_CLUSTER_BY_ID_RESET:
      return {
        ...state,
        clusterInfo: {
          ...state.clusterInfo,
          deleteClusterStatus: {
            attempt: false,
            success: false,
            error: null,
          },
        },
      };
    case ADD_PROPERTY_CLUSTER_ATTEMPT:
      return {
        ...state,
        propertyClusterInfo: {
          ...state.propertyClusterInfo,
          addPropertyClusterStatus: { attempt: true, success: false, error: null },
        },
      };
    case ADD_PROPERTY_CLUSTER_SUCCESS:
      const newClusters = state.clusterInfo.data.map(cluster => {
        if (cluster.id === action.payload.cluster.id) {
          return {
            ...cluster,
            properties: [...cluster.properties, action.payload.property],
          };
        }
        return cluster;
      });
      return {
        ...state,
        clusterInfo: {
          ...state.clusterInfo,
          data: [...newClusters],
        },
        propertyClusterInfo: {
          ...state.propertyClusterInfo,
          addPropertyClusterStatus: { attempt: false, success: true, error: null },
        },
      };
    case ADD_PROPERTY_CLUSTER_ERROR:
      return {
        ...state,
        propertyClusterInfo: {
          ...state.propertyClusterInfo,
          addPropertyClusterStatus: { attempt: false, success: false, error: action.payload?.message },
        },
      };
    case ADD_PROPERTY_CLUSTER_RESET:
      return {
        ...state,
        propertyClusterInfo: {
          ...state.propertyClusterInfo,
          addPropertyClusterStatus: {
            attempt: false,
            success: false,
            error: null,
          },
        },
      };
    case DELETE_PROPERTY_CLUSTER_BY_ID_ATTEMPT:
      return {
        ...state,
        propertyClusterInfo: {
          ...state.propertyClusterInfo,
          deleteClusterStatus: {
            attempt: true,
            success: false,
            error: null,
          },
        },
      };
    case DELETE_PROPERTY_CLUSTER_BY_ID_SUCCESS:
      const editedClusters = state.clusterInfo.data.map(cluster => {
        if (cluster.id === action.payload.cluster.id) {
          return {
            ...cluster,
            properties: cluster.properties.filter(property => property.id !== action.payload.property.id),
          };
        }
        return cluster;
      });
      return {
        ...state,
        clusterInfo: {
          ...state.clusterInfo,
          data: [...editedClusters],
        },
        propertyClusterInfo: {
          ...state.propertyClusterInfo,
          deletePropertyClusterStatus: {
            attempt: false,
            success: true,
            error: null,
          },
        },
      };
    case DELETE_PROPERTY_CLUSTER_BY_ID_ERROR:
      return {
        ...state,
        propertyClusterInfo: {
          ...state.propertyClusterInfo,
          deletePropertyClusterStatus: {
            attempt: false,
            success: false,
            error: action.payload,
          },
        },
      };
    case DELETE_PROPERTY_CLUSTER_BY_ID_RESET:
      return {
        ...state,
        propertyClusterInfo: {
          ...state.propertyClusterInfo,
          deletePropertyClusterStatus: {
            attempt: false,
            success: false,
            error: null,
          },
        },
      };
    default:
      return state;
  }
};

export default clusterReducer;
