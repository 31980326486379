import syncService from '../../api/services/sync';
import {
  SYNC_ATTEMPT,
  SYNC_ERROR,
  SYNC_RESET,
  SYNC_SUCCESS,
  PARTIAL_SYNC_ATTEMPT,
  PARTIAL_SYNC_SUCCESS,
  PARTIAL_SYNC_ERROR,
  PARTIAL_SYNC_RESET,
} from '../constants';

export const syncWithEntrata = () => {
  return async dispatch => {
    try {
      dispatch({ type: SYNC_ATTEMPT });
      await syncService.syncWithEntrata();
      dispatch({ type: SYNC_SUCCESS });
    } catch (err) {
      dispatch({ type: SYNC_ERROR, payload: err });
    }
  };
};

export const syncReset = () => ({ type: SYNC_RESET });

export const partialSyncWithEntrata = propertyExternalId => {
  return async dispatch => {
    try {
      dispatch({ type: PARTIAL_SYNC_ATTEMPT });
      await syncService.partialSyncWithEntrata(propertyExternalId);
      dispatch({ type: PARTIAL_SYNC_SUCCESS });
    } catch (err) {
      dispatch({ type: PARTIAL_SYNC_ERROR, payload: err });
    }
  };
};

export const partialSyncReset = () => ({ type: PARTIAL_SYNC_RESET });
