import axiosApiClient from '..';
import { handleError } from '../../utils/errors';

const BASE_URL = '/marketplace';

class MarketplaceService {
  getItems = async (page, sortBy, order, searchTerm) => {
    try {
      if (!sortBy) {
        sortBy = '';
      }
      if (!order) {
        order = 'ASC';
      }
      const response = await axiosApiClient.get(
        `${BASE_URL}/admin/items?page=${page}&sortBy=${sortBy}&order=${order}&searchTerm=${searchTerm}`,
      );
      if (response.status !== 200) {
        throw new Error();
      }
      const { data, total, perPage } = response.data;
      return { data, total, perPage };
    } catch (err) {
      throw handleError(err, 'Error occured while fetching items.');
    }
  };
  getItemById = async id => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/admin/items/${id}`);

      if (response.status !== 200) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      console.log(err);
      throw handleError(err, 'Error occured while fetching item.');
    }
  };
  approveItemById = async (id, isApproved, rejectionReason) => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}/admin/items/approve/${id}`, {
        isApproved,
        rejectionReason,
      });
      if (response.status !== 200) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      console.log(err);
      throw handleError(err, 'Error occured while approving item');
    }
  };
  deleteItem = async (itemId, deleteReason) => {
    try {
      const response = await axiosApiClient.delete(`${BASE_URL}/admin/items/${itemId}`, {
        data: { deleteReason },
      });

      if (response.status !== 200) {
        throw new Error();
      }
      return response.data;
    } catch (err) {
      console.log(err);
      throw handleError(err, 'Error occured while deleting item.');
    }
  };
}

export default new MarketplaceService();
