import axiosApiClient from '..';
import { handleError } from '../../utils/errors';

class SyncService {
  syncWithEntrata = async () => {
    try {
      const response = await axiosApiClient.get('sync');
      if (response.status !== 200) {
        throw new Error();
      }
    } catch (err) {
      throw handleError(err, 'Error occured while syncing with Entrata.');
    }
  };

  partialSyncWithEntrata = async propertyExternalId => {
    try {
      const response = await axiosApiClient.get(`sync/partial?propertyExternalId=${propertyExternalId}`);
      if (response.status !== 200) {
        throw new Error();
      }
    } catch (err) {
      throw handleError(err, 'Error occured while partially syncing with Entrata.');
    }
  };
}

export default new SyncService();
