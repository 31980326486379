import {
  ADD_ISSUE_ATTEMPT,
  ADD_ISSUE_SUCCESS,
  ADD_ISSUE_ERROR,
  ADD_ISSUE_RESET,
  GET_ISSUE_BY_ID_ATTEMPT,
  GET_ISSUE_BY_ID_SUCCESS,
  GET_ISSUE_BY_ID_ERROR,
  GET_ISSUE_BY_ID_RESET,
  DELETE_ISSUE_ATTEMPT,
  DELETE_ISSUE_SUCCESS,
  DELETE_ISSUE_ERROR,
  DELETE_ISSUE_RESET,
  UUPDATE_ISSUE_ATTEMPT,
  UPDATE_ISSUE_SUCCESS,
  UPDATE_ISSUE_ERROR,
  UPDATE_ISSUE_RESET,
} from '../constants';

const INIT_STATE = {
  issueInfo: {
    attempt: false,
    success: false,
    error: null,
    selectedIssue: {},
    getIssueByIdStatus: {
      attempt: false,
      success: false,
      error: null,
    },
    deleteIssueStatus: {
      attempt: false,
      success: false,
      error: null,
    },
    updateIssueStatus: {
      attempt: false,
      success: false,
      error: null,
    },
  },
};

const issueReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_ISSUE_ATTEMPT:
      return {
        ...state,
        issueInfo: { attempt: true, success: false, error: null },
      };
    case ADD_ISSUE_SUCCESS:
      return {
        ...state,
        issueInfo: { attempt: false, success: true, error: null },
      };
    case ADD_ISSUE_ERROR:
      return {
        ...state,
        issueInfo: { attempt: false, success: false, error: action.payload },
      };
    case ADD_ISSUE_RESET:
      return {
        ...state,
        issueInfo: { attempt: false, success: false, error: null },
      };
    case GET_ISSUE_BY_ID_ATTEMPT:
      return {
        ...state,
        issueInfo: { ...state.issueInfo, getIssueByIdStatus: { attempt: true, success: false, error: null } },
      };
    case GET_ISSUE_BY_ID_SUCCESS:
      return {
        ...state,
        issueInfo: {
          ...state.issueInfo,
          selectedIssue: action.payload,
          getIssueByIdStatus: { attempt: false, success: true, error: null },
        },
      };
    case GET_ISSUE_BY_ID_ERROR:
      return {
        ...state,
        issueInfo: {
          ...state.issueInfo,
          getIssueByIdStatus: { attempt: false, success: false, error: action.payload },
        },
      };
    case GET_ISSUE_BY_ID_RESET:
      return {
        ...state,
        issueInfo: { ...state.issueInfo, getIssueByIdStatus: { attempt: false, success: false, error: null } },
      };
    case DELETE_ISSUE_ATTEMPT:
      return {
        ...state,
        issueInfo: {
          ...state.issueInfo,
          deleteIssueStatus: {
            attempt: true,
            success: false,
            error: null,
          },
        },
      };
    case DELETE_ISSUE_SUCCESS:
      return {
        ...state,
        issueInfo: {
          ...state.issueInfo,
          selectedIssue: {},
          deleteIssueStatus: {
            attempt: false,
            success: true,
            error: null,
          },
        },
      };
    case DELETE_ISSUE_ERROR:
      return {
        ...state,
        issueInfo: {
          ...state.issueInfo,
          deleteIssueStatus: {
            attempt: false,
            success: false,
            error: action.payload,
          },
        },
      };
    case DELETE_ISSUE_RESET:
      return {
        ...state,
        issueInfo: {
          ...state.issueInfo,
          deleteIssueStatus: {
            attempt: false,
            success: false,
            error: null,
          },
        },
      };
    case UUPDATE_ISSUE_ATTEMPT:
      return {
        ...state,
        issueInfo: {
          ...state.issueInfo,
          updateIssueStatus: {
            attempt: true,
            success: false,
            error: null,
          },
        },
      };
    case UPDATE_ISSUE_SUCCESS:
      return {
        ...state,
        issueInfo: {
          ...state.issueInfo,
          selectedIssue: action.payload,
          updateIssueStatus: {
            attempt: false,
            success: true,
            error: null,
          },
        },
      };
    case UPDATE_ISSUE_ERROR:
      return {
        ...state,
        issueInfo: {
          ...state.issueInfo,
          updateIssueStatus: {
            attempt: false,
            success: false,
            error: action.payload,
          },
        },
      };
    case UPDATE_ISSUE_RESET:
      return {
        ...state,
        issueInfo: {
          ...state.issueInfo,
          updateIssueStatus: {
            attempt: false,
            success: false,
            error: null,
          },
        },
      };
    default:
      return state;
  }
};

export default issueReducer;
