import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import userService from '../../api/services/user';
// import Resizer from 'react-image-file-resizer';

const CustomerScreen = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);

  useEffect(() => {
    async function fetchApi() {
      try {
        const response = await userService.getUserById(id);
        setUser(response.data);
      } catch (error) {
        console.log(error.message);
      }
    }
    fetchApi();
  }, [id]);

  return (
    <div className="customer__screen">
      {user && (
        <div className="customer__screen__content">
          <div className="customer__screen__content__container">
            <div className="customer__screen__content__container__box left">
              <h2>
                <b>User</b>
              </h2>
              <p>
                <b>First Name:</b> {user.firstName}
              </p>
              <p>
                <b>Last Name:</b> {user.lastName}
              </p>
              <p>
                <b>Phone: </b>
                {user.phone}
              </p>
              <p>
                <b>Email: </b>
                <i>{user.email}</i>
              </p>
              <p>
                <b>Property Name: </b>
                <i>{user.leases[0]?.unitSpace?.propertyUnit.property.name}</i>
              </p>
            </div>
            {user.addresses.length !== 0 && (
              <div className="customer__screen__content__container__box right">
                <h2>
                  <b>Addresses</b>
                </h2>
                <div className="row">
                  {user.addresses.map((address, ind) => {
                    return (
                      <div key={ind} className="customer__screen__content__container__vehicle">
                        <p>
                          <b>Country: </b>
                          {address.country}
                        </p>
                        <p>
                          <b>State:</b> {address.state}
                        </p>
                        <p>
                          <b>City:</b> {address.city}
                        </p>
                        <p>
                          <b>Address:</b> {address.address}
                        </p>
                        <p>
                          <b>Postal Code:</b> {address.postalCode}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          {user.vehicles.length !== 0 && (
            <div className="customer__screen__content__container__box">
              <h2>
                <b>Vehicles</b>
              </h2>
              <div className="row">
                {user.vehicles.map((vehicle, ind) => {
                  return (
                    <div key={ind} className="customer__screen__content__container__vehicle">
                      <p>
                        <b>Make: </b>
                        {vehicle.make}
                      </p>
                      <p>
                        <b>Model:</b> {vehicle.model}
                      </p>
                      <p>
                        <b>Year:</b> {vehicle.year}
                      </p>
                      <p>
                        <b>License Plate Number:</b> {vehicle.licensePlateNumber}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomerScreen;
